// import React from 'react';
// import logo from './logo.svg';
import './App.css';
import BasicInfo from './Component/BasicInfo';
import PayfonePrefillForm from './Component/PayfonePrefillForm';
import DOFStartingPage from './Component/DOFStartingPage';
import ApprovalPage from './Component/ApprovalPage';
import UserVerifyInformation from './Component/UserVerifyInformation';
import ProfileComplete from './Component/ProfileComplete';
import SendDigitalForm from './Component/SendDigitalForm';
import IdentityVerify from './Component/IdentityVerify';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
  <div>
  <Router>
    <Switch> 
    <Route path="/IdentityVerify" component={IdentityVerify}></Route>
    <Route path="/SendDigitalForm" component={SendDigitalForm}></Route>
    <Route path="/ProfileComplete" component={ProfileComplete}></Route>

    <Route path="/UserVerifyInformation" component={UserVerifyInformation}></Route>
    <Route path="/ApprovalPage" component={ApprovalPage}></Route>  
    <Route path="/PayfonePrefill" component={PayfonePrefillForm}></Route>   
    <Route path="/BasicInfo" component={BasicInfo}></Route>
    <Route path="/" component={DOFStartingPage}></Route>
    
    </Switch>
  </Router>
  </div>
  );
}

export default App;
