

export interface ApiUrls {
  readonly container: string;
  readonly micro: string;
  readonly customers: string;
}

export interface AppConfigItem {
  readonly apiUrls: ApiUrls;
  readonly microUrl: string;
  //readonly auth: {
  //  readonly region: string;
  //  readonly userPoolId: string;
  //  readonly userPoolWebClientId: string;
  //  readonly oauth: {
  //    readonly domain: string;
  //    readonly scope: string[];
  //    readonly redirectSignIn: string;
  //    readonly redirectSignOut: string;
  //    readonly responseType: string;
  //    readonly urlOpener: (url: string) => void;
  //  };
  //};
  readonly APIConfig:{

readonly Dev_PayfoneIdentityURL: string;
readonly Dev_ValidateTokenURL : string;
readonly SMSMessege_Template : string;
readonly Dev_SendOTPURL : string;
readonly Dev_AMS005 : string;
readonly DEV_AMS_MessageDTSTZOffset: string;
readonly DEV_AMS_Revision: string;
readonly DEV_AMS_RegionID : string;
readonly DEV_AMS_ClientID: string;
readonly DEV_AMS_ClientSource: string;
readonly DEV_AMS_ClientOriginator: string;
readonly DEV_AMS_LocationNumber : string;
readonly DEV_AMS_ClientLocationNumber: string;
readonly DEV_AMS_EncryptionMethod: string;
readonly DEV_AMS_UserId : string;
readonly Dev_CMS205_URL : string;
readonly Dev_CMS205_Messege_Type: string;
readonly Dev_CMS205_ClientLocationNumber : string;
readonly Dev_CMS205_ClientSource : string;
readonly Dev_CMS205_LocationNumber: string;
readonly Dev_CMS205_RegionId: string;
readonly Dev_AMS003_URL : string;
readonly Dev_UpdateAccesslink_URL : string;
readonly Dev_SendAccesslink_URL : string;
readonly Dev_ValidateOTPURL : string;


  }

}

const microUrl = {
  localhost: 'http://localhost:3006',
  local: 'https://local-dof-racpad.rentacenter.com',
  dev: 'https://dev-dof-racpad.rentacenter.com',
  qa: 'https://qa-dof-racpad.rentacenter.com',
  uat: 'https://uat-dof-racpad.rentacenter.com',
  prod: 'https://dof-racpad.rentacenter.com'
};

export interface AppConfig extends AppConfigItem {
  readonly appName: string;
}

//export const urlOpener = (url: string): void => {
//  const urlToOpen = sanitizeURL(url);

//  if (urlToOpen) {
//    window.open(urlToOpen, '_self');
//  }
//};

export const localhost: AppConfigItem = {
  apiUrls: {
    container: 'https://local-racpad.rentacenter.com/api',
    micro: 'https://local-dof-racpad.rentacenter.com/api',
    customers: 'https://local-customers-racpad.rentacenter.com/api'
  },
  microUrl: microUrl.localhost,
    APIConfig:
    {
        Dev_PayfoneIdentityURL: 'https://qa-dof-racpad.rentacenter.com/api/getuseridentity',
        Dev_ValidateTokenURL: 'https://qa-dof-racpad.rentacenter.com/api/validateaccesslink',
        SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
        Dev_SendOTPURL: 'https://qa-dof-racpad.rentacenter.com/api/sendotp',
        Dev_ValidateOTPURL: 'https://qa-dof-racpad.rentacenter.com/api/validateotp',
        Dev_AMS005: 'https://qa-dof-racpad.rentacenter.com/api/getexistingcustomer',
        DEV_AMS_MessageDTSTZOffset: '-06:00',
        DEV_AMS_Revision: '2.1',
        DEV_AMS_RegionID: '1',
        DEV_AMS_ClientID: '194',
        DEV_AMS_ClientSource: '0',
        DEV_AMS_ClientOriginator: '194',
        DEV_AMS_LocationNumber: '00006',
        DEV_AMS_ClientLocationNumber: '00006',
        DEV_AMS_EncryptionMethod: '0',
        DEV_AMS_UserId: 'Test',
        Dev_CMS205_URL: 'https://qa-dof-racpad.rentacenter.com/api/getapproval',
        Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
        Dev_CMS205_ClientLocationNumber: '00006',
        Dev_CMS205_ClientSource: '3',
        Dev_CMS205_LocationNumber: '00006',
        Dev_CMS205_RegionId: '1',
        Dev_AMS003_URL: 'https://qa-dof-racpad.rentacenter.com/api/updatecustomerdetails',
        Dev_UpdateAccesslink_URL: 'https://qa-dof-racpad.rentacenter.com/api/updateaccesslink',
        Dev_SendAccesslink_URL: 'https://dev-store-racpad.rentacenter.com/api/sendaccesslink'


    }
 
};

export const localhostIE11: AppConfigItem = { ...localhost };

export const local: AppConfigItem = {
  apiUrls: {
    container: 'https://local-racpad.rentacenter.com/api',
    micro: 'https://local-dof-racpad.rentacenter.com/api',
    customers: 'https://local-customers-racpad.rentacenter.com/api'
  },
  microUrl: microUrl.local,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: 'https://dev-dof-racpad.rentacenter.com/api/getuseridentity',
      Dev_ValidateTokenURL: 'https://dev-dof-racpad.rentacenter.com/api/validateaccesslink',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: 'https://dev-dof-racpad.rentacenter.com/api/sendotp',
      Dev_ValidateOTPURL: 'https://dev-dof-racpad.rentacenter.com/api/validateotp',
      Dev_AMS005: 'https://dev-dof-racpad.rentacenter.com/api/getexistingcustomer',
      DEV_AMS_MessageDTSTZOffset: '-06:00',
      DEV_AMS_Revision: '2.1',
      DEV_AMS_RegionID: '1',
      DEV_AMS_ClientID: '194',
      DEV_AMS_ClientSource: '0',
      DEV_AMS_ClientOriginator: '194',
      DEV_AMS_LocationNumber: '00006',
      DEV_AMS_ClientLocationNumber: '00006',
      DEV_AMS_EncryptionMethod: '0',
      DEV_AMS_UserId: 'Test',
      Dev_CMS205_URL: 'https://dev-dof-racpad.rentacenter.com/api/getapproval',
      Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
      Dev_CMS205_ClientLocationNumber: '00006',
      Dev_CMS205_ClientSource: '3',
      Dev_CMS205_LocationNumber: '00006',
      Dev_CMS205_RegionId: '1',
      Dev_AMS003_URL: 'https://dev-dof-racpad.rentacenter.com/api/updatecustomerdetails',
      Dev_UpdateAccesslink_URL: 'https://dev-dof-racpad.rentacenter.com/api/updateaccesslink',
      Dev_SendAccesslink_URL: 'https://dev-store-racpad.rentacenter.com/api/sendaccesslink'


  }
};

export const dev: AppConfigItem = {
  apiUrls: {
    container: 'https://dev-racpad.rentacenter.com/api',
    micro: 'https://dev-dof-racpad.rentacenter.com/api',
    customers: 'https://dev-customers-racpad.rentacenter.com/api'
  },
  microUrl: microUrl.dev,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: 'https://dev-dof-racpad.rentacenter.com/api/getuseridentity',
      Dev_ValidateTokenURL: 'https://dev-dof-racpad.rentacenter.com/api/validateaccesslink',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: 'https://dev-dof-racpad.rentacenter.com/api/sendotp',
      Dev_ValidateOTPURL: 'https://dev-dof-racpad.rentacenter.com/api/validateotp',
      Dev_AMS005: 'https://dev-dof-racpad.rentacenter.com/api/getexistingcustomer',
      DEV_AMS_MessageDTSTZOffset: '-06:00',
      DEV_AMS_Revision: '2.1',
      DEV_AMS_RegionID: '1',
      DEV_AMS_ClientID: '194',
      DEV_AMS_ClientSource: '0',
      DEV_AMS_ClientOriginator: '194',
      DEV_AMS_LocationNumber: '00006',
      DEV_AMS_ClientLocationNumber: '00006',
      DEV_AMS_EncryptionMethod: '0',
      DEV_AMS_UserId: 'Test',
      Dev_CMS205_URL: 'https://dev-dof-racpad.rentacenter.com/api/getapproval',
      Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
      Dev_CMS205_ClientLocationNumber: '00006',
      Dev_CMS205_ClientSource: '3',
      Dev_CMS205_LocationNumber: '00006',
      Dev_CMS205_RegionId: '1',
      Dev_AMS003_URL: 'https://dev-dof-racpad.rentacenter.com/api/updatecustomerdetails',
      Dev_UpdateAccesslink_URL: 'https://dev-dof-racpad.rentacenter.com/api/updateaccesslink',
      Dev_SendAccesslink_URL: 'https://dev-store-racpad.rentacenter.com/api/sendaccesslink'


  }
};

export const qa: AppConfigItem = {
  apiUrls: {
    container: 'https://qa-racpad.rentacenter.com/api',
    micro: 'https://qa-dof-racpad.rentacenter.com/api',
    customers: 'https://qa-customers-racpad.rentacenter.com/api'
  },
  microUrl: microUrl.qa,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: 'https://qa-dof-racpad.rentacenter.com/api/getuseridentity',
      Dev_ValidateTokenURL: 'https://qa-dof-racpad.rentacenter.com/api/validateaccesslink',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: 'https://qa-dof-racpad.rentacenter.com/api/sendotp',
      Dev_ValidateOTPURL: 'https://qa-dof-racpad.rentacenter.com/api/validateotp',
      Dev_AMS005: 'https://qa-dof-racpad.rentacenter.com/api/getexistingcustomer',
      DEV_AMS_MessageDTSTZOffset: '-06:00',
      DEV_AMS_Revision: '2.1',
      DEV_AMS_RegionID: '1',
      DEV_AMS_ClientID: '194',
      DEV_AMS_ClientSource: '0',
      DEV_AMS_ClientOriginator: '194',
      DEV_AMS_LocationNumber: '00006',
      DEV_AMS_ClientLocationNumber: '00006',
      DEV_AMS_EncryptionMethod: '0',
      DEV_AMS_UserId: 'Test',
      Dev_CMS205_URL: 'https://qa-dof-racpad.rentacenter.com/api/getapproval',
      Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
      Dev_CMS205_ClientLocationNumber: '00006',
      Dev_CMS205_ClientSource: '3',
      Dev_CMS205_LocationNumber: '00006',
      Dev_CMS205_RegionId: '1',
      Dev_AMS003_URL: 'https://qa-dof-racpad.rentacenter.com/api/updatecustomerdetails',
      Dev_UpdateAccesslink_URL: 'https://qa-dof-racpad.rentacenter.com/api/updateaccesslink',
      Dev_SendAccesslink_URL: 'https://qa-store-racpad.rentacenter.com/api/sendaccesslink'


  }
};

export const uat: AppConfigItem = {
  apiUrls: {
    container: 'https://uat-racpad.rentacenter.com/api',
    micro: 'https://uat-dof-racpad.rentacenter.com/api',
    customers: 'https://uat-customers-racpad.rentacenter.com/api'
  },
  microUrl: microUrl.uat,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: 'https://uat-dof-racpad.rentacenter.com/api/getuseridentity',
      Dev_ValidateTokenURL: 'https://uat-dof-racpad.rentacenter.com/api/validateaccesslink',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: 'https://uat-dof-racpad.rentacenter.com/api/sendotp',
      Dev_ValidateOTPURL: 'https://uat-dof-racpad.rentacenter.com/api/validateotp',
      Dev_AMS005: 'https://uat-dof-racpad.rentacenter.com/api/getexistingcustomer',
      DEV_AMS_MessageDTSTZOffset: '-06:00',
      DEV_AMS_Revision: '2.1',
      DEV_AMS_RegionID: '1',
      DEV_AMS_ClientID: '194',
      DEV_AMS_ClientSource: '0',
      DEV_AMS_ClientOriginator: '194',
      DEV_AMS_LocationNumber: '00006',
      DEV_AMS_ClientLocationNumber: '00006',
      DEV_AMS_EncryptionMethod: '0',
      DEV_AMS_UserId: 'Test',
      Dev_CMS205_URL: 'https://uat-dof-racpad.rentacenter.com/api/getapproval',
      Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
      Dev_CMS205_ClientLocationNumber: '00006',
      Dev_CMS205_ClientSource: '3',
      Dev_CMS205_LocationNumber: '00006',
      Dev_CMS205_RegionId: '1',
      Dev_AMS003_URL: 'https://uat-dof-racpad.rentacenter.com/api/updatecustomerdetails',
      Dev_UpdateAccesslink_URL: 'https://uat-dof-racpad.rentacenter.com/api/updateaccesslink',
      Dev_SendAccesslink_URL: 'https://uat-store-racpad.rentacenter.com/api/sendaccesslink'


  }
};

export const prod: AppConfigItem = {
  apiUrls: {
    container: 'https://racpad.rentacenter.com/api',
    micro: 'https://racpad-dof.rentacenter.com/api',
    customers: 'https://racpad-customers.rentacenter.com/api'
  },
  microUrl: microUrl.prod,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: 'https://dof-racpad.rentacenter.com/api/getuseridentity',
      Dev_ValidateTokenURL: 'https://dof-racpad.rentacenter.com/api/validateaccesslink',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: 'https://dof-racpad.rentacenter.com/api/sendotp',
      Dev_ValidateOTPURL: 'https://dof-racpad.rentacenter.com/api/validateotp',
      Dev_AMS005: 'https://dof-racpad.rentacenter.com/api/getexistingcustomer',
      DEV_AMS_MessageDTSTZOffset: '-06:00',
      DEV_AMS_Revision: '2.1',
      DEV_AMS_RegionID: '1',
      DEV_AMS_ClientID: '194',
      DEV_AMS_ClientSource: '0',
      DEV_AMS_ClientOriginator: '194',
      DEV_AMS_LocationNumber: '00006',
      DEV_AMS_ClientLocationNumber: '00006',
      DEV_AMS_EncryptionMethod: '0',
      DEV_AMS_UserId: 'Test',
      Dev_CMS205_URL: 'https://dof-racpad.rentacenter.com/api/getapproval',
      Dev_CMS205_Messege_Type: 'CMS205_ESBHogKcrHAZABXD',
      Dev_CMS205_ClientLocationNumber: '00006',
      Dev_CMS205_ClientSource: '3',
      Dev_CMS205_LocationNumber: '00006',
      Dev_CMS205_RegionId: '1',
      Dev_AMS003_URL: 'https://dof-racpad.rentacenter.com/api/updatecustomerdetails',
      Dev_UpdateAccesslink_URL: 'https://dof-racpad.rentacenter.com/api/updateaccesslink',
      Dev_SendAccesslink_URL: 'https://store-racpad.rentacenter.com/api/sendaccesslink'


  }

};

export const artifact: AppConfigItem = {
  apiUrls: {
    container: '_apiUrls.container',
    micro: '_apiUrls.micro',
    customers: '_apiUrls.customers'
  },
  microUrl: microUrl.uat,
  APIConfig:
  {
      Dev_PayfoneIdentityURL: '_dev_payfoneidentityurl',
      Dev_ValidateTokenURL: '_dev_validatetokenurl',
      SMSMessege_Template: 'Here is your Rent-A-Center security code : ',
      Dev_SendOTPURL: '_dev_sendotpurl',
      Dev_ValidateOTPURL: '_dev_validateotpurl',
      Dev_AMS005: '_dev_ams005',
      DEV_AMS_MessageDTSTZOffset: '_dev_ams_MessageDTSTZoffset',
      DEV_AMS_Revision: '_dev_ams_revision',
      DEV_AMS_RegionID: '_dev_ams_regionid',
      DEV_AMS_ClientID: '_dev_ams_clientid',
      DEV_AMS_ClientSource: '_dev_ams_clientsource',
      DEV_AMS_ClientOriginator: '_dev_ams_clientoriginator',
      DEV_AMS_LocationNumber: '_dev_ams_locationnumber',
      DEV_AMS_ClientLocationNumber: '_dev_ams_clientlocationnumber',
      DEV_AMS_EncryptionMethod: '_dev_ams_encryptionMethod',
      DEV_AMS_UserId: '_dev_ams_userId',
      Dev_CMS205_URL: '_dev_cms205url',
      Dev_CMS205_Messege_Type: '_dev_CMS205_Messege_Type',
      Dev_CMS205_ClientLocationNumber: '_dev_CMS205_ClientLocationNumber',
      Dev_CMS205_ClientSource: '_dev_CMS205_ClientSource',
      Dev_CMS205_LocationNumber: '_dev_CMS205_LocationNumber',
      Dev_CMS205_RegionId: '_dev_CMS205_RegionId',
      Dev_AMS003_URL: '_dev_ams003url',
      Dev_UpdateAccesslink_URL: '_dev_updateaccesslinkurl',
      Dev_SendAccesslink_URL: '_dev_sendaccesslinkurl'


  }
};
let appConfigItem: AppConfigItem;
// const domainName = window.location.hostname;
// switch (domainName) {
//   case 'localhost':
//     appConfigItem = localhost;
//     break;
//   case 'localhost-ie11':
//     appConfigItem = localhostIE11;
//     break;
//   case 'local':
//     appConfigItem = local;
//     break;
//   case 'dev-dof-racpad.rentacenter.com':
//     appConfigItem = dev;
//     console.log(domainName)
//     break;
//   case 'qa-dof-racpad.rentacenter.com':
//     appConfigItem = qa;
//     console.log(domainName)
//     break;
//   case 'uat-dof-racpad.rentacenter.com':
//     appConfigItem = uat;
//     console.log(domainName)
//     break;
//   case 'dof-racpad.rentacenter.com':
//     appConfigItem = prod;
//     break;
//   case 'artifact':
//     appConfigItem = artifact;
//     break;    
//   default:
//     appConfigItem = dev;
//     console.log(domainName)
//     break;
// }

switch (process.env.REACT_APP_STAGE) {
  case 'localhost':
    appConfigItem = localhost;
    break;
  case 'localhost-ie11':
    appConfigItem = localhostIE11;
    break;
  case 'local':
    appConfigItem = local;
    break;
  case 'dev':
    appConfigItem = dev;
    break;
  case 'qa':
    appConfigItem = qa;
    break;
  case 'uat':
    appConfigItem = uat;
    break;
  case 'prod':
    appConfigItem = prod;
    break;
  case 'artifact':
    appConfigItem = artifact;
    break;
  default:
    appConfigItem = dev;
    break;
}


export const appConfig: AppConfig = {
  appName: 'DOF',
  ...appConfigItem
};
