import DOFHeader from "./DOFHeader";
import '../css/commontheme.css'
import '../css/stylesheet.css'
// import NextArrow from '../images/arrow-right.svg'

function ProfileComplete()
{
    return(
        <>
     <DOFHeader/>    
      {/*body content starts*/}
      <div className="row justify-content-center me-0 mb-5">
        <div className="col-md-7 col-mdl-6  mt-5 pe-0">
          <div className="info-card mx-3 text-center mb-5 banner-bg">
  
            <h3 className="blue-title-txt mb-3 px-2">Thank you for completing the profile!</h3>
    

            <h5 className=" mb-3 px-2">Our Co-worker will finish the final steps to get your products.</h5>
            
          </div>
       
        </div>
      </div>
      {/*body content ends*/}   
    
        </>
        );  
}

export default ProfileComplete;