import '../css/commontheme.css'
import '../css/stylesheet.css'
import SecureIcon from '../images/secure-icon.svg'
import Logo from '../images/logo.svg' 
function DOFHeader()
{
    
    return(
        
          <nav className="nav-bg-color w-100 p-2 float-start mb-5">
        <div className="float-start">
          <img className="logo-position float-start" src={Logo} alt="logo" />
          <span className="txt-style-bold ms-2 float-start mt-2">Rental Order Form</span>
        </div>
        <div className="float-end txt-style-semibold mt-custom">
          <img className="me-1" src={SecureIcon} alt="secure-icon" />
          Secure Form
        </div>
      </nav>
       
    );
}
export default DOFHeader;