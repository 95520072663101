/* eslint-disable @typescript-eslint/no-unused-vars */
import DOFHeader from "./DOFHeader";
import ArrowRight from '../images/arrow-right.svg'
import Timer from '../images/Timer.svg'
import Loader from '../images/loader-icon.svg'
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

// import ReactSession from 'react-client-session';

import { ValidateAuthKey } from '../Service/Service'

function DOFStartingPage() {

    //added required State variables
    const [Authkey, setAuthKey] = useState('');
    const [CustomerMobileNumber, setCustomerMobileNumber] = useState('');
    const [CustomerEmailId, setCustomerEmailId] = useState('');
    const [AuthParam, setAuthParam] = useState('');
    const [IsLookingUpOpen, setIsLookingUpOpen] = useState<boolean>(true);




    useEffect(() => {

        // ReactSession.setStoreType("localStorage");
        const params = new URLSearchParams(window.location.search) // id=123
        const EncodeAuthKey = params.get('AuthKey') || "";
        const EncodedStorecode = params.get('StoreCode') || "";
        setAuthKey(EncodeAuthKey);

        ValidateAuthKeyValue(EncodeAuthKey, EncodedStorecode);
     

    }, [])

    const ValidateAuthKeyValue = async (EncodeAuthKey: string, EncodedStorecode: string) => {
        debugger;
        if (EncodeAuthKey !== '') {

            const ResponseValidateAuth: any = await ValidateAuthKey(EncodeAuthKey);
            console.log("Here the date", ResponseValidateAuth);
            if (ResponseValidateAuth !== undefined && ResponseValidateAuth !== null) {
                if (ResponseValidateAuth.valid === true && ResponseValidateAuth.status === 'A') {
                    console.log(ResponseValidateAuth.status);
                    setIsLookingUpOpen(false);

                    var AuthParamKey = 'AuthKey=' + EncodeAuthKey;
                    setAuthParam(AuthParamKey);
                    if (ResponseValidateAuth.phoneNumber) {
                        setCustomerMobileNumber(ResponseValidateAuth.phoneNumber);
                        const EnMobile = new Buffer(ResponseValidateAuth.phoneNumber).toString("base64");
                        console.log(EnMobile);
                        // var AuthParamMobile = AuthParam + "&&MobileNo=" + EnMobile;
                        //console.log(AuthParamMobile);
                        // AuthParamKey = AuthParamKey + "&&MobileNo=" + EnMobile
                        sessionStorage.setItem("MobileNo", EnMobile);
                        sessionStorage.setItem("MobileNoLink", EnMobile);
                        //setAuthParam(AuthParamKey);
                        //console.log(AuthParamKey);
                    }
                    if (ResponseValidateAuth.emailAddress) {
                        const EnEmail = new Buffer(ResponseValidateAuth.emailAddress.toLocaleLowerCase()).toString("base64");
                        // setAuthParam("&&EmailId="+ EnEmail);
                        //AuthParamKey = AuthParamKey + "&&EmailId=" + EnEmail
                        sessionStorage.setItem("EmailId", EnEmail);
                        sessionStorage.setItem("EmailIdLink", EnEmail);
                        //setAuthParam(AuthParamKey);
                        console.log(AuthParamKey);

                    }
                    if (ResponseValidateAuth.storeNumber) {
                        const EnStoreCode = new Buffer(ResponseValidateAuth.storeNumber).toString("base64");
                        // setAuthParam("&&EmailId="+ EnEmail);
                        //AuthParamKey = AuthParamKey + "&&EmailId=" + EnEmail
                        sessionStorage.setItem("StoreCode", EnStoreCode);
                        //setAuthParam(AuthParamKey);
                        console.log(AuthParamKey);

                    }
                }
                else if (ResponseValidateAuth.valid !== undefined && ResponseValidateAuth.status !== undefined && ResponseValidateAuth.valid === true && ResponseValidateAuth.status === 'C') {
                    setIsLookingUpOpen(true);
                    window.location.href = './IdentityVerify?Status=Completed'
                    console.log("Here the date", ResponseValidateAuth.valid);
                }
                else if (ResponseValidateAuth.valid !== undefined && !ResponseValidateAuth.valid) {
                    setIsLookingUpOpen(true);
                    window.location.href = './IdentityVerify?Status=Expired'
                    console.log("Here the date", ResponseValidateAuth.valid);

                }
            }
            else {
                setIsLookingUpOpen(true);
                console.log("Inside else condition");
                window.location.href = './IdentityVerify?Status=Invalid';
            }


        } else {
            if (EncodedStorecode !== '') {

                const EnStoreCode = new Buffer(EncodedStorecode).toString("base64");

                // setAuthParam("&&EmailId="+ EnEmail);
                //AuthParamKey = AuthParamKey + "&&EmailId=" + EnEmail
                sessionStorage.setItem("StoreCode", EncodedStorecode);
                let encodestore = new Buffer(EncodedStorecode, 'base64');
                let decodestore = encodestore.toString('ascii');
                decodestore = decodestore + '-Store'

                const ValidateStore: any = await ValidateAuthKey(decodestore);

                if (ValidateStore.StatusCode !== undefined && ValidateStore.StatusCode === '400') {
                    setIsLookingUpOpen(true);
                    console.log("NotValid");
                    window.location.href = './IdentityVerify?Status=Invalid';

                } else { setIsLookingUpOpen(false); console.log("Valid") }


            } else { window.location.href = './IdentityVerify?Status=Invalid';}

        }
    }




    return (
        <>
            <DOFHeader />
            <div>
                {IsLookingUpOpen === false ?
                    <div className="row justify-content-center me-0">
                        <div className="col-md-8 text-center  mt-5">
                            <h4 className="blue-title-txt mb-3 px-2">Welcome to RAC</h4>
                            <h5 className="sub-heading-txt mb-5 px-2">Let’s start creating your profile</h5>
                            <p className="txt-style px-2">First we need some basic information that will include:</p>
                            <div className="row justify-content-center mb-2">
                                <div className=" col-md-5 col-mdl-4 col-sm-7 col-custom-sm text-start">
                                    <p className="points px-4 "><span className="bullet-points" />Personal Details</p>
                                    <p className="points px-4 "><span className="bullet-points" />Residence</p>
                                    <p className="points px-4 "><span className="bullet-points" />Employment</p>
                                </div>
                            </div>
                            <p className="gray-timertxt mb-5"><img className="me-2" src={Timer} alt="timer icon" />This process will take approximately 3 minutes to complete</p>
                            {/* <button className="btn btn-primary-custom mt-5">LET’S START <img src={ArrowRight} alt="arrow right" /></button> */}
                            <a href={"BasicInfo?" + AuthParam} className="btn btn-primary-custom mt-5">LET’S START <img src={ArrowRight} alt="arrow right" /></a>

                        </div>
                    </div>

                    : null}
                {/*Modal for Loader starst here*/}
                <Modal isOpen={IsLookingUpOpen} className='test'>
                    <div className="modal fade show" id="loader" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body py-4">
                                    <p className="popup-txt-style mb-0"><img className="me-2" src={Loader} alt="loader icon" />Loading!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/*Modal for Loader ends here*/}
            </div>

        </>
    );


}
export default DOFStartingPage;
