/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState,useEffect } from 'react';
import '../css/commontheme.css';
import '../css/stylesheet.css';
import PreviousArrow from '../images/prev-icon.svg';
import Loader from '../images/loader-icon.svg';
import NextArrow from '../images/arrow-right.svg';
import DOFHeader from './DOFHeader'; 
import {BestTimetoCall,states,GovtTypeId,PaydayFrequency,HowLong,WeekDays,RelationShip,Monthly,SemiMonthly} from '../Config/Config';
// import { useStates } from 'react-us-states';
// import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import 'flatpickr/dist/ie.css';
import "react-datepicker/dist/react-datepicker.css";
import { UpdateCustomerService,UpdateAccesslink } from '../Service/Service';
import Modal from 'react-modal';
import moment from 'moment';


function UserVerifyInformation()
{
  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    const EncodeAuthKey = params.get('AuthKey') || "";
    const MobileNumber = sessionStorage.getItem("MobileNo") !== null && sessionStorage.getItem("MobileNo") !==undefined && sessionStorage.getItem("MobileNo") !== '' ? sessionStorage.getItem("MobileNo"):'';
    const UserApprovalInformation = JSON.parse(sessionStorage.getItem('UserInformation') || '{}'); ;
        const TAM = UserApprovalInformation.TAM ;
        const WAM = UserApprovalInformation.WAM;
        const APP = UserApprovalInformation.APP;
        const CID =  UserApprovalInformation.CID;
        const State = UserApprovalInformation.State;
        const Address =  UserApprovalInformation.Address;
        const City = UserApprovalInformation.City;
        const ZipCode = UserApprovalInformation.Zip

    setAuthKey(EncodeAuthKey);

    setMobileNumber(MobileNumber,APP,CID,State,Address,City,ZipCode);
 



}, [])

const [EncodedeState,setEncodedeState] = useState('');
const [EncodedCity,setEncodedCity] = useState('');
const [EncodedAddress,setEncodedAddress] = useState('');
const [EncodedPostalCode,setEncodedPostalCode] = useState('');


const setMobileNumber=(MobileNumber,APP,CID,State,Address,City,ZipCode)=>
{
  let EncodedCID = new Buffer(CID, 'base64');
  let DecodedCID = EncodedCID.toString('ascii');
  stCustomerID(DecodedCID);
  let EncodeState= new Buffer(State, 'base64');
  let DecodState = EncodeState.toString('ascii');
  setEncodedeState(DecodState);
  let EncodeCity = new Buffer(City, 'base64');
  let DecodedCity = EncodeCity.toString('ascii');
  setEncodedCity(DecodedCity);
  let EncodeZip= new Buffer(ZipCode, 'base64');
  let DecodeZip = EncodeZip.toString('ascii');
    setEncodedPostalCode(DecodeZip);
  let EncodeAddress = new Buffer(Address, 'base64');
  let DecodeAddress = EncodeAddress.toString('ascii');
    //setEncodedPostalCode(DecodeAddress);
    setEncodedAddress(DecodeAddress);

  if(MobileNumber !== '' )
  {
    let EncodedMobileNumber = new Buffer(MobileNumber, 'base64');
    let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');

    let formatPhoneNo = formatPhoneNumber(DecodedMobileNumber);
    setMobileNumberValue(formatPhoneNo);
    setIsMobileNumberDisable(true);
    setIsMobileNoVerified(true);
    setMobileNoVerify('verify-icon');

    let EncodedAPP = new Buffer(APP, 'base64');
    let DecodedAPP = EncodedAPP.toString('ascii');

    if (DecodedAPP ==='1') 
    {
      setApproval(true);

    }
    else if(DecodedAPP ==='3'||DecodedAPP ==='4'||DecodedAPP ==='5')
    {
      setApproval(false);
    }
    else{
      window.location.href = './IdentityVerify?Status=Exception';

    }
  }
  else{ setIsMobileNumberDisable(false);}
  
}
    const [IsLookingUpOpen, setIsLookingUpOpen] = useState<boolean>(false);
    const [CustomerID, stCustomerID] = useState('');
    const [IsTermEnable, setIsTermEnable] = useState < boolean > (true);
    const [IsAddTermEnable, setIsAddTermEnable] = useState < boolean > (false);
    const [AuthKey, setAuthKey] = useState('');
    const [IsMobileNumberDisable, setIsMobileNumberDisable] = useState < boolean > (false);
    const [BasicInfo, setBasicInfo] = useState < boolean > (true);
    const [EmployerInfo, setEmployerInfo] = useState < boolean > (false);
    const [ResidenceInfo, setResidenceInfo] = useState < boolean > (false);
    const [ReferenceInfo, setReferenceInfo] = useState < boolean > (false);
    const [ReferenceInfoNav, setReferenceInfoNav] = useState <string> ('done');
    const [EmployerInfoNav, setEmployerInfoNav] = useState <string> ('done');
    const [ResidenceInfoNav, setResidenceInfoNav] = useState <string> ('done');

    const [DaysPaid, setDaysPaid] = useState<string>("");
    const [GovtIdType, setGovtType] = useState < string > ("");
    const [Besttime, setBesttime] = useState < string > ("");
    const [MobileNumber, setMobileNumberValue] = useState < string > ("");
    const [MobileNoVerify, setMobileNoVerify] = useState < string > ("");
    const [EmailVerify, setEmailVerify] = useState < string > ("");
    const [IsMobileNoVerified, setIsMobileNoVerified] = useState < boolean > (false);
    const [IsGovtIdVerify,setGovtVerify] = useState<string> ("");
    const [BestTimeToCallVerify,setBestTimeToCallVerify] = useState<string> ("");


    const [BesttimeVerify, setBesttimeVerify] = useState < string > ("");
    const [GovtId,setGovtId] = useState < string > ("");  
    const [GovtTypeIdVerify,setGovtTypeIdVerify] = useState<string> ("");
    const [IsGovtIdValid,setGovtIdValid] = useState < boolean > (false);
    const [PayDayFrequencyValue,setPayDayFrequency] = useState<string>("");
    const [IsGovtIDTypeVerified, setIsGovtIDTypeVerified] = useState<boolean>(false);


    //steteid for govt
    const [IsGovtStateDisabled, setIsGovtStateDisabled] = useState<boolean>(false);
    const [IsGovtTypeStateVerified, setIsGovtTypeStateVerified] = useState<boolean>(false);
    const [GovtTypeState, setGovtTypeState] = useState<string>('done');
    const [GovtTypeStateVerify, setGovtTypeStateVerify] = useState<string>('done');
    
    const [State,setState] = useState<string>('Select');

    const [EnableContinueButton,setEnableContinueButton] = useState <boolean>(true);
    const [IsGovtIdDisabled,setIsGovtIdDisabled] = useState < boolean > (false);

    const [CompanyName, setCompanyName] = useState < string > ("");
    const [IsCompanyVerified, setIsCompanyVerified] = useState <boolean>(false);
    const [CompanyNameverify, setCompanyNameVerify] = useState < string > ("");

    const [HowLongValue,setHowLongValue] = useState<string> ("");
    const [HowlongVerify, setHowlongVerify] = useState<string>("");


    const [MortageLong, setMortageLong] = useState<string>("");
    const [MortageLongVerify, setMortageLongVerify] = useState<string>("");

    const [RentHouseLong, setRentHouseLong] = useState<string>("");
    const [RentHouseLongVerify, setRentHouseLongVerify] = useState<string>("");
    
    const [EmployerPhoneNumber, setEmployerPhoneNumber] = useState < string > ("");      
    const [IsEmployerPhoneNumberVerified, setIsEmployerPhoneNumberVerified] =  useState <boolean>(false);    
    const [EmployerPhoneNumberVerify, setEmployerPhoneNumberVerify] = useState < string > ("");
    const [IsStep2ContinueButtonDisable, setIsStep2ContinueButtonDisable] =  useState <boolean>(true);



    const [IsPaydayFrequencyVerified, setIsPaydayFrequencyVerified] =  useState <boolean>(false);  
    const [IsDayPaidVerified,setIsDayPaidVerified] =  useState <boolean>(false);
    const [DaypaidVeirfy,setDaypaidVerify] = useState < string > ("");  
    const [IsTakeHomePayVerified, setIsTakeHomePayVerified] =  useState <boolean>(false); 
    // const [IsAddressLineVerified,setIsAddressLineVerified] =  useState <boolean>(false); 
    const [IsEmployerAddressVerified,setIsEmployerAddressVerified] =  useState <boolean>(false); 
    const [IsZipCodeVerified,setIsZipCodeVerified] =  useState <boolean>(false); 
    const [IsCityVerified,setIsCityVerified] =  useState <boolean>(false); 
    const [IsStateVerified,setIsStateVerified] =  useState <boolean>(false); 
    //pay amount 
    const [TakeHomePay, setTakeHomePay] = useState<string>(''); 
    const [TakeHomePayFormat, setTakeHomePayFormat] = useState<string>('');
    const [TakeHomePayVerify, setTakeHomePayVerify] = useState<string>('');

    const [WeeklyDays, setsetWeeklyDays] = useState < string > (""); 
    const [EmployerAddressVerify, setEmployerAddressVerify] = useState < string > (""); 
    const [EmployerAddress, setEmployerAddress] = useState < string > (""); 
    const [EmployerAddress2, setEmployerAddress2] = useState < string > (""); 
    const[EmployerAddress2Verify,setEmployerAddress2Verify] =useState<string>("");

    const[EmployerZipCode,setEmployerZipCode] =useState<string>("");
    const[EmployerZipCodeverify,setEmployerZipCodeverify] =useState<string>("");
    // const[IsEmployerZipCodeVerified,setIsEmployerZipCodeVerified] = useState <boolean>(false);

    const[EmployerCity,setEmployerCity] =useState<string>("");
    const[EmployerCityVerify,setEmployerCityVerify] =useState<string>("");
    const[EmployerStateVerify,setEmployerStateVerify] = useState<string>("");
    const[PayDayFrequencyVerify,setPayDayFrequencyVerify] = useState<string>("");



    //Residence ------------------------------------

    const[Renthouse,setRenthouse] =  useState <boolean>(true);
    const[MortgageEnable,setMortgageEnable] =  useState <boolean>(true);
    const[LandlordName,setLandlordName] = useState<string>("");
    const[LandlordLastname,setLandlordLastname] = useState<string>("");
    const[IsLandlordLastVerified,setIsLandlordLastVerified] = useState <boolean>(false);
    const[LandlordLastVerify,setLandlordLastVerify] = useState<string>("");
    const[IsLandlordVerified,setIsLandlordVerified] = useState <boolean>(false);
    const[DisableStep3ContinueButton,setDisableStep3ContinueButton] = useState <boolean>(true);
    const[LandlordVerify,setLandlordVerify] = useState<string>("");

    const[LandlordMobileNumber,setLandlordMobileNumber] = useState<string>("");
    const[IsLandlordMobileNumberVerified,setIsLandlordMobileNumberVerified] = useState <boolean>(false);
    
    const[LandlordMobileNumberVerify,setLandlordMobileNumberVerify] = useState<string>("");
    const [startDate, setStartDate] = useState<Date>();
    const [StartDateVerify,setStartDateVerify] = useState<string>('');
    const [StartMortgageDate, setStartMortgageDate] = useState<Date>();


    const [PaymentAmount, setPaymentAmount] = useState<string>(''); 
    const [PaymentAmountFormat, setPaymentAmountFormat] = useState<string>(''); 
    const [PaymentAmountVerify, setPaymentAmountVerify] = useState<string>('');
    const [IsEnableUserInformationDetails,setIsEnableUserInformationDetails] =  useState <boolean>(false); 

    const [IsNavEnable,setIsNavEnable] =  useState <boolean>(true); 
    const [IsDayPaidDisabled, setIsDayPaidDisabled] =  useState <boolean>(true); 

//------------------------Reference------------------
//Reference
const [parentName, setparentName] = useState < string > ("");
const [ParentPhoneNumber, setParentPhoneNumber] = useState < string > ("");
const [ParentPhoneNumberVerify, setParentPhoneNumberVerify] = useState < string > ("");
const [IsPArentPhoneNumberVerified, setIsPArentPhoneNumberVerified] =  useState <boolean>(false);    
const [ParentNameverify, setParentNameverify] = useState < string > ("");
const [IsParentNameVerified, setIsParentNameVerified] = useState <boolean>(false);
const [ParentZipCode,setParentZipCode] =useState<string>("");
const [ParentZipCodeverify,setParentZipCodeverify] =useState<string>("");
const [IsParentZipCodeVerified,setIsParentZipCodeVerified] =  useState <boolean>(false); 
const [ParentState,setParentState] =useState<string>("");

//---parent lastName
const [parentLastName, setparentLastName] = useState < string > ("");
const [ParentLastNameverify, setParentLastNameverify] = useState < string > ("");
const [IsParentLastNameVerified, setIsParentLastNameVerified] = useState <boolean>(false);

//   const [ParentStateVerify,setParentStateVerify] = useState<string>("");
const [IsParentStateVerified,setIsParentStateVerified] =  useState <boolean>(false); 
const [ParentStateVerify,setParentStateVerify] = useState<string>("");
const [IsStep4ContinueButtonDisable, setIsStep4ContinueButtonDisable] =  useState <boolean>(true);
const [ParentBestTime,setParentBestTime] =useState<string>("");
const [ParentBestTimeVerify,setParentBestTimeVerify] =useState<string>("");
const [Relation,setRelation] =useState<string>("");
const [RelationVerify,setRelationVerify] =useState<string>("");
const [RelationAddress1,setRelationAddress1] =useState<string>("");
const [RelationAddress1Verify,setRelationAddress1Verify] = useState<string>("");
const [IsRelationAddress1Verified,setIsRelationAddress1Verified] = useState <boolean>(false);
const [RelationAddress2,setRelationAddress2] =useState<string>("");
const [RelationAddress2Verify,setRelationAddress2Verify] =useState<string>("");
const [RelationApt,setRelationApt] =useState<string>("");
const [RelationAptVerify,setRelationAptVerify] =useState<string>("");
const [RelationCity,setRelationCity] =useState<string>("");
const [RelationCityVerify,setRelationCityVerify] =useState<string>("");
const [IsRelationCityVerified,setIsRelationCityVerified] =useState <boolean>(false);
const [IsParentBestTime,setIsParentBestTime] =useState <boolean>(false);
const [IsDisableStep4ContinueButton,setIsDisableStep4ContinueButton] = useState <boolean>(true);
const [MortgageCompanyName,setMortgageCompanyName] =useState<string>("");
const [MortgageCompanyNameVerify,setMortgageCompanyNameVerify] =useState<string>("")

const [MortgageAmount,setMortgageAmount] =useState<string>("");
const [FormatMortgageAmount,setFormatMortgageAmount] =useState<string>("");
const [MortgageAmountVerify,setMortgageAmountVerify] =useState<string>("");

//Approval
const [Approval,setApproval] =useState<boolean>(true);
const [ApprovalRejected,setApprovalRejected] =useState<boolean>(false);

const setRelationCityValue=(event:any)=>
{
  var regex = /^[a-zA-Z ]*$/ ;
  if(regex.test(event.target.value))
  {
  setRelationCity(event.target.value)
  setIsRelationCityVerified(true);
  setRelationCityVerify('verify-icon');
  setDisablestep4ContinueButton(event.target.id);
  
  }
 if(event.target.value<= 0){setIsDisableStep4ContinueButton(true);setIsRelationCityVerified(false);
  setRelationCityVerify('');}

}
const ParentStateChange = (event:any)=>
{
  
 
  if(event.target.value !=='Select'){
    setParentState(event.target.value);
    setIsParentStateVerified(true);
    setParentStateVerify("verify-icon");
    setDisablestep4ContinueButton(event.target.id);  

  }
  // else{
  //   setIsParentStateVerified(false);
  //   setParentStateVerify("");
  //   setIsDisableStep4ContinueButton(true);
   

  // }
} 

const setParentZipCodeValue =(event:any)=>
{

 const ZipCode = event.target.value.replace(/[^\d]/g, "");

 setParentZipCode(ZipCode);  

 const ZipCodelength = ZipCode.length;
 if(ZipCodelength === 5)
 {
   setParentZipCodeverify('verify-icon');
   setIsParentZipCodeVerified(true);
   setDisablestep4ContinueButton(event.target.id);  
 }
 else
 {
   setParentZipCodeverify('');
   setIsParentZipCodeVerified(false);  
   setIsDisableStep4ContinueButton(true);
 }


}

const setRelationAptValue =(event:any)=>
{
  setRelationApt(event.target.value);
  if(event.target.value <= 0){
    setRelationAptVerify('');}else{setRelationAptVerify('verify-icon');}
}
const setRelationAddress1Value=(event:any)=>
{
    var regex = /^[a-zA-Z0-9_,.# ]*$/;  
  if(regex.test(event.target.value))
  {
   //  setAddressvalue(event.target.value);
    setRelationAddress1(event.target.value)    
    setRelationAddress1Verify('verify-icon');
    setIsRelationAddress1Verified(true);
    setDisablestep4ContinueButton(event.target.id);  
  }
  //else { setIsDisableStep4ContinueButton(true); setIsRelationAddress1Verified(false); setRelationAddress1Verify('verify-icon'); }
    // if (event.target.value.trim().length <= 0) { }
    if(!event.target.value.trim())
    {
      setRelationAddress1Verify(''); setIsRelationAddress1Verified(false); setIsDisableStep4ContinueButton(true);

    }

  
}

const setRelationAddress2Value=(event:any)=>
{
    var regex = /^[a-zA-Z0-9_,.# ]*$/; 
  if(regex.test(event.target.value))
  {
   //  setAddressvalue(event.target.value);
    setRelationAddress2(event.target.value)    
    setRelationAddress2Verify('verify-icon');
  }
  if(event.target.value <= 0){
    setRelationAddress2Verify('');}

  
}

const setRelationValue =(event:any)=>
{
  setRelation(event.target.value);

  if(event.target.value !== 'Select')
  {
    setRelationVerify("verify-icon"); 

  }
  else{setRelationVerify(""); }

}
const setParentNameValue = (event:any)=>
{
 

  var Companyvalue = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');
  setparentName(Companyvalue);
  if(Companyvalue.length !== null && Companyvalue.length !== undefined && Companyvalue.length >0 && event.target.value.trim() !=='')
  {
    setIsParentNameVerified(true);
    setParentNameverify('verify-icon');
    setDisablestep4ContinueButton(event.target.id);
    
  }else
  {
    setIsParentNameVerified(false);
    setParentNameverify('');
    setIsDisableStep4ContinueButton(true);
  }



}

const setParentLastNameValue = (event:any)=>
{

  var Companyvalue = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');
  setparentLastName(Companyvalue);
  if(Companyvalue.length !== null && Companyvalue.length !== undefined && Companyvalue.length >0 && event.target.value.trim() !=='')
  {
    setIsParentLastNameVerified(true);
    setParentLastNameverify('verify-icon');
    setDisablestep4ContinueButton(event.target.id); 
  
  }else
  {
    setIsParentLastNameVerified(false);
    setParentLastNameverify('');
    setIsDisableStep4ContinueButton(true);
  }
  //if(!event.target.value){setIsParentLastNameVerified(false);
  //  setParentLastNameverify('');
  //  setIsDisableStep4ContinueButton(true);}



}


const setParentBestTimevalue = (event:any)=>
{
  setParentBestTime(event.target.value);

  if(event.target.value !== 'Select')
  {
    setIsParentBestTime(true);
    setParentBestTimeVerify("verify-icon");
    setIsParentBestTime(true); 
    setDisablestep4ContinueButton(event.target.id);

  }
  else{ setIsParentBestTime(false);setParentBestTimeVerify("");setIsParentBestTime(false);setIsDisableStep4ContinueButton(true); }

}


const setParentPhoneNumberValue = (event:any)=>
  {
   
    var EmpPhone = formatPhoneNumber(event.target.value);
    

    setParentPhoneNumber(EmpPhone);

    if (EmpPhone.length >= 14) {
      setParentPhoneNumberVerify("verify-icon");
      setIsPArentPhoneNumberVerified(true);      
      setDisablestep4ContinueButton(event.target.id);
    
         
       

    } else {
      setParentPhoneNumberVerify("");
      setIsPArentPhoneNumberVerified(false);
      setIsDisableStep4ContinueButton(true);
    }

  }


const setDisablestep4ContinueButton = (Value:any)=>
{
//  console.log("inga")
//  console.log(IsPArentPhoneNumberVerified)
//  console.log(IsParentBestTime);
//  console.log(IsParentZipCodeVerified);
//  console.log(IsRelationCityVerified);
//  console.log(IsParentStateVerified);
// console.log(IsParentStateVerified);
  switch(Value)
  {
    case 'parentname':
          if (IsParentLastNameVerified &&IsRelationAddress1Verified && IsPArentPhoneNumberVerified && IsParentBestTime && IsParentZipCodeVerified && IsRelationCityVerified && IsParentStateVerified) {
              setIsDisableStep4ContinueButton(false);

          } else { setIsDisableStep4ContinueButton(true); }
    
      break;
      case 'parentphone':
        if(IsParentNameVerified&& IsRelationAddress1Verified && IsParentLastNameVerified&& IsParentBestTime&& IsParentZipCodeVerified&&IsRelationCityVerified&& IsParentStateVerified)
        {
          setIsDisableStep4ContinueButton(false);
  
        } else { setIsDisableStep4ContinueButton(true); }

        break;

        case 'ParentBestTime':
          if(IsParentNameVerified &&IsRelationAddress1Verified && IsParentLastNameVerified&& IsPArentPhoneNumberVerified && IsParentZipCodeVerified&&IsRelationCityVerified&& IsParentStateVerified)
          {
            setIsDisableStep4ContinueButton(false);
    
          } else { setIsDisableStep4ContinueButton(true); }

          break;
          case 'RelationZIP':
            if(IsParentNameVerified  &&IsRelationAddress1Verified && IsParentLastNameVerified&& IsPArentPhoneNumberVerified && IsParentBestTime&&IsRelationCityVerified&& IsParentStateVerified)
            {
              setIsDisableStep4ContinueButton(false);
      
            } else { setIsDisableStep4ContinueButton(true); }

            break;

            case 'ParentCity':
              if(IsParentNameVerified&&IsRelationAddress1Verified && IsParentLastNameVerified&& IsParentBestTime&& IsParentZipCodeVerified&& IsParentStateVerified)
              {
                setIsDisableStep4ContinueButton(false);
        
              } else { setIsDisableStep4ContinueButton(true); }

              break;

              case 'ParentState':
                if(IsParentNameVerified   &&IsRelationAddress1Verified &&IsParentLastNameVerified&& IsPArentPhoneNumberVerified && IsParentBestTime&& IsParentZipCodeVerified&&IsRelationCityVerified)
                {
                  setIsDisableStep4ContinueButton(false);
          
                } else { setIsDisableStep4ContinueButton(true); }

                break;

                case 'parentlastname':
                  if(IsParentNameVerified&&IsRelationAddress1Verified &&IsPArentPhoneNumberVerified && IsParentBestTime&& IsParentZipCodeVerified&&IsRelationCityVerified&& IsParentStateVerified)
                  {
                    setIsDisableStep4ContinueButton(false);
            
                  } else { setIsDisableStep4ContinueButton(true); }
                
                  break;

                  case 'relationaddress1':
                    if(IsParentNameVerified&&IsParentLastNameVerified &&IsPArentPhoneNumberVerified && IsParentBestTime&& IsParentZipCodeVerified&&IsRelationCityVerified&& IsParentStateVerified)
                    {
                      setIsDisableStep4ContinueButton(false);
              
                    } else { setIsDisableStep4ContinueButton(true); }
                  
                    break;

  }
}

  const FormatePaymentAmount = (e:any)=>
  {
    var formatemob = parseFloat(e.target.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //alert(formatemob);
      if (e.target.value !== undefined && e.target.value !== '' && e.target.value !== null) {
          setPaymentAmountFormat(formatemob)
      } else { setPaymentAmountFormat('') }
  
   
  }
  const UnFormatePaymentAmount = () => {
    //alert(e.target.value);
    setPaymentAmountFormat(PaymentAmount);
      
    
}


    const ValidatePaymentAmount = (e:any) => 
    {
        const Amountreplacae = e.target.value.trim().replace(/[^\d]/g, "");
        const regex = /^[+]?\d*(?:[.,]\d*)?$/;
        if (regex.test(e.target.value) && e.target.value.length > 0 && Amountreplacae.length >0)
        {
         
          
          setPaymentAmount(e.target.value);
          setPaymentAmountFormat(e.target.value);
         
          setPaymentAmountVerify('verify-icon');
         
        } 

        if(e.target.value.length <=0 )
        {
         setPaymentAmount(e.target.value);
            setPaymentAmount('');
            setPaymentAmountFormat(e.target.value);
         
          setPaymentAmountVerify('');
        }
    
 }
      
    const setMoveinDate = (event: any) => {


        if (event !== undefined && event !== null) {
            setStartDateVerify(event);
           

            setStartDate(event);

           

          
            
        }
    }

const setMortageMoveinDate=(event:any)=>
{
    
 
  if(event !==''&& event!== undefined && event !==null )
  {
     
      setStartMortgageDate(event);
   
  }

}
 const setLandlordPhoneNumber =(event:any)=>
 {
   const landlordphoneNo = formatPhoneNumber(event.target.value);
   setLandlordMobileNumber(landlordphoneNo);
   
   if (landlordphoneNo.length >= 14) {
    setLandlordMobileNumberVerify("verify-icon");
    setIsLandlordMobileNumberVerified(true);


     if(IsLandlordVerified)
     {
       setDisableStep3ContinueButton(false)
       
     }

  } else {
    setLandlordMobileNumberVerify("");
    setIsLandlordMobileNumberVerified(false);     
    setDisableStep3ContinueButton(true);
  }

 }

  const setLandlordsname =(event:any)=>
  {
   
    var landLordName = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');
    if(landLordName.length !== null && landLordName.length !== undefined &&  landLordName.length < 30){
    setLandlordName(landLordName);
    }
    if(landLordName.length !== null && landLordName.length !== undefined && landLordName.length >0 && event.target.value.trim() !=='')
    {
      setIsLandlordVerified(true);
      setLandlordVerify('verify-icon');

      if(IsLandlordMobileNumberVerified && IsLandlordLastVerified)
      {
        setDisableStep3ContinueButton(false)
     
      }
      else{ setDisableStep3ContinueButton(true)}

     
    }else
    {
      setIsLandlordVerified(false);
      setLandlordVerify('');
      setDisableStep3ContinueButton(true);
    }


  }
  const setLandlordsLastname =(event:any)=>
  {
   
    var landLordName = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');
    if(landLordName.length != null && landLordName.length !== undefined &&  landLordName.length < 30){
    setLandlordLastname(landLordName);
    }
    if(landLordName.length !== null && landLordName.length !== undefined && landLordName.length >0 && event.target.value.trim() !=='')
    {
      setIsLandlordLastVerified(true);
      setLandlordLastVerify('verify-icon');

      if(IsLandlordMobileNumberVerified && IsLandlordVerified)
      {
        setDisableStep3ContinueButton(false)
     
      }
      else{ setDisableStep3ContinueButton(true)}

     
    }else
    {
      setIsLandlordLastVerified(false);
      setLandlordLastVerify('');
      setDisableStep3ContinueButton(true);
    }


  }

  const setRenthouseValue=(event:any)=>
  {
    switch(event.target.id)
    {
      case "Rent" :
        setRenthouse(true) ;
        setMortgageEnable(false);
            if (IsLandlordVerified && IsLandlordMobileNumberVerified) {
                setDisableStep3ContinueButton(false);
            } else { setDisableStep3ContinueButton(true);}
        break;
       case "Own":
         setRenthouse(false);
         setMortgageEnable(true);
         setDisableStep3ContinueButton(false);
         break; 

    }
  }

  const enableMortgage =()=>
  {
    if(MortgageEnable)
    {
      setMortgageEnable(false);

    }
    else{setMortgageEnable(true)}
  }



    const setDisableContinueButton =(value:any)=>
    {
      setEnableContinueButton(value);
      
    }
    
    const setBesttimevalue = (event:any)=>
    {
       setBesttime(event.target.value);
     

      if(event.target.value !=='Select'){setBesttimeVerify('verify-icon');}else{setBesttimeVerify('');}
      
    }

    const StateChange = (event:any)=>
    {
      
      
      if(event.target.value !== 'Select'){
        setState(event.target.value);
        setIsStateVerified(true);
        setEmployerStateVerify("verify-icon");
        setDisablestep2ContinueButton(event.target.id);

      }
      // else{
      //   setIsStateVerified(false);
      //   setEmployerStateVerify("");
      //   setIsStep2ContinueButtonDisable(true);

      // }
    }

    const GovtStateChange = (event: any) => {


        if (event.target.value !== 'Select') {
            setGovtTypeState(event.target.value);
            setIsGovtTypeStateVerified(true);
            setGovtTypeStateVerify("verify-icon");
            if (IsMobileNoVerified === true && IsGovtIdValid === true && IsGovtIDTypeVerified === true) {
                setDisableContinueButton(false);

            }
            else { setDisableContinueButton(true);}
        }
        // else{
        //   setIsStateVerified(false);
        //   setEmployerStateVerify("");
        //   setIsStep2ContinueButtonDisable(true);

        // }
    }

    const MoveToSecond = () =>
    {
        setBasicInfo(false);
        setEmployerInfo(true);
        setEmployerInfoNav('completion-status');

    }
    const moveback=()=>{setBasicInfo(true);
        setEmployerInfo(false);
        setEmployerInfoNav('');
        setIsMobileNumberDisable(true);
      }

    

      const formatPhoneNumber =(value: any)=> {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) {
          return value;
        }
    
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");
    
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
    
        if (phoneNumberLength < 4) return phoneNumber;
    
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
    
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    
      const handleInput = (e:any)=>
      {
            // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
   

    if (formattedPhoneNumber.length >= 14) {
      setMobileNoVerify("verify-icon");
      setIsMobileNoVerified(true);
       if(IsGovtIDTypeVerified && GovtIdType && IsGovtTypeStateVerified === true)
       {
         setDisableContinueButton(false);
         
       }

    } else {
      setMobileNoVerify("");
      setIsMobileNoVerified(false);     
      setDisableContinueButton(true);
    }

    // we'll set the input value using our setInputValue
    setMobileNumberValue(formattedPhoneNumber);

      }

      const setGovtTypeValue = (event:any)=>
      {
        
        if(event.target.value !== 'Select')
        { 
          setGovtType(event.target.value);
          setGovtTypeIdVerify('verify-icon');         
            setGovtId('');
            setDisableContinueButton(true);
            setIsGovtIdDisabled(false);
            setIsGovtIDTypeVerified(true);
            setGovtId('');
            setGovtVerify('');
            setGovtTypeState('Select');
            setIsGovtTypeStateVerified(false);
            setGovtTypeStateVerify('');

            if (event.target.value === 'Passport') {
                setIsGovtStateDisabled(true);
                setGovtTypeState('Select');
                setIsGovtTypeStateVerified(true);
                setGovtTypeStateVerify('');
            } else { setIsGovtStateDisabled(false); }
          
        }
        // else{
        //     setGovtTypeIdVerify(''); setGovtId(''); setDisableContinueButton(true); setGovtId(''); setGovtVerify('');
        // }
        
      }

      const setGovtIdValue =(event:any)=>
      {
          const value = event.target.value.replace(/[^0-9a-zA-Z' .-]/ig, '');
          if (value.trim().length <= 30) {
              
              setGovtId(value);
              if (value.trim() !== '') {

                  setGovtVerify('verify-icon');
                  setGovtIdValid(true);
                  if (IsMobileNoVerified === true && event.target.value.length > 0 && IsGovtIDTypeVerified === true && IsGovtTypeStateVerified === true) {
                      setDisableContinueButton(false);

                  }
                  else { setDisableContinueButton(true); setGovtVerify(''); }
              } else { setDisableContinueButton(true); setGovtVerify(''); }

              // const GovtIds = event.target.value.replace(/[^\d]/g, "")


          }

        

      }


   //set paydayfrequency
    const setPayDayFrequencyValue=(event:any)=>
    {
      setIsStep2ContinueButtonDisable(true);
      if(event.target.value !== 'Select')
      {
        setPayDayFrequency(event.target.value);
        setsetWeeklyDays('');
        setDaypaidVerify('');
        
        setIsPaydayFrequencyVerified(true);
        setPayDayFrequencyVerify("verify-icon");
          if (event.target.value === 'Daily') {            
              setIsDayPaidDisabled(true); setIsDayPaidVerified(true);
              setDisablestep2ContinueButton('PaydayDaily')
          } else { setIsDayPaidDisabled(false); setIsStep2ContinueButtonDisable(true);}
        // if(event.target.value === 'Bi-Weekly' ||event.target.value === 'Weekly'){} ;
      }
      // else
      // {
      //   setIsPaydayFrequencyVerified(false);        
      //   setPayDayFrequencyVerify("");
      //   setIsStep2ContinueButtonDisable(true);
      //   setIsDayPaidDisabled(true);
        
      // }

    }



    const setCompanyNameValue = (event:any)=>
    {

     
      //var Companyvalue = event.target.value.replace(/[^A-Za-z. ]/ig, '');
      var Companyvalue = event.target.value;
      setCompanyName(Companyvalue);
      if(Companyvalue.length !== null && Companyvalue.length !== undefined && Companyvalue.length >0 && event.target.value.trim() !=='')
      {
        setIsCompanyVerified(true);
        setCompanyNameVerify('verify-icon');
        setDisablestep2ContinueButton(event.target.id);
      
      }else
      {
        setIsCompanyVerified(false);
        setCompanyNameVerify('');
        setIsStep2ContinueButtonDisable(true);
      }



    }






    const setHowlongValues = (event:any)=>
    {
      setHowLongValue(event.target.value);
        if (event.target.value !== "Select") {

            setHowlongVerify('verify-icon');
        } else { setHowlongVerify(''); }

    }


    const setMortageValues = (event: any) => {
        setMortageLong(event.target.value);
        if (event.target.value !== "Select") {

            setMortageLongVerify('verify-icon');
        } else { setMortageLongVerify(''); }

    }
    const setRentValues = (event: any) => {
        setRentHouseLong(event.target.value);
        if (event.target.value !== "Select") {

            setRentHouseLongVerify('verify-icon');
        } else { setRentHouseLongVerify(''); }

    }

    const MoveTostep3 = ()=>
    {
      if(Approval)
      { 
        setEmployerInfo(false);
        setReferenceInfo(false);
        setIsEnableUserInformationDetails(true);
        setIsNavEnable(false);}
      else
      {
        setEmployerInfo(false);
        setResidenceInfo(true);
        setResidenceInfoNav('completion-status');
      }
     
    }

    const BackToStep2 =()=>
    {
      setMortgageEnable(true);
      setEmployerInfo(true);
      setResidenceInfo(false);
      setResidenceInfoNav('');

    }

    const MoveTostep4 = ()=>
    {
      setMortgageEnable(true);
      setReferenceInfo(true);
      setResidenceInfo(false);
      setReferenceInfoNav('completion-status');
    }

  const BackToStep3 = ()=>
  {
    setReferenceInfo(false);
    setResidenceInfo(true);
    setReferenceInfoNav('');
  }

  const UpdateCustomer=()=>
  {

    setReferenceInfo(false);
    setIsEnableUserInformationDetails(true);
    setIsNavEnable(false);
     let currentDate = startDate !== undefined ? startDate : (new Date());
     let date1 = moment(currentDate);
     let date2 = moment();
     let lengthofRes = date2.diff(date1, 'year');
     //console.log(lengthofRes);


     let currentMorgagedate = StartMortgageDate !== undefined ? startDate : (new Date());
     let mortageStartingdate1 = moment(currentMorgagedate);
     let mortageStartingdate2 = moment();
     let lengthofMor = mortageStartingdate2.diff(mortageStartingdate1, 'year');
     //console.log(lengthofMor);
 
     let UserInfo = {
      'Approval':Approval,
      'EncodedState':EncodedeState,
      'EncodedCity' : EncodedCity,
      'EncodedAddress' :EncodedAddress,
      'EncodedZip': EncodedPostalCode,
      'CustomerId': CustomerID,
      'MobileNumber': MobileNumber.replace(/[-() ]/g, ''),
      'Besttime': Besttime,
      'GovtIdType': GovtIdType,
      'GovtId': GovtId,
      'GovtIdState' : GovtTypeState,

      'CompanyName':CompanyName ,
      'HowLongValue': HowLongValue,
      'EmployerPhoneNumber': EmployerPhoneNumber.replace(/[-() ]/g, ''),
      'PayDayFrequencyValue':PayDayFrequencyValue ,
      'WeeklyDays':DaysPaid,
      'TakeHomePay': TakeHomePay,
      'EmployerAddress':EmployerAddress ,
      'EmployerAddress2':EmployerAddress2 ,
      'EmployerCity':EmployerCity ,
      'State': State,
      'EmployerZipCode':EmployerZipCode ,

      'Renthouse': Renthouse,
      'LandlordName': LandlordName,
      'LandlordLastName': LandlordLastname,
      'LandlordMobileNumber':LandlordMobileNumber.replace(/[-() ]/g, '') ,
      'startDate': lengthofRes.toString(),
      'PaymentAmount':PaymentAmount,


      'StartMortgageDate': lengthofMor.toString(),
      'MortgageCompanyName':MortgageCompanyName ,
      'MortgageAmount':MortgageAmount ,

      'parentName': parentName,
      'parentLastName' : parentLastName,
      'ParentPhoneNumber': ParentPhoneNumber.replace(/[-() ]/g, ''),
      'ParentBestTime': ParentBestTime,
      'Relation': Relation,
      'RelationAddress1':RelationAddress1 ,
      'RelationAddress2':RelationAddress2 ,
      'RelationCity': RelationCity,
      'ParentZipCode':ParentZipCode,
      'RelationApt' :RelationApt,
      'ParentState': ParentState

      };
      //console.log(UserInfo);
  }

  const EditPageOnclick=()=>
  {
    setIsTermEnable(true);
    if(Approval)
    {   
      setIsEnableUserInformationDetails(false);
      setIsNavEnable(true);
      setEmployerInfo(true);
    }
    else{
    setReferenceInfo(true);
    setIsEnableUserInformationDetails(false);
    setIsNavEnable(true);}

  }

  const setEmployerPhoneNumberValue = (event:any)=>
  {
   
    var EmpPhone = formatPhoneNumber(event.target.value);
    

    setEmployerPhoneNumber(EmpPhone);

    if (EmpPhone.length >= 14) {
      setEmployerPhoneNumberVerify("verify-icon");
      setIsEmployerPhoneNumberVerified(true);
      
      setDisablestep2ContinueButton(event.target.id)
         
       

    } else {
      setEmployerPhoneNumberVerify("");
      setIsEmployerPhoneNumberVerified(false);
      
      setIsStep2ContinueButtonDisable(true);
    }

  }

  const setDisablestep2ContinueButton =(value:any)=>
  {
   switch(value)
   {
     case 'CompanyName':
       if(IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
       {
         setIsStep2ContinueButtonDisable(false);

       }
       else{ setIsStep2ContinueButtonDisable(true);}

       break;
   
   case 'empPhone':
    if(IsCompanyVerified&&IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
    {
      setIsStep2ContinueButtonDisable(false);

    }
    else{ setIsStep2ContinueButtonDisable(true);}

    break;

       case 'PaydayDaily':
           if (IsCompanyVerified && IsEmployerPhoneNumberVerified  && IsTakeHomePayVerified && IsEmployerAddressVerified && IsZipCodeVerified && IsCityVerified && IsStateVerified) {
               setIsStep2ContinueButtonDisable(false);

           }
           else { setIsStep2ContinueButtonDisable(true); }

           break;

    case 'Payday':
      if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
      {
        setIsStep2ContinueButtonDisable(false);

      }
      else{ setIsStep2ContinueButtonDisable(true);}

      break;

      case 'DayPaid':
        if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
        {
          setIsStep2ContinueButtonDisable(false);
 
        }
        else{ setIsStep2ContinueButtonDisable(true);}
 
        break;

        case 'takehomepay':
          if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
          {
            setIsStep2ContinueButtonDisable(false);
   
          }
          else{ setIsStep2ContinueButtonDisable(true);}
   
          break;

          case 'add1':
            if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsZipCodeVerified&&IsCityVerified&&IsStateVerified)
            {
              setIsStep2ContinueButtonDisable(false);
     
            }
            else{ setIsStep2ContinueButtonDisable(true);}
     
            break;
            case 'ZIP':
              if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsCityVerified&&IsStateVerified)
              {
                setIsStep2ContinueButtonDisable(false);
       
              }
              else{ setIsStep2ContinueButtonDisable(true);}
       
              break;
              case 'City':
                if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsStateVerified)
                {
                  setIsStep2ContinueButtonDisable(false);
         
                }
                else{ setIsStep2ContinueButtonDisable(true);}
         
                break;

                case 'State':
                  if(IsCompanyVerified&&IsEmployerPhoneNumberVerified && IsPaydayFrequencyVerified&&IsDayPaidVerified&&IsTakeHomePayVerified&&IsEmployerAddressVerified&&IsZipCodeVerified&&IsCityVerified)
                  {
                    setIsStep2ContinueButtonDisable(false);
           
                  }
                  else{ setIsStep2ContinueButtonDisable(true);}
           
                  break;
            

  }
}

const setWeeklyDaysValue = (event:any)=>
{
  
  if(event.target.value !== 'Select'){
    setsetWeeklyDays(event.target.value);
      if (PayDayFrequencyValue === 'Weekly' || PayDayFrequencyValue === 'Bi-Weekly') {
          switch (event.target.value) {
              case 'SUN':
                  setDaysPaid('1')
                  break;
              case 'MON':
                  setDaysPaid('2')
                  break;
              case 'TUE':
                  setDaysPaid('3')
                  break;
              case 'WED':
                  setDaysPaid('4')
                  break;
              case 'THU':
                  setDaysPaid('5')
                  break;
              case 'FRI':
                  setDaysPaid('6')
                  break;
              case 'SAT':
                  setDaysPaid('7')
                  break;
       }
      }
      else if (PayDayFrequencyValue === 'Semi-Monthly') {
          switch (event.target.value) {
              case '1 & 15':
                  setDaysPaid('1')
                  break;
              case '2 & 16':
                  setDaysPaid('2')
                  break;
              case '3 & 17':
                  setDaysPaid('3')
                  break;
              case '4 & 18':
                  setDaysPaid('4')
                  break;
              case '5 & 19':
                  setDaysPaid('5')
                  break;
              case '6 & 20':
                  setDaysPaid('6')
                  break;
              case '7 & 21':
                  setDaysPaid('7')
                  break;
              case '8 & 22':
                  setDaysPaid('8')
                  break;
              case '9 & 23':
                  setDaysPaid('9')
                  break;
              case '10 & 24':
                  setDaysPaid('10')
                  break;
              case '11 & 25':
                  setDaysPaid('11')
                  break;
              case '12 & 26':
                  setDaysPaid('12')
                  break;
              case '14 & 28':
                  setDaysPaid('13')
                  break;
              case '15 & 30':
                  setDaysPaid('14')
                  break;
              
               
          }
      }

      else if (PayDayFrequencyValue === 'Monthly')
      {
          if (event.target.value === 'LastDay') {
              setDaysPaid('0');

          } else { setDaysPaid(event.target.value) }

      }
      setIsDayPaidVerified(true);
    setDisablestep2ContinueButton(event.target.id);
    setDaypaidVerify('verify-icon');
  }  
  // else{
  //   setIsDayPaidVerified(false);
  //   setDatpaidVerify('');
  //   setIsStep2ContinueButtonDisable(true);
  // }
}


    // const FormatPaymentValue = (num, decimals) => num.toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    // });


    const FormateAmount = (e: any) => {
        //alert(e.target.value);
        var formatemob = parseFloat(e.target.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //alert(formatemob);
        if(e.target.value !== undefined && e.target.value !== '' && e.target.value !== null)
        {
          setTakeHomePayFormat(formatemob);
        }
    }

    const UnFormateAmount = () => {
      //alert(e.target.value);
   
        setTakeHomePayFormat(TakeHomePay);
      
  }

   
const ValidateAmount = (e:any) => 
{
    const Amountreplacae = e.target.value.trim().replace(/[^\d]/g, "");
    const regex = /^[+]?\d*(?:[.,]\d*)?$/;
    if (regex.test(e.target.value) === true && e.target.value.length > 0 && Amountreplacae.length > 0)
    {
      //console.log("inside validate amount");
      setTakeHomePayFormat(e.target.value);    
      setTakeHomePay(e.target.value);
      setIsTakeHomePayVerified(true);
      setTakeHomePayVerify('verify-icon');
      setDisablestep2ContinueButton(e.target.id);
    } 
    // else
    // {
    //   setIsTakeHomePayVerified(false);
    //   setTakeHomePayVerify('');

    // }
    if(e.target.value.length <=0 )
    {
        setTakeHomePayFormat(e.target.value);
        setTakeHomePay(e.target.value);
        setIsTakeHomePayVerified(false);
        setTakeHomePayVerify('');
        setTakeHomePay('');
        setIsStep2ContinueButtonDisable(true);
    }

   };

   //reference payment


   const ValidateMortgageAmount = (e:any) => 
   {
       const Amountreplacae = e.target.value.trim().replace(/[^\d]/g, "");
       const regex = /^[+]?\d*(?:[.,]\d*)?$/;
       if (regex.test(e.target.value) && e.target.value.length > 0 && Amountreplacae.length > 0)
       {      
         setMortgageAmount(e.target.value); 
         setFormatMortgageAmount(e.target.value);
         setMortgageAmountVerify('verify-icon');
       } 
   
       if(e.target.value.length <=0 )
       {
           setMortgageAmountVerify('');
           setMortgageAmount(e.target.value);
           setFormatMortgageAmount(e.target.value);
       }
   
      };

      const FormateMortageAmount = (e:any)=>
      {
        var formateAmount = parseFloat(e.target.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //alert(formatemob);
          if (e.target.value !== undefined && e.target.value !== '' && e.target.value !== null) {
              setFormatMortgageAmount(formateAmount)
          } else { setFormatMortgageAmount('') }
      
       
      }
      const UnFormateMortageAmount = () => {
        //alert(e.target.value);
     
        setFormatMortgageAmount(MortgageAmount);
        
    }
  
  
  
 //Set Value for Address
 
 const setAddressvalue = (event:any)=>
 {
   
   var regex = /^[a-zA-Z0-9_,.# ]*$/ ;
   if(regex.test(event.target.value))
   {
    //  setAddressvalue(event.target.value);
    setEmployerAddress(event.target.value)
     setIsEmployerAddressVerified(true);
     setEmployerAddressVerify('verify-icon');
     setDisablestep2ContinueButton(event.target.id);
   }
   if(event.target.value <= 0){ setIsEmployerAddressVerified(false);
       setEmployerAddressVerify(''); setIsStep2ContinueButtonDisable(true);}

 }

 const setAddress2Value=(event:any)=>
 {
   var regex = /^[a-zA-Z0-9_,.# ]*$/ ;
  if(regex.test(event.target.value))
  {
   //  setAddressvalue(event.target.value);
   setEmployerAddress2(event.target.value)
    
    setEmployerAddress2Verify('verify-icon');
  }
  if(event.target.value <= 0){ 
   setEmployerAddress2Verify('');}

 }


 const setEmployerZipCodeValue =(event:any)=>
 {

  const ZipCode = event.target.value.replace(/[^\d]/g, "");

  setEmployerZipCode(ZipCode);
    

  const ZipCodelength = ZipCode.length;
  if(ZipCodelength === 5)
  {
    setEmployerZipCodeverify('verify-icon');
    setIsZipCodeVerified(true);   
    setDisablestep2ContinueButton(event.target.id);

  }
  else
  {
    setEmployerZipCodeverify('');
    setIsZipCodeVerified(false); 
    setIsStep2ContinueButtonDisable(true);
  }




 }
 const setEmployerCityValue = (event:any)=>
 {
  var regex = /^[a-zA-Z ]*$/ ;
  if(regex.test(event.target.value))
  {
   //  setAddressvalue(event.target.value);
   setEmployerCity(event.target.value)
   setIsCityVerified(true);
    setEmployerCityVerify('verify-icon');
    setDisablestep2ContinueButton(event.target.id);
  }
  if(event.target.value <= 0){ setIsCityVerified(false);
      setEmployerCityVerify(''); setIsStep2ContinueButtonDisable(true);
  }


 }

 const setMortgageCompanyNameValue=(event:any)=>
 {
  var regex = /^[a-zA-Z ]*$/ ;
  if(regex.test(event.target.value))
  {
    setMortgageCompanyName(event.target.value);
    setMortgageCompanyNameVerify('verify-icon');
  }
  if(event.target.value <= 0){ 
    setMortgageCompanyNameVerify('');}
 }

 const SetTermEnable =()=>
 {
   if(IsTermEnable){setIsTermEnable(false);}else{setIsTermEnable(true);}
   
 }
 const SetAddTermEnable =()=>
 {
   console.log("Term Enable", IsAddTermEnable)
   if(IsAddTermEnable){setIsAddTermEnable(false);}else{setIsAddTermEnable(true);}
  
   
 }

 const UpdateCustomerDetails=async()=>
 {
     setIsLookingUpOpen(true);
     let currentDate = startDate !== undefined ? startDate : (new Date());
     let date1 = moment(currentDate);
     let date2 = moment();
     let lengthofRes = date2.diff(date1, 'year');
     //console.log(lengthofRes);


     let currentMorgagedate = StartMortgageDate !== undefined ? startDate : (new Date());
     let mortageStartingdate1 = moment(currentMorgagedate);
     let mortageStartingdate2 = moment();
     let lengthofMor = mortageStartingdate2.diff(mortageStartingdate1, 'year');
     //console.log(lengthofMor);
 
     let UserInfo = {
      'Approval':Approval,
      'EncodedState':EncodedeState,
      'EncodedCity' : EncodedCity,
      'EncodedAddress' :EncodedAddress,
      'EncodedZip': EncodedPostalCode,
      'CustomerId': CustomerID,
      'MobileNumber': MobileNumber.replace(/[-() ]/g, ''),
      'Besttime': Besttime,
      'GovtIdType': GovtIdType,
      'GovtId': GovtId,
      'GovtIdState': GovtTypeState,


      'CompanyName':CompanyName ,
      'HowLongValue': HowLongValue,
      'EmployerPhoneNumber': EmployerPhoneNumber.replace(/[-() ]/g, ''),
      'PayDayFrequencyValue':PayDayFrequencyValue ,
      'WeeklyDays':DaysPaid,
      'TakeHomePay': TakeHomePay,
      'EmployerAddress':EmployerAddress ,
      'EmployerAddress2':EmployerAddress2 ,
      'EmployerCity':EmployerCity ,
      'State': State,
      'EmployerZipCode':EmployerZipCode ,

      'Renthouse': Renthouse,
      'LandlordName': LandlordName,
      'LandlordLastName': LandlordLastname,
      'LandlordMobileNumber': LandlordMobileNumber.replace(/[-() ]/g, ''),
      'startDate': RentHouseLong,
      'PaymentAmount': PaymentAmount,


      'StartMortgageDate': MortageLong,
      'MortgageCompanyName':MortgageCompanyName ,
      'MortgageAmount':MortgageAmount ,

      'parentName': parentName,
      'parentLastName' : parentLastName,
      'ParentPhoneNumber': ParentPhoneNumber.replace(/[-() ]/g, ''),
      'ParentBestTime': ParentBestTime,
      'Relation': Relation,
      'RelationAddress1':RelationAddress1 ,
      'RelationAddress2':RelationAddress2 ,
      'RelationCity': RelationCity,
      'ParentZipCode':ParentZipCode,
      'RelationApt' :RelationApt,
      'ParentState': ParentState,
      'SolicitationFlag' : IsAddTermEnable === true ? 1 : 0

     };

     let UpdateCustomerResponse = await UpdateCustomerService(UserInfo,AuthKey);
     //console.log("Response: ",UpdateCustomerResponse);

     let updateAccessLink = await UpdateAccesslink(AuthKey,false);

     if(UpdateCustomerResponse.AMSResponse !== undefined && UpdateCustomerResponse.AMSResponse.Body !== undefined && UpdateCustomerResponse.AMSResponse.Body.UpdateCustomerResponse !== undefined && UpdateCustomerResponse.AMSResponse.Body.UpdateCustomerResponse.StatusCode ==="200")
     {
         
         window.location.href = '/ProfileComplete';
         setIsLookingUpOpen(false);

     } else {
         window.location.href = '/IdentityVerify?Status=Exception';
     }

 
 }

  

    return(
        <>
        <DOFHeader />
        { IsNavEnable === true ?
         <div className="row justify-content-center me-0">
        <div className="col-md-7 col-mdl-6  mt-5 pe-0">
            <div className="w-100 floats-start">
              {/*Wizard starts*/}

              <ul id="progressbar" className="mx-2">
                {Approval === false ?
                <div>
                <li className="active completion-status"><span className="wizardtxt">Basic Information</span>
                  <span className="accordion-steps d-none">1</span>
                </li>
                <li className={"active "+ EmployerInfoNav}><span className="wizardtxt">Employer</span>
                  <span className="accordion-steps">2</span>
                </li>
                <li className={"active "+ ResidenceInfoNav}><span className="wizardtxt">Residence</span>
                  <span className="accordion-steps">3</span>
                </li>
                <li className={"active "+ ReferenceInfoNav}><span className="wizardtxt">Reference</span>
                  <span className="accordion-steps">3</span>
                </li>
                </div>:
                <div className="ca-approval-div">
                <li className="active completion-status ca-approval-li"><span className="wizardtxt">Basic Information</span>
                  <span className="accordion-steps d-none">1</span>
                </li>
                <li className={"active ca-approval-div "+ EmployerInfoNav}><span className="wizardtxt">Employer</span>
                  <span className="accordion-steps">2</span>
                </li>
                </div>}
              </ul>
             
              </div>
              </div>
              </div> :null}
        <div>
       
       
        {/*body content starts*/}
        <div className="row justify-content-center me-0 mb-5">
         
              {BasicInfo === true ?
              <div className="col-md-7 col-mdl-6">
            <div className="w-100 floats-start">
              {/*Wizard starts*/}
              {/* <ul id="progressbar" className="mx-2">
                <li className="active completion-status"><span className="wizardtxt">Basic Information</span>
                  <span className="accordion-steps d-none">1</span>
                </li>
                <li className={"active "+ EmployerInfoNav}><span className="wizardtxt">Employer</span>
                  <span className="accordion-steps">2</span>
                </li>
                <li className={"active "+ ResidenceInfoNav}><span className="wizardtxt">Residence</span>
                  <span className="accordion-steps">3</span>
                </li>
                <li className={"active "+ReferenceInfoNav}><span className="wizardtxt">Reference</span>
                  <span className="accordion-steps">3</span>
                </li>
              </ul> */}
             
              <div className="w-100 float-start">
                <h5 className="sub-heading-txt mb-4 px-3">Please enter your basic info:</h5>
                <div className="w-100 float-start px-3 mb-3 ">
                  <label className="form-label mandatory" htmlFor="Mobile#">Mobile #</label>
                  <input type="text" onChange={handleInput} className={"form-control "+ MobileNoVerify} value={MobileNumber} id="Mobile" disabled={IsMobileNumberDisable} />
                </div>
                <div className="w-100 float-start px-3 mb-3 ">
                  <label className="form-label" htmlFor="besttime">Best Time to call</label>
                  <select className={"form-select "+ BesttimeVerify} value={Besttime} onChange={setBesttimevalue} id="besttime">                 
                      
                 {BestTimetoCall.map((value,index) => (
                    <option key={index} value={value}>
                       {value}
                     </option>
                  ))}

                  </select>
                </div>
                <div className="w-100 float-start px-3 mb-3 ">
                  <label className="form-label mandatory" htmlFor="govtid">Govt. ID Type</label>
                  <select className={"form-select "+GovtTypeIdVerify} value={GovtIdType} onChange={setGovtTypeValue} id="govtid">
                  {GovtTypeId.map((value,index) => (
                    <option key={index} value={value}>
                       {value}
                     </option>
                  ))}
                  </select>
                </div>
                <div className="w-100 float-start px-3 mb-3 ">
                  <label className="form-label mandatory" htmlFor="id">Govt. ID #</label>
                  <input type="text" className={"form-control " + IsGovtIdVerify} id="id" onChange={setGovtIdValue} disabled={IsGovtIdDisabled} value={GovtId} />
                                    </div>


                <div className="w-100 float-start">
                   <div className="w-100 float-start px-3 mb-3">
                                            <label className="form-label mandatory" htmlFor="State">Govt. ID State #</label>
                                            <select className={"form-select " + GovtTypeStateVerify} value={GovtTypeState} onChange={GovtStateChange} id="State" disabled={IsGovtStateDisabled}>
                 {/* <option selected>Select</option> */}
                 
                       {states.map((value,index) => (
                          <option key={index} value={value}>
                             {value}
                           </option>
                        ))}
                 {/* <option value={1}>One</option>
                 <option value={2}>Two</option>
                 <option value={3}>Three</option> */}
                        </select>
                      </div>
                  </div>
   
              </div>
              {/*Wizard ends*/}
            </div>
            <div className="mx-3">
              <button className="btn btn-primary-custom mt-5  w-100" disabled={EnableContinueButton} onClick={MoveToSecond}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
            </div>
             </div>
                :null}

                {EmployerInfo === true? 
                
                <div className="col-md-7 col-mdl-6">
                <div className="w-100 floats-start ">
                  {/*Wizard starts*/}
                  <div className="w-100 float-start">
                    <h5 className="sub-heading-txt mb-4 px-3">Please give us some information
                      about your source of income
                    </h5>
                    <div className="w-100 float-start px-3 mb-3 ">
                      <label className="form-label mandatory" htmlFor="CompanyName">Company Name</label>
                      <input onChange={setCompanyNameValue} maxLength={30} value={CompanyName} type="text" className={"form-control "+ CompanyNameverify} id="CompanyName" />
                    </div>
                    <div className="w-100 float-start  mb-3 ">
                      <div className="col-md-6 float-start px-3  mb-3 ">
                        <label className="form-label" htmlFor="HowLong?">How Long?</label>
                        <select value={HowLongValue} onChange={setHowlongValues} className={"form-select " + HowlongVerify} id="HowLong">
                        {HowLong.map((value,index) => (
                          <option key={index} value={value}>
                              {value}
                           </option>
                         ))}
                        </select>
                      </div>
                      <div className="col-md-6 float-start px-3  mb-3 ">
                        <label className="form-label mandatory" htmlFor="emp#">Employer Phone #</label>
                        <input type="text" onChange={setEmployerPhoneNumberValue} value={EmployerPhoneNumber} className={"form-control "+ EmployerPhoneNumberVerify} id="empPhone" />
                      </div>
                    </div>
                    <div className="w-100 float-start">
                      <h5 className="sub-form-heading px-3 mb-3">Income Verification</h5> 
                      <div className="w-100 float-start">
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="Payday">Payday Frequency</label>
                          <select className= {"form-select "+ PayDayFrequencyVerify} onChange={setPayDayFrequencyValue} value={PayDayFrequencyValue} id="Payday">
                              {PaydayFrequency.map((value,index) => (
                                <option key={index} value={value}>
                                   {value}
                                 </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="DayPaid">Day Paid</label>
                          <select className={"form-select "+ DaypaidVeirfy } onChange={setWeeklyDaysValue} value={WeeklyDays} id="DayPaid" disabled={IsDayPaidDisabled}>
                          {PayDayFrequencyValue === '' || PayDayFrequencyValue === 'Weekly' || PayDayFrequencyValue === 'Bi-Weekly'?

                          WeekDays.map((value,index) => (
                                <option key={index} value={value}>
                                   {value}
                                 </option>
                              ))

                              :null}

                            {PayDayFrequencyValue === 'Monthly' ?
                            Monthly.map((value,index) => (
                                <option key={index} value={value}>
                                   {value}
                                 </option>
                              )) : null}

                        {PayDayFrequencyValue === 'Semi-Monthly' ?
                            SemiMonthly.map((value,index) => (
                                <option key={index} value={value}>
                                   {value}
                                 </option>
                              )) : null}

              {PayDayFrequencyValue === 'Daily' ?
                           <option value="Select">Select</option> : null}
                          </select>
                        </div>
                      </div>
                      <div className="w-100 float-start">
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="Payday">Take Home Pay</label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                           <input type="text" onChange={ValidateAmount} onFocus={UnFormateAmount} onBlur={FormateAmount} value={TakeHomePayFormat} id="takehomepay" className={"form-control border-r-0 "+ TakeHomePayVerify} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 float-start mb-5">
                      <h5 className="sub-form-heading px-3 mb-3">Employer Address Information</h5>
                      <div className="w-100 float-start px-3 mb-3 ">
                        <label className="form-label mandatory" htmlFor="add1">Address Line 1</label>
                        <input type="text"  onChange={setAddressvalue} value ={EmployerAddress} className={"form-control "+ EmployerAddressVerify} id="add1" maxLength={30} />
                      </div>
                      <div className="w-100 float-start px-3 mb-3 ">
                        <label className="form-label" htmlFor="add2">Address Line 2</label>
                        <input type="text" value={EmployerAddress2} onChange ={setAddress2Value} className={"form-control "+ EmployerAddress2Verify} id="add2" maxLength={30} />
                      </div>
                      <div className="w-100 float-start">
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="ZIP">ZIP Code </label>
                          <input type="text" maxLength={5} onChange={setEmployerZipCodeValue} value={EmployerZipCode} className={"form-control "+ EmployerZipCodeverify} id="ZIP" />
                        </div>
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="City">City</label>
                          <input type="text" onChange={setEmployerCityValue} value={EmployerCity} className={"form-control " + EmployerCityVerify} maxLength={20} id="City" />
                        </div>
                      </div>
                      <div className="w-100 float-start">
                        <div className="col-md-6 float-start px-3  mb-3 ">
                          <label className="form-label mandatory" htmlFor="State">State</label>
                          <select className={"form-select " + EmployerStateVerify} value={State} onChange={StateChange} id="State">
                 {/* <option selected>Select</option> */}
                 
                       {states.map((value,index) => (
                          <option key={index} value={value}>
                             {value}
                           </option>
                        ))}
                 {/* <option value={1}>One</option>
                 <option value={2}>Two</option>
                 <option value={3}>Three</option> */}
               </select>
             </div>
                      </div>
                    </div>
                  </div>
                  {/*Wizard ends*/}
                </div>
                <div className="mx-2 text-center d-md">
                  <button className="prev-button px-4 bg-rem  " onClick={moveback}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
                  <button className="btn btn-primary-custom my-3" onClick={MoveTostep3} disabled={IsStep2ContinueButtonDisable}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                </div>
                <div className="mx-2 text-center d-sm">
                  <button className="btn btn-primary-custom my-3 " onClick={MoveTostep3} disabled={IsStep2ContinueButtonDisable}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                  <button className="prev-button px-4 bg-rem" onClick={moveback} ><img className="me-2" src={PreviousArrow}alt="prev icon" />Previous</button>
                </div>
              </div>
                
                
                
                    :null}



               {ResidenceInfo === true ?
                <div className="col-md-7 col-mdl-6  mt-5 pe-0">
                <div className="w-100 floats-start">
                  <div className="w-100 float-start">
                    <h5 className="sub-heading-txt mb-4 px-3">Please give us some information
                      about your residence
                    </h5>
                    <div className="w-100 float-start px-3 mb-3 ">
                      <label className="form-label mandatory">Do you rent or own?</label>
                      <div className="w-100 float-start">
                        <div className="form-check float-start me-3">
                          <input className="form-check-input"  onClick={setRenthouseValue} checked={Renthouse === true} type="radio" name="flexRadioDefault" id="Rent"  />
                          <label className="form-check-label" htmlFor="Rent">
                            Rent
                          </label>
                        </div>
                        <div className="form-check float-start">
                          <input className="form-check-input"  onClick={setRenthouseValue}  checked={Renthouse === false} type="radio" name="flexRadioDefault" id="Own" />
                          <label className="form-check-label" htmlFor="Own">
                            Own
                          </label>
                        </div>
                      </div>
                    </div>
                    {Renthouse === true ?
                    <div>
                   <div className="w-100 float-start ">
                        <div className="col-md-6 float-start px-3 mb-3">
                          <label className="form-label mandatory" htmlFor="LandlordName">Landlord’s FirstName</label>
                          <input type="text" onChange={setLandlordsname} value={LandlordName} className={"form-control "+LandlordVerify}  id="LandlordName"  />
                        </div>
                        <div className="col-md-6 float-start px-3 mb-3">
                          <label className="form-label mandatory" htmlFor="LandlordName">Landlord’s LastName</label>
                          <input type="text" onChange={setLandlordsLastname} value={LandlordLastname} className={"form-control "+LandlordLastVerify}  id="LandlordName"  />
                        </div>
                    </div>
                    
                    <div className="w-100 float-start px-3 mb-3 ">
                      <label className="form-label mandatory" htmlFor="phone#">Landlord’s Phone #</label>
                      <input type="text" className={"form-control "+ LandlordMobileNumberVerify} onChange={setLandlordPhoneNumber} value={LandlordMobileNumber} id="LandlordsMobileNumber"  />
                    </div>
                     <div className="w-100 float-start px-3 mb-3 ">
                         <label className="form-label" htmlFor="renthouselong">Length of Residency</label>
                         <select className={"form-select " + RentHouseLongVerify } value={RentHouseLong} onChange={setRentValues} id="renthouselong">
                            {HowLong.map((value, index) => (
                             <option key={index} value={value}>
                              {value}
                             </option>
                             ))}
                              </select>
                     </div>
                    <div className="w-100 float-start">
                      <div className="col-md-6 float-start px-3  mb-3 ">
                        <label className="form-label ">Payment Amount</label>
                        <div className="input-group mb-3">
                          <span className="input-group-text">$</span>
                          <input type="text" onChange={ValidatePaymentAmount} onFocus={UnFormatePaymentAmount} onBlur={FormatePaymentAmount} value={PaymentAmountFormat} className={"form-control border-r-0 "+ PaymentAmountVerify} />
                        </div>
                      </div>
                    </div>
               </div>
                :null}
                 {Renthouse ===false ?
                    <div>
                    <div className=" w-100 float-start px-3 mb-3 ">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" onClick={enableMortgage} id="flexCheckChecked" defaultChecked />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Mortgage
                        </label>
                      </div>
                    </div>
                   {MortgageEnable === true ?
                   <div>

                     <div className="w-100 float-start px-3 mb-3 ">
                         <label className="form-label" htmlFor="mortgagelong">Length of Residency</label>
                         <select className={"form-select " + MortageLongVerify } value={MortageLong} onChange={setMortageValues} id="mortgagelong">
                            {HowLong.map((value, index) => (
                             <option key={index} value={value}>
                              {value}
                             </option>
                             ))}
                              </select>
                     </div>                   
                    <div className="w-100 float-start px-3 mb-3 ">
                      <label className="form-label" htmlFor="mortagename1">Mortgage Company Name</label>
                      <input type="text" className={"form-control " + MortgageCompanyNameVerify} id="mortagename1" onChange={setMortgageCompanyNameValue} value={MortgageCompanyName} />
                    </div> 
                    <div className="w-100 float-start">
                      <div className="col-md-6 float-start px-3  mb-3 ">
                        <label className="form-label ">Payment Amount</label>
                        <div className="input-group mb-3">
                          <span className="input-group-text">$</span>
                          <input type="text" onChange={ValidateMortgageAmount} onBlur={FormateMortageAmount} onFocus={UnFormateMortageAmount} value={FormatMortgageAmount} className={"form-control border-r-0 "+ MortgageAmountVerify} />
                        </div>
                      </div>
                    </div>
                  </div> :null}
                  </div> :null} 
                  </div>
                  {/*Wizard ends*/}
                </div>
                <div className="mx-2 text-center d-md">
                  <button className="prev-button px-4 bg-rem " onClick={BackToStep2}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
                  <button className="btn btn-primary-custom my-3" disabled={DisableStep3ContinueButton} onClick={MoveTostep4}>CONTINUE<img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                </div>
                <div className="mx-2 text-center d-sm">
                  <button className="btn btn-primary-custom my-3 " disabled={DisableStep3ContinueButton} onClick={MoveTostep4}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                  <button className="prev-button px-4 bg-rem " onClick={BackToStep2}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
                </div>
              </div>
               :null}
               {ReferenceInfo?   <div className="col-md-7 col-mdl-6  mt-5 pe-0">
        <div className="w-100 floats-start">
          {/*Wizard starts*/}
          <div className="w-100 float-start mb-5">
            <h5 className="sub-heading-txt mb-4 px-3">Please add a parent or a relative
              as a reference
            </h5>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label mandatory" htmlFor="parentname">Parent / Relative FirstName </label>
              <input type="text" value={parentName} onChange={setParentNameValue} className={"form-control "+ ParentNameverify} id="parentname" maxLength={30} />
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label mandatory" htmlFor="parentlastname">Parent / Relative LastName </label>
              <input type="text" value={parentLastName} onChange={setParentLastNameValue} className={"form-control "+ ParentLastNameverify} id="parentlastname" maxLength={30}/>
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label mandatory" htmlFor="parentphone">Phone #</label>
              <input type="text" onChange={setParentPhoneNumberValue} value={ParentPhoneNumber} className={"form-control "+ ParentPhoneNumberVerify} id="parentphone" />
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label mandatory" htmlFor="ParentBestTime">Best Time to Call</label>
              <select className={"form-select " + ParentBestTimeVerify} value={ParentBestTime} onChange={setParentBestTimevalue} id="ParentBestTime">
              {BestTimetoCall.map((value,index) => (
                    <option key={index} value={value}>
                       {value}
                     </option>
                  ))}
              </select>
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label" htmlFor="Relationship">Relationship</label>
              <select className={"form-select " + RelationVerify} id="Relationship" value={Relation} onChange={setRelationValue}>
              {RelationShip.map((value,index) => (
                    <option key={index} value={value}>
                       {value}
                     </option>
                  ))}
              </select>
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label mandatory" htmlFor="Relationadd1">Address Line 1</label>
              <input type="text" onChange={setRelationAddress1Value} value={RelationAddress1}  className={"form-control "+ RelationAddress1Verify} id="relationaddress1" maxLength={30} />
            </div>
            <div className="w-100 float-start px-3 mb-3 ">
              <label className="form-label" htmlFor="add2">Address Line 2</label>
              <input type="text"onChange={setRelationAddress2Value} value={RelationAddress2}  className={"form-control "+ RelationAddress2Verify} id="relationaddress1" maxLength={30} />
            </div>
            <div className="w-100 float-start">
              <div className="col-md-6 float-start px-3  mb-3 ">
                <label className="form-label" htmlFor="Relationapt">Apt # / Unit #</label>
                <input type="text" maxLength={6} className={"form-control " + RelationAptVerify} value={RelationApt} onChange={setRelationAptValue} id="Relationapt" />
              </div>
              <div className="col-md-6 float-start px-3  mb-3 ">
                <label className="form-label mandatory" htmlFor="RelationZIP">ZIP Code </label>
                <input type="text" onChange={setParentZipCodeValue} className={"form-control "+ ParentZipCodeverify} value={ParentZipCode} maxLength={5} id="RelationZIP" />
              </div>
            </div>
            <div className="w-100 float-start">
              <div className="col-md-6 float-start px-3  mb-3 ">
                <label className="form-label mandatory" htmlFor="ParentCity">City</label>
                <input type="text" value={RelationCity} onChange={setRelationCityValue} className={"form-control "+ RelationCityVerify} id="ParentCity" />
              </div>
              <div className="col-md-6 float-start px-3  mb-3 ">
                <label className="form-label mandatory" htmlFor="ParentState">State</label>
                <select className={"form-select " + ParentStateVerify} value={ParentState} onChange={ParentStateChange} id="ParentState">
                {states.map((value,index) => (
                          <option key={index} value={value}>
                             {value}
                           </option>
                        ))}
                </select>
              </div>
            </div>
          </div>
          {/*Wizard ends*/}
        </div>
        <div className="mx-2 text-center d-md">
          <button className="prev-button px-4 bg-rem" onClick={BackToStep3}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
          <button className="btn btn-primary-custom my-3" onClick={UpdateCustomer} disabled={IsDisableStep4ContinueButton}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
        </div>
        <div className="mx-2 text-center d-sm">
          <button className="btn btn-primary-custom my-3 " disabled={IsDisableStep4ContinueButton} onClick={UpdateCustomer}>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
          <button className="prev-button px-4 bg-rem"  onClick={BackToStep3} ><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
        </div>
      </div>
               :null}
               
         </div>
        
        {/*body content ends*/}    
     
     
      </div>
      {IsEnableUserInformationDetails===true ?
       <div>
           <div className="row justify-content-center me-0 mb-5">
        <div className="col-md-8 col-mdl-6  mt-5 pe-0">
          <h4 className="blue-title-txt mb-3 px-2 text-center px-3">Review Your Information</h4>
          <div className="information-container float-start w-100 p-3 mb-5">
            <div className="w-100 float-start mb-3">
              <p className="label-header-style px-3">Basic Information</p>
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Mobile: </span>
                <span className="label-style-txt font-regular">{MobileNumber}</span>
              </div>
              {Besttime !== 'Select'&& Besttime !== '' ?
               <div className="w-100 float-start mb-3 px-3">
                 <span className="label-style-txt">Best Time to Call: </span>
                 <span className="label-style-txt font-regular"> {Besttime}</span>
               </div>:null}
              
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Govt. ID Type:</span>
                <span className="label-style-txt font-regular"> {GovtIdType}</span>
              </div>
              {GovtId !== '' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Govt. ID #: </span>
                <span className="label-style-txt font-regular">{GovtId}</span>
              </div> : null}

            {GovtTypeState !== '' && GovtTypeState !== 'Select' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Govt. ID State # :</span>
                <span className="label-style-txt font-regular">{GovtTypeState}</span>
            </div> : null}

            </div>
            <div className="w-100 float-start">
              <p className="label-header-style px-3">Employment Information</p>
              {CompanyName !== ''?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Company Name:</span>
                <span className="label-style-txt font-regular"> {CompanyName}</span>
              </div>
              :null}
             {HowLongValue !== 'Select' && HowLongValue !=='' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">How Long?:</span>
                <span className="label-style-txt font-regular"> {HowLongValue}</span>
              </div>
              :null}
              {EmployerPhoneNumber !== ''?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Employer Phone #:</span>
                <span className="label-style-txt font-regular"> {EmployerPhoneNumber}</span>
              </div>
              :null}
              {PayDayFrequencyValue !== '' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Payday Frequency: </span>
                <span className="label-style-txt font-regular">{PayDayFrequencyValue}</span>
              </div> :null}
              {WeeklyDays !== 'Select' && PayDayFrequencyValue !== 'Daily' && WeeklyDays !==''?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Day Paid: </span>
                <span className="label-style-txt font-regular">{WeeklyDays}</span>
              </div>
              :null}
              {TakeHomePayFormat !== '' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Take Home Pay: </span>
                <span className="label-style-txt font-regular">{ '$' + TakeHomePayFormat }</span>
              </div> :null}
              {EmployerAddress !== ''?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Address Line 1: </span>
                <span className="label-style-txt font-regular"> {EmployerAddress}</span>
              </div>
              :null}
              {EmployerAddress2 !== '' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Address Line 2: </span>
                <span className="label-style-txt font-regular"> {EmployerAddress2}</span>
              </div>
              :null }
              <div className="w-100 float-start mb-3">
                {EmployerCity !== '' ?
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">City: </span>
                  <span className="label-style-txt font-regular"> {EmployerCity}</span>
                </div>:null }
                {State !== '' ?
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">State: </span>
                  <span className="label-style-txt font-regular"> {State}</span>
                </div>
                :null}
                {EmployerZipCode !== '' ?
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">ZIP Code: </span>
                  <span className="label-style-txt font-regular"> {EmployerZipCode}</span>
                </div>
                :null}
              </div>
            </div>
            {Approval === false ?
            <div>
            <div className="w-100 float-start">
              <p className="label-header-style px-3">Residence Information</p>
              {Renthouse === true ?
              <div>
                  <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt">Rent House : </span>
                    <span className="label-style-txt font-regular"> Yes </span>
                  </div>
                  <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt">Landlord’s Name : </span>
                    <span className="label-style-txt font-regular"> {LandlordName}</span>
                  </div>
                  
                  <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt">Landlord’s Phone : </span>
                    <span className="label-style-txt font-regular">{LandlordMobileNumber}</span>
                  </div>
                  {PaymentAmountFormat !== '' ?
                   <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt">PaymentAmount: </span>
                    <span className="label-style-txt font-regular">{PaymentAmountFormat}</span>
                  </div>:null}


                    {RentHouseLong !== null && RentHouseLong !== undefined ?
                  <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt"> Length Of Residence </span>
                    <span className="label-style-txt font-regular">{RentHouseLong}</span>
                  </div>:null}
          
              </div> :
              <div>
                <div className="w-100 float-start mb-3 px-3">
                    <span className="label-style-txt">Own  House : </span>
                    <span className="label-style-txt font-regular"> Yes </span>
                  </div>
               {MortageLong !== null && MortageLong !== undefined ?
             <div className="w-100 float-start mb-3 px-3">
               <span className="label-style-txt">Length Of Residence: </span>
               <span className="label-style-txt font-regular">{MortageLong}</span>
             </div>:null}
             {MortgageCompanyName !== null&& MortgageCompanyName !== undefined &&  MortgageCompanyName !== '' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Mortgage Company Name: </span>
                <span className="label-style-txt font-regular">{MortgageCompanyName}</span>
              </div>
              :null}
           {FormatMortgageAmount !== null && FormatMortgageAmount !==undefined && FormatMortgageAmount !=='' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Payment Amount: </span>
                <span className="label-style-txt font-regular"> {FormatMortgageAmount}</span>
              </div>:null}
              </div> }
                   

            </div>
            <div className="w-100 float-start">
              <p className="label-header-style px-3">Reference Information</p>
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Parent / Relative FirstName : </span>
                <span className="label-style-txt font-regular"> {parentName}</span>
              </div>
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Parent / Relative LastName : </span>
                <span className="label-style-txt font-regular"> {parentLastName}</span>
              </div>

              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Phone # : </span>
                <span className="label-style-txt font-regular"> {ParentPhoneNumber}</span>
              </div>
             {ParentBestTime !=='Select' && ParentBestTime !=='' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Best Time to Call : </span>
                <span className="label-style-txt font-regular"> {ParentBestTime}</span>
              </div> :null}
              {Relation !== '' && Relation !=='Select' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Relationship : </span>
                <span className="label-style-txt font-regular">{Relation}</span>
              </div>
              :null}
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Address Line 1: </span>
                <span className="label-style-txt font-regular"> {RelationAddress1}</span>
              </div>
              { RelationAddress2 !=='' ?
              <div className="w-100 float-start mb-3 px-3">
                <span className="label-style-txt">Address Line 2: </span>
                <span className="label-style-txt font-regular"> {RelationAddress2}</span>
              </div>:null}
              <div className="w-100 float-start mb-3">
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">City: </span>
                  <span className="label-style-txt font-regular"> {RelationCity}</span>
                </div>
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">State: </span>
                  <span className="label-style-txt font-regular"> {ParentState}</span>
                </div>
                <div className="col-md-4 float-start px-3 mb-3 ">
                  <span className="label-style-txt">ZIP Code: </span>
                  <span className="label-style-txt font-regular"> {ParentZipCode}</span>
                </div>
              </div>
            </div>
    
            </div> 
               :null}
          
           </div>
           <div className="w-100 float-left">
          <div className="form-check fl m-3">
           <input type="checkbox" onClick={SetTermEnable}  className="form-check-input default-sizing-checkbox" id="terms-and-conditions" />
          <p className="form-check-label ms-2 p-js">
          I certify that the information I have provided in this rental order form is correct, that I am not aware of any facts that could lead to my filing for bankruptcy and that I have not consulted with any attorney regarding bankruptcy in the past 90 days. I understand that Rent-A-Center will not fulfill my rental order if any of the information I provide is inaccurate. I authorize Rent-A-Center to contact any person or business (including employers and landlords) I have provided on this rental order form to verify the information or to obtain location information about me throughout the duration of my agreement.  This authorization ends when a person or business asks Rent-A-Center not to contact them or I make the request in writing to Rent-A-Center.
          </p>
          <p className="form-check-label ms-2 p-js">
          You understand that by clicking on the “I AGREE & CONTINUE” button immediately following this notice, you are providing written instructions to Rent-A-Center under the Fair Credit Reporting Act authorizing Rent-A-Center to obtain information about you from credit reporting agencies (including but not limited to Experian, Clarity, Equifax, and TransUnion) or others, including your creditors and other third parties, (1) in connection with this transaction for the purpose of evaluating your rental purchase application, to confirm your identity, and to avoid fraudulent transactions, and (2) at any time and use that information for the purposes of (a) considering you for new offers, programs, and services that Rent-A-Center, its affiliates, or third parties may offer in the future, and (b) for marketing such programs to you.
          </p>
        
        </div>
        <div className="form-check fl m-3">
           <input type="checkbox" onClick={SetAddTermEnable}  className="form-check-input default-sizing-checkbox" id="terms-and-conditions2" />
          <p className="form-check-label ms-2 p-js">
          I further agree Rent-A-Center and its agents may deliver marketing sales calls and text messages regarding Rent-A-Center goods, services or offers to at the telephone number provided above using an automated system for the selection or dialing of telephone numbers. I understand that I am not required to consent to marketing calls and text messages in order to obtain goods or services from Rent-A-Center or open a Rent-A-Center account.  To provide written notice of withdrawal, write to: RAC Opt Out, Attn: Marketing Dept., 5501 Headquarters Drive, Plano, Texas 75024. Withdrawal of consent will not adversely affect my agreement(s) with Rent-A-Center.
           </p>
         
        
        </div>
      </div>
          



          
          <div className="mx-2 text-center d-md">
            <button className="prev-button px-4 bg-rem" onClick={EditPageOnclick}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
            <button className="btn btn-primary-custom my-3" onClick={UpdateCustomerDetails} disabled={IsTermEnable}>FINISH <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
          </div>
          <div className="mx-2 text-center d-sm">
            <button className="btn btn-primary-custom my-3"  onClick={UpdateCustomerDetails} disabled={IsTermEnable}>FINISH <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
            <button className="prev-button px-4 bg-rem" onClick={EditPageOnclick} ><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
          </div>
        </div>
      </div>
       </div>:null
      }

            <Modal isOpen={IsLookingUpOpen} className='test'>
                <div className="modal fade show" id="loader" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-body py-4">
                                <p className="popup-txt-style mb-0"><img className="me-2" src={Loader} alt="loader icon" />Loading Please wait</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

      </>
    
        );
}
export default UserVerifyInformation;
