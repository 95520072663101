/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import DOFHeader from './DOFHeader';
import '../css/commontheme.css'
import '../css/stylesheet.css'
import PreviousArrow from '../images/prev-icon.svg'
import NextArrow from '../images/arrow-right.svg'
import { useState, useEffect } from 'react';
// import DayPicker from 'react-day-picker';
//import 'react-day-picker/lib/style.css';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import 'flatpickr/dist/ie.css';
import { states } from '../Config/Config';
import Modal from 'react-modal';
import { GetPayfoneDetails, getExsitingCustomerDetails, GetApproval, UpdateAccesslink } from '../Service/Service';
import Loader from '../images/loader-icon.svg';
import moment from 'moment';


import "react-datepicker/dist/react-datepicker.css";
//import BasicInfo from './BasicInfo';


function PayfonePrefillForm() {
    //StateVariables
    const [IsPayfonePrefill, setIsPayfonePrefill] = useState<boolean>(false);
    const [IsDobChanged, setIsDobChanged] = useState<boolean>(false);
    const [IsNameChanged, setIsNameChanged] = useState<boolean>(false);
    const [IsAddressChanged, setIsAddressChanged] = useState<boolean>(false);
    const [IsLookingUpOpen, setIsLookingUpOpen] = useState<boolean>(true);
    const [IsLookingUpOpenup, setIsLookingUpOpenup] = useState<boolean>(true);
    const [MaskedFullSSN, setMaskedFullSSN] = useState<string>('');
    const [FullSSN, setFullSSN] = useState<string>('');
    const [FirstName, setFirstName] = useState<string>('');
    const [LastName, setLastName] = useState<string>('');
    const [Address1, setAddress1] = useState<string>('');
    const [Address2, setAddress2] = useState<string>('');
    const [AptNumber, setAptNumber] = useState<string>('');
    const [ZipCode, setZipCode] = useState<string>('');
    const [City, setCity] = useState<string>('');
    const [Email, setEmail] = useState<string>('');
    const [DateOfBirth, setDateOfBirth] = useState<string>('');
    const [FormateDateOfBirth, setFormateDateOfBirth] = useState<string>('');
    const [State, setState] = useState<string>('Select');
    const [MobileNumber, setMobileNumber] = useState<string>('');

    const [MaskedDateOfBirth, setMaskedDateOfBirth] = useState<string>('');
    const [IsSSNDisabled, setIsSSNDisabled] = useState<boolean>(false);
    const [IsDOBDisabled, setIsDOBDisabled] = useState<boolean>(false);



    //Verify State Variables
    const [FirstNameVerify, setFirstVerify] = useState<string>('');
    const [IsFirstNameVerified, setIsFirstNameVerified] = useState<boolean>(false);
    const [LastNameVerify, setLastNameVerify] = useState<string>('');
    const [IsLastNameVerified, setIsLastNameVerified] = useState<boolean>(false);
    const [SSNVerify, setSSNVerify] = useState<string>('');
    const [IsSSNVerified, setIsSSNVerified] = useState<boolean>(false);
    const [DOBVerify, setDOBVerify] = useState<string>('');
    const [EmailVerify, setEmailVerify] = useState<string>('');
    const [IsEmailVerified, setIsEmailVerified] = useState<boolean>(false);
    const [IsDOBVerified, setIsDOBVerified] = useState<boolean>(false);
    const [Address1Verify, setAddress1Verify] = useState<string>('');
    const [IsAddress1Verified, setIsAddress1Verified] = useState<boolean>(false);
    const [Address2Verify, setAddress2Verify] = useState<string>('');
    const [AptNumberVerify, setAptNumberVerify] = useState<string>('');
    const [ZipCodeVerify, setZipCodeVerify] = useState<string>('');
    const [IsZipCodeVerified, setIsZipCodeVerified] = useState<boolean>(false);
    const [CityVerify, setCityVerify] = useState<string>('');
    const [IsCityVerified, setIsCityVerified] = useState<boolean>(false);
    const [StateVerify, setStateVerify] = useState<string>('');
    const [StartDateVerify, setStartDateVerify] = useState<string>('');
    const [IsStateVerified, setIsStateVerified] = useState<boolean>(false);
    const [IsFirstNameDisabled, setIsFirstNameDisabled] = useState<boolean>(false);
    const [IsLastNameDisabled, setIsLastNameDisabled] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<Date>();
    const [IsEditInfo, setIsEditInfo] = useState<boolean>(false);
    const [Authkey, setAuthKey] = useState('');
    const [DOBMaxDate, setDOBMaxDate] = useState<Date>();
    const [DOBMinDate, setDOBMinDate] = useState<Date>();
    //Exsigtingcustomer verbiage hide and show
    const [IsTextMessgeEnble, setIsTextMessgeEnble] =  useState<boolean>(false);

    useEffect(() => {

        //setFirstName('Gowtham');
        //setLastName('Haridass');
        //setFullSSN('123456789')
        //setState('TX');
        //setAddress1('1202 S Rangeline Road');
        //setDateOfBirth('May 19 XXXX');
        //setStartDate(new Date('1999-05-19'));
        //setEmail('gowtham.h@avasoft.com');
        //setAptNumber('19');
        //setZipCode('10001');
        //setCity('Texas');
        // var test = encryptSSN('1234555678');

        // var test1 = decryptSSN(test);
        // alert (test);
        // alert(test1);
        const params = new URLSearchParams(window.location.search) // id=123
        const EncodeAuthKey = params.get('AuthKey') || "";
        const MobileNumber = sessionStorage.getItem("MobileNo") !== null && sessionStorage.getItem("MobileNo") !== undefined && sessionStorage.getItem("MobileNo") !== '' ? sessionStorage.getItem("MobileNo") : '';
        const LastFourSSN = sessionStorage.getItem("LFN") !== null && sessionStorage.getItem("LFN") !== undefined && sessionStorage.getItem("LFN") !== '' ? sessionStorage.getItem("LFN") : '';
        const EmailId = sessionStorage.getItem("EmailId") !== null && sessionStorage.getItem("EmailId") !== undefined && sessionStorage.getItem("EmailId") !== '' ? sessionStorage.getItem("EmailId") : '';
        setAuthKey(EncodeAuthKey);

        BindData(MobileNumber, LastFourSSN, EncodeAuthKey, EmailId);

        console.log("Inside Useeffect")




    }, [])


    //Bind data to bind the data which is fetxh from query param and make call AMS005 to get the value
    const BindData = async (MobileNumber, LastFourSSN, EncodeAuthKey, EmailId) => {
        let Max_date = moment().subtract(18, 'years');
        let aug = new Date(Max_date.toString());
        setDOBMaxDate(aug);

        let Min_date = moment().subtract(100, 'years');
        let min = new Date(Min_date.toString());
        setDOBMinDate(min);

        let EncodedMobileNumber = new Buffer(MobileNumber, 'base64');
        let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');
        setMobileNumber(DecodedMobileNumber);
        let EncodedLastFourSSN = new Buffer(LastFourSSN, 'base64');
        let DecodedLastFourSSN = EncodedLastFourSSN.toString('ascii');
        let EncodedEmail = new Buffer(EmailId, 'base64');
        let DecodedEmail = EncodedEmail.toString('ascii');

        let AMSReponse = await getExsitingCustomerDetails(DecodedMobileNumber, EncodeAuthKey);
        //console.log(AMSReponse);
        if (AMSReponse !== null && AMSReponse !== undefined) {
            if (AMSReponse.Message === undefined && AMSReponse.AMSResponse.StatusCode === "200" && (AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].Addresses.AddressInfo).length > 0) {

                
                interface Addresses {
                  AddressType: string;
                  PrimaryAddress: string;
                  StateProvince: string;
                  Active: string;
	              Address1: string;
                  Address2: string;
                  City: string;
                  PostalCode: string;
                }
                
	            const addressvalue :Addresses[] = AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].Addresses.AddressInfo;
	  

                const PrimaryAddressValue = addressvalue.find(x => x.PrimaryAddress==='1'&& x.Active==='1')
                //console.log("got the AMS value", AMSReponse);
                // let updateAccessLink = await UpdateAccesslink(EncodeAuthKey, false);
                // let sessionMobileNumber = sessionStorage.getItem('MobileNo') || '';
                // let EncodedMobileNumber = new Buffer(sessionMobileNumber, 'base64');
                // let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');
                // 'DateOfBirth': AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].DateOfBirth,
                // 'NationalIDNumber': AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].NationalIDNumber,
                
                setFirstName(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].FirstName);
                setLastName(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].LastName);
                setFullSSN(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].NationalIDNumber);
                setState(PrimaryAddressValue !== undefined&& PrimaryAddressValue.StateProvince !== undefined ? PrimaryAddressValue.StateProvince:'');
                setAddress1(PrimaryAddressValue !== undefined&& PrimaryAddressValue.Address1 !== undefined ? PrimaryAddressValue.Address1:'');
                setAddress2(PrimaryAddressValue !== undefined&& PrimaryAddressValue.Address2 !== undefined ? PrimaryAddressValue.Address2:'')
                let dob = moment(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].DateOfBirth).format("MM/DD/").toString() + "XXXX";
                setDateOfBirth(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].DateOfBirth);
                setMaskedDateOfBirth(dob);
                let doboriginal = moment(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].DateOfBirth).format("YYYYMMDD");
                setFormateDateOfBirth(doboriginal);
                setStartDate(new Date(AMSReponse.AMSResponse.Body.FindCustomerResponse.Customers.Customer[0].DateOfBirth));
                setAptNumber('');
                setEmail(DecodedEmail);
                var fullZip = PrimaryAddressValue !== undefined&& PrimaryAddressValue.PostalCode !== undefined ? PrimaryAddressValue.PostalCode:'';
                var FiveDigitZipCode = fullZip.substring(0, 5);
                setZipCode(FiveDigitZipCode);
                setCity(PrimaryAddressValue !== undefined&& PrimaryAddressValue.City !== undefined ? PrimaryAddressValue.City:'');
                //While prefilling need to make all verified key becos we are gettting and populating data from payfone
                setIsFirstNameVerified(true);
                setIsLastNameVerified(true);
                setIsSSNVerified(true);
                setIsDOBVerified(true);
                setIsZipCodeVerified(true);
                setIsCityVerified(true);
                setIsAddress1Verified(true);
                setIsStateVerified(true);
                setIsEmailVerified(true);
                setIsSSNDisabled(true);
                setIsDOBDisabled(true);
                var verifyicon = 'verify-icon'
                setFirstVerify(verifyicon);
                setLastNameVerify(verifyicon);
                setSSNVerify(verifyicon);
                setDOBVerify(verifyicon);
                setZipCodeVerify(verifyicon);
                setCityVerify(verifyicon);
                setAddress1Verify(verifyicon);
                setStateVerify(verifyicon);
                setEmailVerify(verifyicon)
                //makecontinuebuttonenable
                setIsPayfonePrefill(true);
                setIsLookingUpOpenup(false);
                setIsTextMessgeEnble(true);
                setIsFirstNameDisabled(true);
                setIsLastNameDisabled(true);
       
        

            }
            else {
                let payfoneRes = await GetPayfoneDetails(DecodedMobileNumber, DecodedLastFourSSN, EncodeAuthKey);
                //console.log("got the value", payfoneRes);
                setEmail(DecodedEmail);
                setEmailVerify('verify-icon');
                setIsEmailVerified(true);
                if (AMSReponse.responseInfo === undefined && payfoneRes.response !== undefined && payfoneRes.response.phoneNumber !== undefined && payfoneRes.response.phoneNumber !== '' && payfoneRes.response.phoneNumber !== null) {

                    let fulladdress = payfoneRes.response.individual.addresses[0].address;
                    let AptNumber = fulladdress.substr(0, fulladdress.indexOf(' '));
                    let address = fulladdress.substr(fulladdress.indexOf(' ') + 1);
                    setFirstName(payfoneRes.response.individual.firstName);
                    setLastName(payfoneRes.response.individual.lastName);
                    setFullSSN(payfoneRes.response.individual.ssn);
                    setState(payfoneRes.response.individual.addresses[0].region);
                    setAddress1(address);
                    setAddress2(payfoneRes.response.individual.addresses[0].extendedAddress)
                    let dob = moment(payfoneRes.response.individual.dob.toString(), "YYYYMMDD").format("MM/DD/").toString() + "XXXX";
                    setDateOfBirth(payfoneRes.response.individual.dob);
                    setMaskedDateOfBirth(dob);

                    let doboriginal = moment(payfoneRes.response.individual.dob.toString()).format("YYYYMMDD");

                    setFormateDateOfBirth(doboriginal);

                    setStartDate(new Date(payfoneRes.response.individual.dob));
                    //setEmail('gowtham.h@avasoft.com');
                    setAptNumber(AptNumber);
                    let fullZip = payfoneRes.response.individual.addresses[0].postalCode;
                    let FiveDigitZipCode = fullZip.substring(0, 5);
                    setZipCode(FiveDigitZipCode);
                    setCity(payfoneRes.response.individual.addresses[0].city);
                    //While prefilling need to make all verified key becos we are gettting and populating data from payfone
                    setIsFirstNameVerified(true);
                    setIsLastNameVerified(true);
                    setIsSSNVerified(true);
                    setIsDOBVerified(true);
                    setIsZipCodeVerified(true);
                    setIsCityVerified(true);
                    setIsAddress1Verified(true);
                    setIsStateVerified(true);
                    setIsEmailVerified(true);
                    setIsSSNDisabled(true);
                    setIsDOBDisabled(true);

                    var verifyicon = 'verify-icon'
                    setFirstVerify(verifyicon);
                    setLastNameVerify(verifyicon);
                    setSSNVerify(verifyicon);
                    setDOBVerify(verifyicon);
                    setZipCodeVerify(verifyicon);
                    setCityVerify(verifyicon);
                    setAddress1Verify(verifyicon);
                    setStateVerify(verifyicon);
                    //makecontinuebuttonenable
                   

                    setIsPayfonePrefill(true);
                    setIsLookingUpOpenup(false);


                } else { 
                    setIsEditInfo(true); setIsPayfonePrefill(false); 
                    let FirstNamesession = sessionStorage.getItem('FirstName') || '';
                    let EncodedFirstName = new Buffer(FirstNamesession, 'base64');
                    let DecodedFirstName = EncodedFirstName.toString('ascii');
                    let LastNamesession = sessionStorage.getItem('LastName') || '';
                    let EncodedLastName = new Buffer(LastNamesession, 'base64');
                    let DecodedLastName = EncodedLastName.toString('ascii');
                    setFirstName(DecodedFirstName);
                    setLastName(DecodedLastName);
                    var verifyicon = 'verify-icon'
                    setFirstVerify(verifyicon);
                    setLastNameVerify(verifyicon);
                    setIsFirstNameVerified(true);
                    setIsLastNameVerified(true);
                }
            }

        }



        setIsLookingUpOpen(false);
        setIsLookingUpOpenup(false)
    }

    //Handle input is method which is used to validate the inputs

    const handleInput = (e: any) => {



        if (e.target.id === 'FirstName') {

            // alert(IsFirstNameVerified.toString() + IsLastNameVerified.toString() + IsSSNVerified.toString() + IsDOBVerified.toString() + IsZipCodeVerified.toString() + IsEmailVerified.toString() +IsCityVerified.toString() + IsAddress1Verified.toString()+IsStateVerified.toString())

            const value = e.target.value.replace(/[^A-Za-z' .,-]/ig, '');

            setFirstName(value);
            if (value.length > 0 && e.target.value.trim() !== '') {
                if (IsPayfonePrefill) { setIsNameChanged(true); }
                setFirstVerify('verify-icon'); setIsFirstNameVerified(true);

                

            } else { setFirstVerify(''); setIsFirstNameVerified(false); }
        }
        if (e.target.id === 'LastName') {
            const value = e.target.value.replace(/[^A-Za-z' .,-]/ig, '');

            setLastName(value); 

            if (value.length > 0 && e.target.value.trim() !== '') {
                if (IsPayfonePrefill) { setIsNameChanged(true); }
                setLastNameVerify('verify-icon'); setIsLastNameVerified(true);
               

            } else { setLastNameVerify(''); setIsLastNameVerified(false); }

        }
        if (e.target.id === 'Address1') {
            
            if (e.target.value.length > 0 && e.target.value.trim() !== '') {
                if (IsPayfonePrefill) { setIsAddressChanged(true); }
                setAddress1Verify('verify-icon'); setIsAddress1Verified(true);



            } else { setAddress1Verify(''); setIsAddress1Verified(false); }
            var regex = /^[a-zA-Z0-9_,.# ]*$/; 
            const value = e.target.value.replace(/[^0-9a-zA-Z# .,-]/ig, '');
            setAddress1(value);
        }
        if (e.target.id === 'Address2') {
            if (e.target.value.length > 0) { if (IsPayfonePrefill) { setIsAddressChanged(true); } setAddress2Verify('verify-icon'); } else { if (IsPayfonePrefill) { setIsAddressChanged(false); } setAddress2Verify(''); }
            setAddress2(e.target.value)
        }
        if (e.target.id === 'AptNumber') {
            if (e.target.value.length > 0) { setAptNumberVerify('verify-icon'); } else { setAptNumberVerify(''); }
            setAptNumber(e.target.value);
        }
        if (e.target.id === 'ZIPCode') {
            const ZipCode = e.target.value.replace(/[^\d]/g, "");
            if (ZipCode.length === 5) {
                if (IsPayfonePrefill) { setIsAddressChanged(true); }
                setZipCodeVerify('verify-icon'); setIsZipCodeVerified(true);

              

            } else { setZipCodeVerify(''); setIsZipCodeVerified(false); }

            setZipCode(ZipCode);
        }
        if (e.target.id === 'City') {
            const value = e.target.value.replace(/[^A-Za-z ]/ig, '');
            if (value.length > 0 && e.target.value.trim() !== '') {
                if (IsPayfonePrefill) { setIsAddressChanged(true); }
                setCityVerify('verify-icon'); setIsCityVerified(true);
               
            } else { setCityVerify(''); setIsCityVerified(false); }

            setCity(value);
        }
        if (e.target.id === 'Email') {
            var EmailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (EmailRegex.test(e.target.value.trim())) {
                setEmail(e.target.value.trim());
                setIsEmailVerified(true);
                setEmailVerify("verify-icon");

               

            } else {
                setEmailVerify("");
                setEmail(e.target.value);
                setIsEmailVerified(false);

            }
        }

    }

    //SSNFormate will make last 4 as decrypt
    const SSNFormate = (event: any) => {

        const SSNreplace = event.target.value.replace(/[^\d]/g, "");
        if (SSNreplace.length >= 5) {
            var MaskedSSN = SSNreplace.replace(/.(?=.{4})/g, 'X');
            setMaskedFullSSN(MaskedSSN);


        }

    }
    //SSN will make as decrypt
    const SSNReformate = (event: any) => {
        setMaskedFullSSN(FullSSN)
    }
    function allEqual(input) {
        return input.split('').every(char => char === input[0]);
    }

    const setSSNvalue = (event: any) => {

        const SSNreplace = event.target.value.replace(/[^\d]/g, "")
        setMaskedFullSSN(SSNreplace);
        setFullSSN(SSNreplace);

        if (SSNreplace.length === 9 && !allEqual(SSNreplace)) {
            setSSNVerify('verify-icon');
            setIsSSNVerified(true);
           
        } else { setSSNVerify(''); setIsSSNVerified(false);}

    }

    const setEditButtonEnable = () => {

        var MaskedSSN = FullSSN.replace(/.(?=.{4})/g, 'X');
        setMaskedFullSSN(MaskedSSN);
        setFullSSN(FullSSN);
        setIsEditInfo(true);

    }


    //Datepickerset date

    const setDateOfBirthValue = (event: any) => {
        let Max_date = moment().subtract(18, 'years');
        let aug = new Date(Max_date.toString());

        if (event !== undefined && event !== null && event.length > 0) {
            if (IsPayfonePrefill) { setIsDobChanged(true); }
            setStartDateVerify('verify-icon');
            setIsDOBVerified(true);
            setStartDate(event);
            let dob = moment(event.toString()).format("YYYYMMDD");

            let Maskeddob = moment(dob).format("MM/DD/").toString() + "XXXX";
            setMaskedDateOfBirth(Maskeddob);

            setFormateDateOfBirth(dob);

        }
        else {
            setIsDOBVerified(false); 
        }
    }

    //setState

    const StateChange = (event: any) => {
        setState(event.target.value);
        if (event.target.value !== 'Select') {
            if (IsPayfonePrefill) { setIsAddressChanged(true); }
            setStateVerify('verify-icon');
            setIsStateVerified(true);
        }
        else {
            setStateVerify('');
            setIsStateVerified(false);
          
        }
    }

    const RedirectToApprovaPage = async () => {

        let sessionMobileNumber = sessionStorage.getItem('MobileNo') || '';
        let EncodedMobileNumber = new Buffer(sessionMobileNumber, 'base64');
        let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');
        setIsLookingUpOpen(true);
        let Userinfo = {
            'FirstName': FirstName,
            'LastName': LastName,
            'DateOfBirth': FormateDateOfBirth,
            'NationalIDNumber': FullSSN,
            'Address1': Address1,
            'Address2': Address2,
            'ApartmentNumber': AptNumber,
            'City': City,
            'StateProvince': State,
            'PostalCode': ZipCode,
            'EmailAddress': Email,
            'MobileNumber': DecodedMobileNumber,
            'PayfonePrefill': IsPayfonePrefill,
            'DobChanged': IsDobChanged,
            'AddressChanged': IsAddressChanged,
            'NameChanged': IsNameChanged

        };
        setIsLookingUpOpenup(true);
        const ApprovalResponse = await GetApproval(Userinfo, Authkey);
        console.log(ApprovalResponse);

       ////console.log("CMSResponse", ApprovalResponse);
        // alert("hey ");
        if (ApprovalResponse.StatusCode !== undefined && ApprovalResponse.StatusCode === "200") {
            if (ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse === "1") {
                setIsLookingUpOpen(false);
                if(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount === undefined ||ApprovalResponse.ApprovalDecisionResponse.WeeklyApprovalAmount === undefined){
                    window.location.href = './IdentityVerify?Status=Exception';
                }
                const EncodeMobileNumber = new Buffer(MobileNumber).toString("base64");
                const EncodeApprovalAmount = new Buffer(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount.toString()).toString("base64");
                const WeeklyApprovalAmount = new Buffer(ApprovalResponse.ApprovalDecisionResponse.WeeklyApprovalAmount.toString()).toString("base64");
                const Approval = new Buffer(ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse.toString()).toString("base64");
                const CustomerId = new Buffer(ApprovalResponse.ApprovalDecisionResponse.CustomerID.toString()).toString("base64");
                const EncodedeState = new Buffer(State).toString("base64");
                const EncodedCity = new Buffer(City).toString("base64");
                const EncodedAddress = new Buffer(Address1).toString("base64");
                const EncodedPostalCode = new Buffer(ZipCode.toString()).toString("base64");

                const UserInformation = {
                    "MobileNumber": EncodeMobileNumber,
                    "TAM": EncodeApprovalAmount,
                    "WAM": WeeklyApprovalAmount,
                    "APP": Approval,
                    "CID": CustomerId,
                    "State": EncodedeState,
                    "Zip": EncodedPostalCode,
                    "Address": EncodedAddress,
                    "City": EncodedCity
                }

                sessionStorage.setItem("UserInformation", JSON.stringify(UserInformation));
                //alert(EncodeApprovalAmount);
                //alert(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount);
                //alert(WeeklyApprovalAmount);
                window.location.href = './ApprovalPage?AuthKey=' + Authkey
            }
            else if (ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse === "2") {
                let updateAccessLink = await UpdateAccesslink(Authkey, false);
                window.location.href = './IdentityVerify?Status=Rejected';
            }
            else if (ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse === "4" || ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse === "5" || ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse === "6") {
                //  window.location.href = './IdentityVerify?Rejected';

                if(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount === undefined ||ApprovalResponse.ApprovalDecisionResponse.WeeklyApprovalAmount === undefined){
                    setIsLookingUpOpen(false);
                    window.location.href = './IdentityVerify?Status=Exception';
                }
                const EncodeMobileNumber = new Buffer(MobileNumber).toString("base64");
                const EncodeApprovalAmount = ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount !== undefined ? new Buffer(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount.toString()).toString("base64"): '';
                const WeeklyApprovalAmount = ApprovalResponse.ApprovalDecisionResponse.WeeklyApprovalAmount !== undefined ? new Buffer(ApprovalResponse.ApprovalDecisionResponse.WeeklyApprovalAmount.toString()).toString("base64"):'';
                const Approval = new Buffer(ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse.toString()).toString("base64");
                const CustomerId = new Buffer(ApprovalResponse.ApprovalDecisionResponse.CustomerID.toString()).toString("base64");
                const EncodedeState = new Buffer(State).toString("base64");
                const EncodedCity = new Buffer(City).toString("base64");
                const EncodedAddress = new Buffer(Address1).toString("base64");
                const EncodedPostalCode = new Buffer(ZipCode.toString()).toString("base64");
                //alert(EncodeApprovalAmount);
                //alert(ApprovalResponse.ApprovalDecisionResponse.ApprovalAmount);
                //alert(WeeklyApprovalAmount);
                const UserInformation = {
                    "MobileNumber": EncodeMobileNumber,
                    "TAM": EncodeApprovalAmount,
                    "WAM": WeeklyApprovalAmount,
                    "APP": Approval,
                    "CID": CustomerId,
                    "State": EncodedeState,
                    "Zip": EncodedPostalCode,
                    "Address": EncodedAddress,
                    "City": EncodedCity
                }

                sessionStorage.setItem("UserInformation", JSON.stringify(UserInformation));
                //alert(EncodeApprovalAmount);
                window.location.href = './ApprovalPage?AuthKey=' + Authkey;
                setIsLookingUpOpen(false);

            }
        } else {
            //alert(ApprovalResponse.ApprovalDecisionResponse.ApprovalResponse);
            setIsLookingUpOpen(false);
            // window.location.href = './IdentityVerify?Status=Exception';
        }
    }

    const RedirectToPayfonePrefill = () => {
        setIsEditInfo(false);
    }


    return (
        <>
            <DOFHeader />

            {IsLookingUpOpenup === false ?
                <div>
                    {IsEditInfo === false ?
                        <div>
                            <div className="row justify-content-center me-0 mb-5">
                                <div className="col-md-7 col-mdl-6 mt-5 pe-0">
                                    { IsTextMessgeEnble === true?
                                    <h5 className="sub-heading-txt mb-4 blue-title-txt px-2">We found your profile information!</h5> :''}
                                    <h5 className="sub-heading-txt mb-4 px-2">Please review your information and confirm it is accurate :</h5>
                                    <div className="info-card mx-2">
                                        <p className="sub-heading-txt color-black">{FirstName + ' ' + LastName}</p>
                                        <div className="w-100 floats-start">
                                            <p className="card-heading-txt mb-1">Current Address</p>
                                            <p className="card-txt-style">{AptNumber !== undefined && AptNumber !== null && AptNumber !== '' ? AptNumber + "," : ''}
                                             { Address1 !== undefined && Address1 !== null && Address1 !== '' ? Address1 + "," :'' } { Address2 !== undefined && Address2 !== null && Address2!== '' ? Address2 + "," : ''}</p>
                                            <p className="card-txt-style">{City + ", " + State + ", " + ZipCode}</p>
                                        </div>
                                        {MaskedDateOfBirth !== undefined && MaskedDateOfBirth !== null && MaskedDateOfBirth !== '' ?
                                            <div className="w-100 floats-start">
                                                <p className="card-heading-txt mb-1">Date of Birth</p>
                                                <p className="card-txt-style">{MaskedDateOfBirth}</p>
                                            </div> : null}
                                        <div className="w-100 floats-start">
                                            <p className="card-heading-txt mb-1">Email Address</p>
                                            <p className="card-txt-style">{Email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-2 text-center d-md">
                                <a className="prev-button px-4 mx-2 " href={'./BasicInfo?AuthKey=' + Authkey}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</a>
                                <button type="button" onClick={setEditButtonEnable} className="btn outline-btn mx-2  edit-icon my-2">EDIT INFO</button>
                                <button className="btn btn-primary-custom mx-2 my-2" onClick={RedirectToApprovaPage} >CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                            </div>
                            <div className="mx-2 text-center d-sm">
                                <button className="btn btn-primary-custom mx-2 my-2" onClick={RedirectToApprovaPage} >CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                                <button type="button" onClick={setEditButtonEnable} className="btn outline-btn mx-2  edit-icon my-2">EDIT INFO</button>
                                <a className="prev-button px-4 mx-2 " href={'./BasicInfo?AuthKey=' + Authkey}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</a>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="row justify-content-center me-0 mb-4">
                                <div className="col-md-7 col-mdl-6  mt-5 pe-0">
                                    <h5 className="sub-heading-txt mb-4 px-2">Let’s start creating your profile</h5>
                                    <div className="w-100 float-start mb-3 px-3">
                                        <label className="form-label mandatory" htmlFor="SSN">SSN</label>
                                        <input maxLength={9} type="text" onFocus={SSNReformate} onChange={setSSNvalue} onLoad={setSSNvalue} value={MaskedFullSSN} onBlur={SSNFormate} className={"form-control " + SSNVerify} id="SSN" disabled={IsSSNDisabled} />
                                    </div>
                                    <div className="w-100 float-start ">
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="FirstName">First Name</label>
                                            <input type="text" onChange={handleInput} value={FirstName} className={"form-control " + FirstNameVerify} id="FirstName" maxLength={30} disabled={IsFirstNameDisabled} />
                                        </div>
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="LastName">Last Name</label>
                                            <input type="text" onChange={handleInput} value={LastName} className={"form-control " + LastNameVerify} id="LastName" maxLength={30} disabled={IsLastNameDisabled} />
                                        </div>
                                    </div>

                                    <div className="w-100 float-start mb-3 px-3">
                                        <label className="form-label mandatory" htmlFor="SSN">Email</label>
                                        <input type="text" onChange={handleInput} value={Email} id="Email" className={"form-control " + EmailVerify} maxLength={50} />

                                    </div>

                                    <div className="w-100 float-start">
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="DateofBirth">Date of Birth</label>
                                            {IsDOBDisabled === false ?
                                                <Flatpickr className='form-control calendar daypicker no-repeat' placeholder='MM/DD/YYYY' options={{ maxDate: DOBMaxDate, minDate: DOBMinDate, dateFormat: 'm/d/Y', static: true }} onChange={setDateOfBirthValue} value={startDate}></Flatpickr> : 
                                                    <input type="text" value={MaskedDateOfBirth} className="form-control calendar daypicker no-repeat" id="dob" disabled/>
                                               
                                            }

                                            {/* <DatePicker  className="form-control calendar no-repeat" id="DateofBirth"  selected={startDate} onChange={setDateOfBirthValue} /> */}

                                            {/* <input type="date"  className="form-control" id="DateofBirth" /> */}
                                        </div>
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="Address1">Address Line 1</label>
                                            <input type="text" onChange={handleInput} value={Address1} className={"form-control " + Address1Verify} id="Address1" maxLength={30} />
                                        </div>
                                    </div>
                                    <div className="w-100 float-start">
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label " htmlFor="AddressLine2">Address Line 2</label>
                                            <input type="text" onChange={handleInput} value={Address2} className={"form-control " + Address2Verify} id="Address2" maxLength={30} />
                                        </div>
                                        <div className="col-md-6 float-start px-3 mb-3 ">
                                            <label className="form-label " htmlFor="Apt#">Apt # / Unit #</label>
                                            <input type="text" value={AptNumber} onChange={handleInput} className={"form-control " + AptNumberVerify} id="AptNumber" maxLength={6} />
                                        </div>
                                    </div>
                                    <div className="w-100 float-start mb-5">
                                        <div className="col-md-4 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="ZIPCode">ZIP Code</label>
                                            <input type="text" value={ZipCode} onChange={handleInput} maxLength={5} className={"form-control " + ZipCodeVerify} id="ZIPCode" />
                                        </div>
                                        <div className="col-md-4 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="City">City</label>
                                            <input type="text" value={City} onChange={handleInput} className={"form-control " + CityVerify} id="City" maxLength={20} />
                                        </div>


                                        <div className="col-md-4 float-start px-3 mb-3 ">
                                            <label className="form-label mandatory" htmlFor="State">State</label>
                                            <select className={"form-select " + StateVerify} value={State} onChange={StateChange} id="State">
                                                {/* <option selected>Select</option> */}

                                                {states.map((value, index) => (
                                                    <option key={index} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                                {/* <option value={1}>One</option>
                 <option value={2}>Two</option>
                 <option value={3}>Three</option> */}
                                            </select>
                                        </div>

                                    </div>
                                    <div className="mx-2 mt-5 text-center d-md">
                                        {IsPayfonePrefill === true ?
                                            <button className="prev-button px-4 bg-rem" onClick={RedirectToPayfonePrefill} ><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button> : null}
                                        <button className="btn btn-primary-custom my-3" onClick={RedirectToApprovaPage} disabled={IsFirstNameVerified && IsLastNameVerified && IsDOBVerified && IsSSNVerified && IsZipCodeVerified && IsCityVerified &&IsEmailVerified &&IsAddress1Verified && IsStateVerified ? false : true} >CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                                    </div>
                                    <div className="mx-2 mt-5 text-center d-sm">
                                        <button className="btn bg-rem btn-primary-custom my-3 " onClick={RedirectToApprovaPage} disabled={IsFirstNameVerified && IsLastNameVerified && IsDOBVerified && IsSSNVerified && IsZipCodeVerified && IsCityVerified && IsEmailVerified&& IsAddress1Verified && IsStateVerified ? false : true} >CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                                        {IsPayfonePrefill === true ? <button className="prev-button px-4 " onClick={RedirectToPayfonePrefill} ><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                : null}
            <Modal isOpen={IsLookingUpOpen} className='test'>
                <div className="modal fade show" id="loader" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-body py-4">
                                <p className="popup-txt-style mb-0"><img className="me-2" src={Loader} alt="loader icon" />Loading please wait</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>




        </>
    );
}

export default PayfonePrefillForm;
