/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import '../css/commontheme.css';
import '../css/stylesheet.css';
import Modal from 'react-modal';
import QRCodeReact from 'qrcode.react'
import Success from '../images/success-icon.svg'

function SendDigitalForm() {
    const [DigitalFormPopup, SendDigitalFormPopup] = useState<boolean>(false);
    const [QRCodePopup, SendQRCodePopup] = useState<boolean>(false);
    const [PhoneNumber, SendPhoneNumber] = useState<string>("");
    const [UnformatePhoneNumber, setUnformatePhoneNumber] = useState<string>("");
    const [Email, SendEmail] = useState<string>("");
    const [QRCodeURL, SendQRCodeURL] = useState<string>("");
    const [IsEmailVerified,setIsEmailVerified ] = useState<boolean>(false);
    const [IsSuccessPopUpOpen,setIsSuccessPopUpOpen ] = useState<boolean>(false);
    
    function ShowDigitalPopup() {
        SendDigitalFormPopup(true);
    }

    const setQRUrl=()=>
    {
        SendQRCodeURL("https://www.rentacenter.com?Email=" + Email + "&PhoneNumber=" + UnformatePhoneNumber);

    }
    const CreateQRCode=()=> {
        SendDigitalFormPopup(false);
        SendQRCodePopup(true);
    }
    const HideDigitalPopup =()=> {
        SendQRCodePopup(false);
    }
    const HideSuccessPopUp=()=>{
        SendQRCodePopup(false);
        SendDigitalFormPopup(false);

    }

    const formatPhoneNumber =(value: any)=> {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) {
          return value;
        }
    
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");
    
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
    
        if (phoneNumberLength < 4) return phoneNumber;
    
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
    
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }

      const EmailValueVerify = (event: any) => {
  
        var EmailRegex = new RegExp('^([0-9a-zA-Z]([-\\.\\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})$');
        if (EmailRegex.test(event.target.value)) {
            
            SendEmail(event.target.value);
           
            setIsEmailVerified(true);            
         
        } else {
            SendEmail(event.target.value);
            setIsEmailVerified(false); 
          
         
        }
    
      }
    
      const handleInput = (e:any)=>
      {
          const PhoneNumber = formatPhoneNumber(e.target.value);
          SendPhoneNumber(PhoneNumber);
          setUnformatePhoneNumber(PhoneNumber.replace(/[-() ]/g,''));
          setQRUrl();

      }
      const OpenSucessPopUp=()=>
      {
        SendQRCodePopup(false);
        setIsSuccessPopUpOpen(true);


      }


    return (
        <div>
   <div className="row justify-content-center me-0 ">
            <div className="col-md-7 col-mdl-6  mt-5 pe-0">
                        <div className="modal-content">
                        <div className="modal-body pt-0">
                                <div className="w-100 float-start">
                                    <div className="col-md-12 float-start mb-3 ">
                                        <label htmlFor="jrsr" className="form-label w-100">Is customer physically in store?</label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked />
                                            <label className="form-check-label checkbox-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                                            <label className="form-check-label checkbox-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label" htmlFor="PhoneNumber">Phone Number</label>
                                            <input type="text" onChange={handleInput} value={PhoneNumber} className="form-control" id="PhoneNumber" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label" htmlFor="Email">Email</label>
                                            <input type="email" onChange={EmailValueVerify} value={Email} className="form-control" id="Email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 pb-4 px-3 text-end">
                                <button type="button" onClick={HideDigitalPopup} className="btn btn-secondary mx-1" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary mx-1" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#QRCode" onClick={CreateQRCode}>Send</button>
                            </div>
                        
                        </div>
                    </div>
                </div>
          
            {/*Customer-Signature ends*/}
            {/*Send Agreement starts*/}
            <Modal isOpen={QRCodePopup} className='Test'>
                <div className="modal fade show" style={{ display: 'block' }} id="QRCode" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                    <div className="modal-dialog modal-custom">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <label className="modal-title title popup-text text-center">Customer can now scan the QR code</label>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={HideDigitalPopup} data-bs-toggle="modal" data-bs-target="#initialpayment" />
                            </div>
                            <div className="modal-body pt-0 text-center">
                                <div className="w-100 float-start my-3">
                                    {/* <img src="images/QR.png" id="QRCode" alt="QR" /> */}
                                    <QRCodeReact value={QRCodeURL} />,
                                </div>
                            </div>
                            <div className="col-md-12 pb-4 px-3 text-center">
                                <a type="button" href={QRCodeURL} target="_blank"className="btn btn-secondary mx-1" data-bs-dismiss="modal" rel="noreferrer">Access Link</a>
                                <button type="button" className="btn btn-primary mx-1" onClick={OpenSucessPopUp} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#success">Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*Send Agreement ends*/}
            {/*Success Modal Starts here */}
            <Modal isOpen={IsSuccessPopUpOpen} className='Test'>
            <div className="modal fade show" id="success" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" onClick={HideSuccessPopUp} data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body text-center ">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <img src={Success} alt="transaction" />
                                </div>
                                <p className="popup-text mt-3">
                                    Digital Order Form sent successfully.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 pb-3 text-center">
                            <button type="button" className="btn btn-primary mx-1" onClick={HideSuccessPopUp} data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Success ends here */}

            </Modal>
        </div>
    );
}

export default SendDigitalForm;