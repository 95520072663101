import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import PayfonePrefillForm from './Component/PayfonePrefillForm';
import App from './App';
//import UserVerifyInformation from './Component/UserVerifyInformation';
// import BasicInfo from './Component/BasicInfo';
// import PayfonePrefillForm from './Component/PayfonePrefillForm';
// import DOFStartingPage from './Component/DOFStartingPage';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route
// } from "react-router-dom";


// import DOFStartingPage from './Component/DOFStartingPage';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <PayfonePrefillForm/> */}
    {/* <UserVerifyInformation /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
