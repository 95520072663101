// import React from "react";
// import {SMSTextMsg} from '../Config/Config';
import axios from 'axios'
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
// import {
//     Dev_SendOTPURL,
//     Dev_ValidateTokenURL,
//     SMSMessege_Template,
//     Dev_AMS005,DEV_AMS_MessageDTSTZOffset,
//     DEV_AMS_Revision,
//     DEV_AMS_RegionID,
//     DEV_AMS_ClientID,
//     DEV_AMS_ClientLocationNumber,
//     DEV_AMS_EncryptionMethod,
//     DEV_AMS_UserId,
//     DEV_AMS_ClientSource,
//     DEV_AMS_ClientOriginator,
//     DEV_AMS_LocationNumber,
//     Dev_PayfoneIdentityURL,
//     Dev_CMS205_URL,
//     Dev_CMS205_Messege_Type,   
//     Dev_CMS205_ClientSource,   
//     Dev_CMS205_RegionId,
//     Dev_AMS003_URL,Dev_UpdateAccesslink_URL} from '../Config/Config';

import { appConfig } from '../Config/app-config';
import moment from 'moment';






export const GetApproval = async (UserInfo: any, AuthKey: string) => {
    var url = appConfig.APIConfig.Dev_CMS205_URL;
    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey
        }
    }

    let sessionStoreCode = sessionStorage.getItem('StoreCode') || '';
    let EncodedStore = new Buffer(sessionStoreCode, 'base64');
    let DecodedStore = EncodedStore.toString('ascii');
    if (!DecodedStore) {
        const ResponseValidateAuth: any = await ValidateAuthKey(AuthKey);
        DecodedStore = ResponseValidateAuth?.storeNumber
    }
    const body = {
        "MessageType": appConfig.APIConfig.Dev_CMS205_Messege_Type,
        "RegionID": appConfig.APIConfig.Dev_CMS205_RegionId,
        "ClientLocationNumber": DecodedStore,
        "ClientSource": appConfig.APIConfig.Dev_CMS205_ClientSource,
        "LocationNumber": DecodedStore,
        "ApprovalDecisionRequest": {
            "FirstName": UserInfo.FirstName,
            "LastName": UserInfo.LastName,
            "DateOfBirth": UserInfo.DateOfBirth,
            "NationalIDNumber": UserInfo.NationalIDNumber,
            "Address1": UserInfo.ApartmentNumber + ' ' + UserInfo.Address1 + ' ' + UserInfo.Address2,
            "City": UserInfo.City,
            "StateProvince": UserInfo.StateProvince,
            "PostalCode": UserInfo.PostalCode,
            "Email": UserInfo.EmailAddress,
            "PrefillFlag": UserInfo.PayfonePrefill === true ? 1 : 0,
            "DobChanged": UserInfo.IsDobChanged === true ? 1 : 0,
            "AddressChanged": UserInfo.IsAddressChanged === true ? 1 : 0,
            "NameChanged": UserInfo.IsNameChanged === true ? 1 : 0,
            "PrimaryPhone": UserInfo.MobileNumber !== '' ? UserInfo.MobileNumber : '',
            "PrimaryPhoneTpe": UserInfo.MobileNumber !== '' ? 1 : '',
            'EmailConsent': 1,
            'AuthFlag': 1
        }
    }
    console.log("Inside service", body);
    const CMSResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        //alert("Success");
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        //alert("Failure");
        return error.response.data
    });

    return CMSResponse


}

//This method will validate the Auth key which is used to send in link
export const ValidateAuthKey = async (AuthKey: any) => {
    debugger;

    const config: any = {
        method: 'post',
        url: appConfig.APIConfig.Dev_ValidateTokenURL,
        headers: {
            'Authorization': 'Bearer ' + AuthKey,
        }
    };

    //console.log((config);

    let ValidateResponse = await axios(config).then(function (response) {

        return response
    })
        .catch(function (error) {
            return error.response.data
        });

    //console.log((ValidateResponse);
    if (ValidateResponse.Messege !== undefined && ValidateResponse.Messege !== null) {
        ValidateResponse = null;

    }
    else { ValidateResponse = ValidateResponse.data }

    return ValidateResponse;




}



export const SendOTP = async (MobileNumber, AuthKey) => {
    var SMSResponse;
    if (MobileNumber != null) {

        var url = appConfig.APIConfig.Dev_SendOTPURL;
        const headers = {
            'headers':
            {
                'Authorization': 'Bearer ' + AuthKey
            }
        };

        const body = {
            'phoneNumber': MobileNumber,
            'message': appConfig.APIConfig.SMSMessege_Template
        };

        SMSResponse = await axios.post(url, body, headers).then(response => {
            //console.log(("Response", response.data);
            return response.data
        }).catch((error) => {
            //console.log(("Error : ", error.response.data);
            return error.response.data
        });



    }
    return SMSResponse;



}
export const ValidateOTP = async (payload, AuthKey) => {
    var ValidateOtpRes;


    var url = appConfig.APIConfig.Dev_ValidateOTPURL;
    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey
        }
    };


    ValidateOtpRes = await axios.post(url, payload, headers).then(response => {
        //console.log(("Response", response.data);
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        return error.response.data
    });

    return ValidateOtpRes;


}



export const ReSendOTP = (MobileNumber) => {
    return '22222';

}


export const GetPayfoneDetails = async (MobileNumber, LastFourSSN, AuthKey) => {
    var url = appConfig.APIConfig.Dev_PayfoneIdentityURL;

    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey
        }
    }

    const body = {
        "phoneNumber": MobileNumber,
        "last4": LastFourSSN
    }

    const PayfoneResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        return error.response.data
    });

    return PayfoneResponse;


}


export const getExsitingCustomerDetails = async (MobileNumber, AuthKey) => {
    let FirstNamesession = sessionStorage.getItem('FirstName') || '';
    let EncodedFirstName = new Buffer(FirstNamesession, 'base64');
    let DecodedFirstName = EncodedFirstName.toString('ascii');
    let LastNamesession = sessionStorage.getItem('LastName') || '';
    let EncodedLastName = new Buffer(LastNamesession, 'base64');
    let DecodedLastName = EncodedLastName.toString('ascii');
    var url = appConfig.APIConfig.Dev_AMS005;
    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey
        }
    }

    const body = {
        "AMSRequest": {
            "MessageID": "AMS005-" + uuidv4().toString(),
            "MessageType": "AMS005",
            "MessageDTS": moment().utc().format('YYYY-MM-DD-HH:mm:ss:000813'),
            "MessageDTSTZOffset": appConfig.APIConfig.DEV_AMS_MessageDTSTZOffset,
            "Revision": appConfig.APIConfig.DEV_AMS_Revision,
            "RegionID": appConfig.APIConfig.DEV_AMS_RegionID,
            "ClientID": appConfig.APIConfig.DEV_AMS_ClientID,
            "ClientLocationNumber": appConfig.APIConfig.DEV_AMS_ClientLocationNumber,
            "ClientSource": appConfig.APIConfig.DEV_AMS_ClientSource,
            "ClientOriginator": appConfig.APIConfig.DEV_AMS_ClientOriginator,
            "LocationNumber": appConfig.APIConfig.DEV_AMS_LocationNumber,
            "EncryptionMethod": appConfig.APIConfig.DEV_AMS_EncryptionMethod,
            "Body": {
                "FindCustomerRequest": {
                    "LastName": DecodedLastName,
                    "FirstNameWildCardSearch": "false",
                    "FirstName": DecodedFirstName,
                    "LastNameWildCardSearch": "false",
                    "PrimaryPhone": MobileNumber,
                    "ClientProfile": {
                        "UserID": appConfig.APIConfig.DEV_AMS_UserId
                    },
                    "PageInfo": {
                        "PageSize": "10",
                        "SortCol": null,
                        "SortDirection": null,
                        "StartingRow": null,
                        "TotalCount": null
                    }
                }
            }
        }

    };

    const AMSResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        return error.response.data
    });

    return AMSResponse;


}

export const decrypt = (ciphertext) => {
    var decryptText = CryptoJS.AES.decrypt(ciphertext, 'Test');
    var originalText = decryptText.toString(CryptoJS.enc.Utf8);
    //console.log((originalText);
    return originalText;
};
export const encrypt = (plaintext) => {
    var encryptText = CryptoJS.AES.encrypt(plaintext, 'Test');
    //console.log((encryptText);
    return encryptText;
};

export const UpdateCustomerService = async (UserInfo: any, Authkey: string) => {
    let sessionStoreCode = sessionStorage.getItem('StoreCode') || '';
    let EncodedStore = new Buffer(sessionStoreCode, 'base64');
    let DecodedStore = EncodedStore.toString('ascii');


    let Lengthofresidence = '1';

    if (UserInfo.Renthouse === true) {
        Lengthofresidence = UserInfo.startDate === '0 to 1 year' ? '1' : UserInfo.startDate === '1 to 5 years' ? '2' : UserInfo.startDate === '5+ years' ? '3' : '1';
    } else { Lengthofresidence = UserInfo.StartMortgageDate === '0 to 1 year' ? '1' : UserInfo.StartMortgageDate === '1 to 5 years' ? '2' : UserInfo.StartMortgageDate === '5+ years' ? '3' : '1' }

    var url = appConfig.APIConfig.Dev_AMS003_URL;
    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + Authkey
        }
    }

    let body;

    if (UserInfo.Approval === false) {

        body = {

            "AMSRequest": {

                "MessageID": "AMS003-" + uuidv4(),

                "MessageType": "AMS003",

                "MessageDTS": moment().utc().format('YYYY-MM-DD-HH:mm:ss:000813'),

                "MessageDTSTZOffset": "-05:00",

                "Revision": "2.1",

                "RegionID": "1",

                "ClientID": "194",

                "ClientLocationNumber": DecodedStore,

                "ClientSource": "3",

                "ClientOriginator": "194",

                "LocationNumber": DecodedStore,

                "EncryptionMethod": "0",

                "Body": {

                    "UpdateCustomerRequest": {

                        "CustomerInfoFlag": '1',
                        "CustomerRequest": {

                            "CustomerID": UserInfo.CustomerId,
                            "AcceptTextSolicitation": UserInfo.SolicitationFlag,
                            "AcceptPhoneSolicitation": UserInfo.SolicitationFlag,
                            "IDType": UserInfo.GovtIdType === 'StateID' ? '2' : UserInfo.GovtIdType === 'DriverLicense' ? '1' : UserInfo.GovtIdType === 'Passport' ? '3' : '9',

                            "IDNumber": UserInfo.GovtId !== '' ? UserInfo.GovtId : '',

                            "StateProvince": UserInfo.GovtIdState !== '' && UserInfo.GovtIdType !== 'Passport' ? UserInfo.GovtIdState : UserInfo.GovtIdType !== 'Passport' ? UserInfo.EncodedState : '',

                            "PhoneNumbers": [

                                {

                                    "PhoneNumber": UserInfo.MobileNumber,

                                    "PrimaryPhone": '1',

                                    "PhoneType": '2',

                                    "BestTimeToCall": UserInfo.Besttime === 'Morning' ? '1' : UserInfo.Besttime === 'Afternoon' ? '2' : '3'

                                }

                            ]

                        },

                        "CustomerEmployerRequest": {

                            "EmployerInfo": [

                                {
                                    "Active": 1,
                                    "PrimarySourceOfIncome": '1',

                                    "LengthOfEmployment": UserInfo.HowLongValue === '0 to 1 year' ? '1' : UserInfo.HowLongValue === '1 to 5 years' ? '2' : UserInfo.HowLongValue === '5+ years' ? '3' : '1',

                                    "Employer": UserInfo.CompanyName,

                                    "EmployerPhone": UserInfo.EmployerPhoneNumber,

                                    "Schedule": UserInfo.PayDayFrequencyValue === 'Weekly' ? '1' : UserInfo.PayDayFrequencyValue === 'Bi-Weekly' ? '2' : UserInfo.PayDayFrequencyValue === 'Semi-Monthly' ? '3' : UserInfo.PayDayFrequencyValue === 'Monthly' ? '4' : '5',

                                    "DaysPaid": UserInfo.PayDayFrequencyValue === 'Daily' ? '' : UserInfo.WeeklyDays,

                                    "MonthlyIncome": UserInfo.TakeHomePay,

                                    "EmployerAddress1": UserInfo.EmployerAddress,

                                    "EmployerAddress2": UserInfo.EmployerAddress2,

                                    "EmployerCity": UserInfo.EmployerCity,

                                    "EmployerStateProvince": UserInfo.State,

                                    "EmployerPostalCode": UserInfo.EmployerZipCode,


                                }

                            ]

                        },

                        "CustomerResidenceRequest": {

                            "ResidenceInfo": [

                                {
                                    "Active": 1,
                                    "ResidenceType": UserInfo.Renthouse === true ? '2' : '1',

                                    "Address1": UserInfo.EncodedAddress,

                                    "City": UserInfo.EncodedCity,

                                    "StateProvince": UserInfo.EncodedState,

                                    "PostalCode": UserInfo.EncodedZip,

                                    "LandlordFirstName": UserInfo.Renthouse === true && UserInfo.LandlordName !== '' && UserInfo.LandlordName !== undefined ? UserInfo.LandlordName : UserInfo.MortgageCompanyName !== undefined &&
                                        UserInfo.MortgageCompanyName !== '' ? UserInfo.MortgageCompanyName : '',

                                    "LandlordLastName": UserInfo.Renthouse === true && UserInfo.LandlordLastName !== '' && UserInfo.LandlordLastName !== undefined ? UserInfo.LandlordLastName : '',

                                    "PhoneNumber": UserInfo.LandlordMobileNumber,

                                    "LengthOfResidence": Lengthofresidence,

                                    "MonthlyLeaseMortgagePayment": UserInfo.PaymentAmount !== '' && UserInfo.PaymentAmount !== null ? UserInfo.PaymentAmount : UserInfo.MortgageAmount !== '' && UserInfo.MortgageAmount !== null ? UserInfo.MortgageAmount : ''

                                }

                            ]

                        },

                        "CustomerReferencesRequest": {

                            "References": [

                                {

                                    "FirstName": UserInfo.parentName,

                                    "LastName": UserInfo.parentLastName,

                                    "Address1": UserInfo.RelationAddress1,

                                    "Address2": UserInfo.RelationAddress2,

                                    "PhoneNumber": UserInfo.ParentPhoneNumber,

                                    "PhoneType": UserInfo.ParentPhoneNumber !== '' && UserInfo.ParentPhoneNumber !== undefined && UserInfo.ParentPhoneNumber !== null ? '2' : '0',

                                    "PrimaryPhone": UserInfo.ParentPhoneNumber !== '' && UserInfo.ParentPhoneNumber !== undefined && UserInfo.ParentPhoneNumber !== null ? '1' : '0',

                                    "BestTimeToCall": UserInfo.ParentBestTime === 'Morning' ? '1' : UserInfo.ParentBestTime === 'Afternoon' ? '2' : '3',

                                    "Relationship": UserInfo.Relation === 'Parent / In-law' ? '1' : UserInfo.Relation === 'Grandparent' ? '2' : UserInfo.Relation === 'Sibling' ? '3' : UserInfo.Relation === 'Aunt' ? '4' : UserInfo.Relation === 'Cousin' ? '5' : UserInfo.Relation === 'Niece / Nephew' ? '6' : UserInfo.Relation === 'Friend' ? '7' : UserInfo.Relation === 'Co-Worker' ? '8' : '9',

                                    "ApartmentNumber": UserInfo.RelationApt !== '' && UserInfo.RelationApt !== undefined ? UserInfo.RelationApt : '',

                                    "City": UserInfo.RelationCity,

                                    "StateProvince": UserInfo.ParentState,

                                    "PostalCode": UserInfo.ParentZipCode

                                }

                            ]

                        }

                    }

                }

            }

        }
    } else {
        body = {

            "AMSRequest": {

                "MessageID": "AMS003-" + uuidv4(),

                "MessageType": "AMS003",

                "MessageDTS": moment().utc().format('YYYY-MM-DD-HH:mm:ss:000813'),

                "MessageDTSTZOffset": "-05:00",

                "Revision": "2.1",

                "RegionID": "1",

                "ClientID": "194",

                "ClientLocationNumber": DecodedStore,

                "ClientSource": "3",

                "ClientOriginator": "194",

                "LocationNumber": DecodedStore,

                "EncryptionMethod": "0",

                "Body": {

                    "UpdateCustomerRequest": {

                        "CustomerInfoFlag": '1',
                        "CustomerRequest": {

                            "CustomerID": UserInfo.CustomerId,
                            "AcceptTextSolicitation": UserInfo.SolicitationFlag,

                            "AcceptPhoneSolicitation": UserInfo.SolicitationFlag,

                            "IDType": UserInfo.GovtIdType === 'StateID' ? '2' : UserInfo.GovtIdType === 'DriverLicense' ? '1' : UserInfo.GovtIdType === 'Passport' ? '3' : '9',

                            "IDNumber": UserInfo.GovtId !== '' ? UserInfo.GovtId : '',

                            "StateProvince": UserInfo.GovtIdState !== '' && UserInfo.GovtIdType !== 'Passport' ? UserInfo.GovtIdState : UserInfo.GovtIdType !== 'Passport' ? UserInfo.EncodedState : '',

                            "PhoneNumbers": [

                                {

                                    "PhoneNumber": UserInfo.MobileNumber,

                                    "PrimaryPhone": '1',

                                    "PhoneType": '2',

                                    "BestTimeToCall": UserInfo.Besttime === 'Morning' ? '1' : UserInfo.Besttime === 'Afternoon' ? '2' : '3'

                                }

                            ]

                        },

                        "CustomerEmployerRequest": {

                            "EmployerInfo": [

                                {
                                    "Active": 1,
                                    "PrimarySourceOfIncome": "1",

                                    "LengthOfEmployment": UserInfo.HowLongValue === '0 to 1 year' ? '1' : UserInfo.HowLongValue === '1 to 5 years' ? '2' : UserInfo.HowLongValue === '5+ years' ? '3' : '1',

                                    "Employer": UserInfo.CompanyName,

                                    "EmployerPhone": UserInfo.EmployerPhoneNumber,

                                    "Schedule": UserInfo.PayDayFrequencyValue === 'Weekly' ? '1' : UserInfo.PayDayFrequencyValue === 'Bi-Weekly' ? '2' : UserInfo.PayDayFrequencyValue === 'Semi-Monthly' ? '3' : UserInfo.PayDayFrequencyValue === 'Monthly' ? '4' : '5',

                                    "DaysPaid": UserInfo.PayDayFrequencyValue === 'Daily' ? '' : UserInfo.WeeklyDays,

                                    "MonthlyIncome": UserInfo.TakeHomePay,

                                    "EmployerAddress1": UserInfo.EmployerAddress,

                                    "EmployerAddress2": UserInfo.EmployerAddress2,

                                    "EmployerCity": UserInfo.EmployerCity,

                                    "EmployerStateProvince": UserInfo.State,

                                    "EmployerPostalCode": UserInfo.EmployerZipCode,


                                }

                            ]

                        }
                    }

                }

            }

        }
    }

    const AMSResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        return error.response.data
    });

    return AMSResponse;



}



//This method will validate the Auth key which is used to send in link
export const UpdateAccesslink = async (AuthKey: any, store: boolean) => {
    debugger;
    let sessionMobileNumber = sessionStorage.getItem('MobileNoLink') || '';
    let EncodedMobileNumber = new Buffer(sessionMobileNumber, 'base64');
    let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');

    let sessionEmail = sessionStorage.getItem('EmailIdLink') || '';
    let EncodedEmail = new Buffer(sessionEmail, 'base64');
    let DecodedEmail = EncodedEmail.toString('ascii');

    //alert(DecodedEmail+DecodedMobileNumber);

    // var storeid = sessionStorage.getItem('StoreId') 
    // storeid = storeid + "-Store"
    const url = appConfig.APIConfig.Dev_UpdateAccesslink_URL;

    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey
        }
    }


    var body: any;
    if (!store) {


        body = {
            "phoneNumber": sessionMobileNumber !== '' ? DecodedMobileNumber : '',
            "emailAddress": sessionEmail !== '' ? DecodedEmail : '',
            "token": AuthKey

        }
    } else {
        let sessionStoreCode = sessionStorage.getItem('StoreCode') || '';
        let EncodedStore = new Buffer(sessionStoreCode, 'base64');
        let DecodedStore = EncodedStore.toString('ascii');
        //alert(DecodedStore)
        body = {

            "phoneNumber": sessionMobileNumber !== '' ? DecodedMobileNumber : '',
            "emailAddress": sessionEmail !== '' ? DecodedEmail : '',
            "storeNumber": DecodedStore

        }
    }


    const CMSResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        //alert("Success");
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        //alert("Failure");
        return error.response.data
    });

    return CMSResponse





}



export const SendAccessLink = async (UserInfo: any, AuthKey: string) => {
    var url = appConfig.APIConfig.Dev_SendAccesslink_URL;
    const headers = {
        'headers':
        {
            'Authorization': 'Bearer ' + AuthKey,
            "phoneNumber": '',
            "emailAddress": '',
            "isOnlyGenerateToken": 'true'
        }
    }

    const body = {
        "phoneNumber": UserInfo.phoneNumber !== '' && UserInfo.phoneNumber !== undefined && UserInfo.phoneNumber !== null ? UserInfo.phoneNumber : '',
        "emailAddress": UserInfo.emailAddress !== '' && UserInfo.emailAddress !== undefined && UserInfo.emailAddress !== null ? UserInfo.emailAddress : '',
        "storeNumber": UserInfo.storeNumber
    }


    const CMSResponse = await axios.post(url, body, headers).then(response => {
        //console.log(("Response", response.data);
        return response.data
    }).catch((error) => {
        //console.log(("Error : ", error.response.data);
        return error.response.data
    });

    return CMSResponse


}
