import '../css/commontheme.css'
import '../css/stylesheet.css'
import DOFHeader from './DOFHeader';
//import NextArrow from '../images/arrow-right.svg'
import { useEffect, useState } from 'react';

function IdentityVerify() {
    const [ApprovalStatus, setApprovalStatus] = useState('');
    useEffect(() => {

        const params = new URLSearchParams(window.location.search) // id=123
        const Status = params.get('Status') || "";
        ValidatePage(Status);


    }, []);

    const ValidatePage = (Status) => {
        if (Status === 'Completed') { setApprovalStatus('Completed') }
        else if (Status === 'Expired') { setApprovalStatus('Expired') }
        else if (Status === 'Rejected') { setApprovalStatus('Rejected') }
        else if (Status === 'Existing') { setApprovalStatus('Existing') }
        else if (Status === 'Exception') { setApprovalStatus('Exception') }
        else { setApprovalStatus('Invalid'); }

    }
    return (
        <>
            <DOFHeader />
            <div className="row justify-content-center me-0 mb-5">
                <div className="col-md-7 col-mdl-6  mt-5 pe-0">
                    <div className="info-card mx-3 text-center mb-5 banner-bg">
                        {ApprovalStatus === 'Completed' ?
                            <h3 className="sub-heading-txt mb-4 px-2">Link has been used already</h3> : 
                            ApprovalStatus === 'Expired' ?
                                <h3 className="sub-heading-txt mb-4 px-2">Link has been Expired</h3> : 
                                ApprovalStatus === 'Exception' ? 
                                <h3 className="sub-heading-txt mb-4 px-2">Something went wrong please contact administrator.</h3> :
                                    
                                    ApprovalStatus === 'Existing' ?
                                     <div> 
                                        <h5 className="sub-heading-txt mb-4 blue-title-txt px-2">We found your profile information!</h5>
                                        <h5 className="sub-heading-txt mb-4 px-2">Our Coworker will work with you to </h5>
                                        <h5 className="sub-heading-txt mb-4 px-2">Complete the order process.</h5>
                                    </div> 
                                    :

                                        ApprovalStatus === 'Rejected' ?
                                            <div>
                                                <h3 className="blue-title-txt sub-heading-txt mb-4 px-2">Thank you for your application request.</h3>
                                                <h5 className="sub-heading-txt mb-4 px-2">Unfortunately, we were unable to approve your request at this time.</h5>
                                                <h5 className="sub-heading-txt mb-4 px-2">In 3-10 business days you will receive a detailed email with reason(s)
                                                why we were unable to approve your application. If the email is unable to be delivered, you will receive a letter by mail.</h5>
                                                <h5 className="sub-heading-txt mb-4 px-2">Please feel free to reapply in 60 days.</h5>
                                                </div> :


                                                <h3 className="sub-heading-txt mb-4 px-2">Please enter the valid URL</h3>}
                    </div>
                </div>
            </div>


        </>
    );

}
export default IdentityVerify;