
const states = ['Select', 'AE', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI','WV','WY'];

const SMSTextMsg = 'Here is your Rent-A-Center security code :';

const Generate_SMSURL = 'https://qa-dof-racpad.rentacenter.com/api/sendotp';

const BestTimetoCall = ['Select','Morning','Afternoon','Evening'];

const GovtTypeId = ['Select','StateID','DriverLicense','Passport'];

const PaydayFrequency = ['Select','Weekly','Bi-Weekly','Daily','Monthly','Semi-Monthly'];

const WeekDays = ['Select','SUN','MON','TUE','WED','THU','FRI','SAT'];

const Monthly = ['Select','LastDay','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

const SemiMonthly = ['Select','1 & 15','2 & 16','3 & 17','4 & 18','5 & 19','6 & 20','7 & 21','8 & 22','9 & 23', '10 & 24','11 & 25','12 & 26','13 & 27','14 & 28','15 & 30'];

const HowLong = ['Select','0 to 1 year','1 to 5 years','5+ years'];

const RelationShip = ['Select', 'Parent / In-law', 'Grandparent', 'Sibling', 'Aunt', 'Cousin', 'Niece / Nephew', 'Friend', 'Co-Worker','Other'];




//-------------Dev URL----------------------
const Dev_PayfoneIdentityURL = 'https://qa-dof-racpad.rentacenter.com/api/getuseridentity';
const Dev_ValidateTokenURL = 'https://qa-dof-racpad.rentacenter.com/api/validateaccesslink';
const SMSMessege_Template = 'Here is your Rent-A-Center security code : ';
const Dev_SendOTPURL = 'https://qa-dof-racpad.rentacenter.com/api/sendotp';
const Dev_AMS005 = 'https://qa-dof-racpad.rentacenter.com/api/getexistingcustomer';
const DEV_AMS_MessageDTSTZOffset = '-06:00';
const DEV_AMS_Revision = '2.1';
const DEV_AMS_RegionID = '1';
const DEV_AMS_ClientID = '194';
const DEV_AMS_ClientSource = '0';
const DEV_AMS_ClientOriginator = '194';
const DEV_AMS_LocationNumber = '00006';
const DEV_AMS_ClientLocationNumber = '00006';
const DEV_AMS_EncryptionMethod = '0';
const DEV_AMS_UserId = 'Test';

//-------------------------------------CMS205------------------------------------

const Dev_CMS205_URL = 'https://qa-dof-racpad.rentacenter.com/api/getapproval';
const Dev_CMS205_Messege_Type = 'CMS205_ESBHogKcrHAZABXD'; 
const Dev_CMS205_ClientLocationNumber = '00006';
const Dev_CMS205_ClientSource = '3';
const Dev_CMS205_LocationNumber = '00006';
const Dev_CMS205_RegionId = '1';


//---------------------------------------UpdateCustomer AMS003--------------------------
const Dev_AMS003_URL ='https://qa-dof-racpad.rentacenter.com/api/updatecustomerdetails';


//-------------------------------------Update Accesslink---------------------------------
const Dev_UpdateAccesslink_URL = 'https://qa-dof-racpad.rentacenter.com/api/updateaccesslink';





export {
    states,
    SMSTextMsg,
    Generate_SMSURL,
    BestTimetoCall,
    GovtTypeId,
    PaydayFrequency,
    HowLong,WeekDays,
    RelationShip,
    Monthly,
    SemiMonthly,
    Dev_ValidateTokenURL,
    Dev_SendOTPURL,
    SMSMessege_Template,
    Dev_AMS005,
    DEV_AMS_MessageDTSTZOffset,
    DEV_AMS_Revision,
    DEV_AMS_RegionID,
    DEV_AMS_ClientID,
    DEV_AMS_ClientLocationNumber,
    DEV_AMS_ClientOriginator,
    DEV_AMS_LocationNumber,
    DEV_AMS_EncryptionMethod,
    DEV_AMS_UserId,
    DEV_AMS_ClientSource,
    Dev_PayfoneIdentityURL,
    Dev_CMS205_URL,
    Dev_CMS205_Messege_Type,
    Dev_CMS205_ClientLocationNumber,
    Dev_CMS205_ClientSource,
    Dev_CMS205_LocationNumber,
    Dev_CMS205_RegionId,
    Dev_AMS003_URL,
    Dev_UpdateAccesslink_URL
};


