/* eslint-disable @typescript-eslint/no-unused-vars */
import DOFHeader from "./DOFHeader";
import '../css/commontheme.css'
import '../css/stylesheet.css'
import NextArrow from '../images/arrow-right.svg';
import {useState,useEffect} from 'react';


function ApprovalPage()
{
    const [MobileNumber,setMobileNumber] = useState('');
    const [TotalApprovalAmount, setTotalApprovalAmount] = useState('');
    const [WeeklyApprovalAmount, setWeeklyApprovalAmount] = useState('');
    const [ApprovalResponse, setApprovalResponse] = useState('');
    const [EncodedApprovalResponse, setEncodedApprovalResponse] = useState('')
    const [AuthKey, setAuthKey] = useState('')
    const [CustomerId,setCustomerId] = useState('');
    const [EncodedeState,setEncodedeState] = useState('');
    const [EncodedCity,setEncodedCity] = useState('');
    const [EncodedAddress,setEncodedAddress] = useState('');
    const [EncodedPostalCode,setEncodedPostalCode] = useState('');
    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        const EncodeAuthKey = params.get('AuthKey') || "";
      //  const MobileNumber = params.get('MobileNumber') || "";

        // let UserInfo = sessionStorage.getItem('user') !== undefined ? sessionStorage.getItem('user'):'';

        const UserApprovalInformation = JSON.parse(sessionStorage.getItem('UserInformation') || '{}'); ;
        const TAM = UserApprovalInformation.TAM ;
        const WAM = UserApprovalInformation.WAM;
        const APP = UserApprovalInformation.APP;
        //const CID =  UserApprovalInformation.CID;
        //const State = UserApprovalInformation.State;
        //const Address =  UserApprovalInformation.Address;
        //const City = UserApprovalInformation.City;
        //const ZipCode = UserApprovalInformation.Zip
        setAuthKey(EncodeAuthKey);
        bindValue(TAM, WAM, APP);
       

    }, [])

    const bindValue = (TotalApprovalAmount, WeeklyApprovalAmount, APP)=>
   {
     
    // let EncodedMobileNumber = new Buffer(MobileNumber, 'base64');
    // let DecodedMobileNumber = EncodedMobileNumber.toString('ascii');
    // setMobileNumber(MobileNumber);
    let EncodedTAM = new Buffer(TotalApprovalAmount, 'base64');
    let DecodedTAM = EncodedTAM.toString('ascii');
    setTotalApprovalAmount(DecodedTAM);
    let EncodedWAM = new Buffer(WeeklyApprovalAmount, 'base64');
    let DecodedWAM = EncodedWAM.toString('ascii');
       setWeeklyApprovalAmount(DecodedWAM);
        let EncodedAPP = new Buffer(APP, 'base64');
      let DecodedAPP = EncodedAPP.toString('ascii');
        setApprovalResponse(DecodedAPP);
    // setApprovalResponse(DecodedAPP);
    //  let EncodedCID = new Buffer(CID, 'base64');    
    // setCustomerId(CID);

    // setEncodedeState(State);
    // setEncodedCity(Address);
    // setEncodedAddress(City);
    // setEncodedPostalCode(ZipCode);


   }

    return(
        <>
     <DOFHeader/>    
      {/*body content starts*/}
      <div className="row justify-content-center me-0 mb-5">
        <div className="col-md-7 col-mdl-6  mt-5 pe-0">
          {ApprovalResponse ==="1"?
                    <div className="info-card mx-3 text-center mb-5 banner-bg">
                    <h5 className="sub-heading-txt mb-4 px-2">Congratulations! You have been Approved for</h5>
                    <h3 className="blue-title-txt mb-3 px-2">{'$ '+ parseFloat(TotalApprovalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</h3>
                    {WeeklyApprovalAmount!== '' ?
                    <h6 className="sub-heading-txt mb-4 px-2">Weekly Approval Amount : <span className="blue-title-txt">{'$ '+ WeeklyApprovalAmount+'.00'}</span></h6> :null}
                    <h5 className="sub-heading-txt mb-4 px-2">Let's finish creating your profile.
                    </h5>
        
                    
                  </div> : 
                            <div className="info-card mx-3 text-center mb-5 banner-bg">
                            <h5 className="sub-heading-txt mb-4 px-2">Congratulations! You have been Pre-Approved for</h5>
                            <h3 className="blue-title-txt mb-3 px-2">{'$ '+ parseFloat(TotalApprovalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</h3>
                            {WeeklyApprovalAmount!== '' ?
                            <h6 className="sub-heading-txt mb-4 px-2">Weekly Approval Amount : <span className="blue-title-txt">{'$ '+ WeeklyApprovalAmount+'.00'}</span></h6> :null}
                            <h5 className="sub-heading-txt mb-4 px-2">We just need a few more details 
                            to finalize your approval.
                            </h5>
                
                            
                          </div>

          }


          <div className="mx-3">
            <a className="btn btn-primary-custom mt-5  w-100" href={"./UserVerifyInformation/?AuthKey="+ AuthKey }>CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></a>
          </div>
        </div>
      </div>
      {/*body content ends*/}   
    
        </>
        );  
}

export default ApprovalPage;