/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import '../css/commontheme.css'
import '../css/stylesheet.css'
import DOFHeader from './DOFHeader';
import PreviousArrow from '../images/prev-icon.svg'
import NextArrow from '../images/arrow-right.svg'
//import Info from '../images/info.svg'
import Loader from '../images/loader-icon.svg'
//import OtpInput from 'react-otp-input';
import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { SendOTP, UpdateAccesslink, ValidateOTP } from '../Service/Service';


// import {Switch} from 'react-router-dom';



function BasicInfo() {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        const EncodeMobileNumber = sessionStorage.getItem("MobileNo") !== null && sessionStorage.getItem("MobileNo") !== undefined && sessionStorage.getItem("MobileNo") !== '' ? sessionStorage.getItem("MobileNo") : '';
        const EncodeEmailId = sessionStorage.getItem("EmailId") !== null && sessionStorage.getItem("EmailId") !== undefined && sessionStorage.getItem("EmailId") !== '' ? sessionStorage.getItem("EmailId") : '';
        const EncodeAuthKey = params.get('AuthKey') || "";
        const EncodedStorecode = params.get('StoreCode') || "";

        setAuthkey(EncodeAuthKey);
        setvalidateOtpAuthKey(EncodeAuthKey)

        bindDefaultvalue(EncodeMobileNumber, EncodeEmailId, EncodedStorecode);



        // const formatedMobNumber = formatPhoneNumber(EncodeMobile)
        // setMobileNumberValue(formatedMobNumber);
        // EmailValueVerify(EncodeEmaild);
        // alert(formatedMobNumber.replace(/[-() ]/g,''));

        // alert(encodeURIComponent("test"));
        // let DecodeEmaild = decode(EncodeEmaild);
        // const test = CryptoJS..encrypt("test","123");
        // const tsts = CryptoJS.AES.decrypt(test,"123");
        // alert(test);
        // alert(tsts);
        // // alert(DecodeEmaild);
        // setMobileNoVerify(DecodeEmaild);

        // alert(id);
        // let mobile = encode("8976589726"); 
        // let test = encode("test@gmail.com");
        // let Emaild = decode("Emaild");
        // let mobtets = decode("a$f#i2k2l2wp#u2x2v#x2");

    }, []);


    const bindDefaultvalue = (EncodeMobileNumber, EncodeEmailId, EncodedStorecode) => {
        if (EncodeMobileNumber !== '') {
            let EncodedMobNum = new Buffer(EncodeMobileNumber, 'base64');
            let DecodedNum = EncodedMobNum.toString('ascii');
            ////console.log((DecodedNum);

            const formatedMobNumber = formatPhoneNumber(DecodedNum)
            setMobileNumberValue(formatedMobNumber);
        }
        if (EncodeEmailId !== '') {
            let EncodedEmail = new Buffer(EncodeEmailId, 'base64');
            let DecodedEmailId = EncodedEmail.toString('ascii');
            setEmailIdValue(DecodedEmailId.toLowerCase());
            setEmailVerify('verify-icon');
            setIsEmailVerified(true);
            EmailValueVerify(DecodedEmailId.toLowerCase())
        }
        if (EncodedStorecode !== '') {
            let EncodedStoreId = new Buffer(EncodedStorecode, 'base64');
            let DecodedStoreId = EncodedStoreId.toString('ascii');
            //console.log(DecodedStoreId.toLowerCase());

        }


    }





    //Get the value using the AuthKey 

    //required state variables will be declared 
    const [Authkey, setAuthkey] = useState<string>("");
    const [MobileNumber, setMobileNumberValue] = useState<string>("");
    const [EmailId, setEmailIdValue] = useState<string>("");
    const [LastFourSSN, setLastFourSSNValue] = useState<string>("");
    const [MobileNoVerify, setMobileNoVerify] = useState<string>("");
    const [EmailVerify, setEmailVerify] = useState<string>("");
    const [IsEmailVerified, setIsEmailVerified] = useState<boolean>(false);
    const [IsMobileNoVerified, setIsMobileNoVerified] = useState<boolean>(false);
    const [IsLastFourSSNVerified, setIsLastFourSSNVerified] = useState<boolean>(false);
    const [LastFourVerify, setLastFourVerify] = useState<string>("");
    const [IsOTPOpen, setIsOTPOpen] = useState<boolean>(false);
    const [ActionShow, setActionShow] = useState<string>("");
    const [DisableContinueButton, setDisableContinueButton] = useState<boolean>(true);
    const [OTPValue, setOTPValue] = useState("");

    const [messageId, setMessageId] = useState<string>("");

    const [IsLookingUpOpen, setIsLookingUpOpen] = useState<boolean>(false);
    const [OTPValidationText, setOTPValidationText] = useState<string>('');
    const [MobileNoValidationText, setMobileNoValidationText] = useState<string>('');
    const [validateOtpAuthKey, setvalidateOtpAuthKey] = useState<string>('');


    //Added for new requirement
    //Fistname and last name should be included into basic details
    const [FirstName, setFirstName] = useState<string>('');
    const [IsFirstNameVerified, setIsFirstNameVerified] = useState<boolean>(false);
    const [FirstNameVerify, setFirstNameVerify] = useState<string>('')
    const [LastName, setLastName] = useState<string>('');
    const [IsLastNameVerified, setIsLastNameVerified] = useState<boolean>(false);
    const [LastNameVerify, setLastNameVerify] = useState<string>('')


    //OTPVariable
    // const [OtpFirstButton, setOtpFirstButton] = useState < boolean > (true);
    // const [OtpSecondButton, setOtpSecondButton] = useState < boolean > (false);
    // const [OtpThirdButton, setOtpThirdButton] = useState < boolean > (false);
    // const [OtpFourthButton, setFourthButton] = useState < boolean > (false);
    // const [OtpFifthButton, setOtpFifthButton] = useState < boolean > (false);



    //Will send the mobile number to make an service call to get an OTP as out put


    const SetOTP = (e: any) => {
        setOTPValidationText('');

        const OTP = e.target.value.replace(/[^\d]/g, "");
        setOTPValue(OTP);
    }
    // const SetOTP = (Value: any) => {
    //     setOTPValidationText('');
    //     const OTP = Value.replace(/[^\d]/g, "");
    //     setOTPValue(OTP);
    // }



    const formatPhoneNumber = (value: any) => {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) {
            setIsMobileNoVerified(false);
            setMobileNoVerify("");
            setDisableContinueButton(true);
            return value;
        }

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength >= 10) {

            const EnStoreCode = new Buffer(value).toString("base64");
            // sessionStorage.setItem("MobileNo",EnStoreCode);

            setMobileNoVerify("verify-icon");
            setIsMobileNoVerified(true);
            enableContinueButton('MobileNumber');

        } else {
            setMobileNoVerify("");
            setIsMobileNoVerified(false);
            enableContinueButton('MobileNumber');
            setDisableContinueButton(true);
        }

        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const handleInput = (e: any) => {

        setMobileNoValidationText('');
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);

        // we'll set the input value using our setInputValue
        setMobileNumberValue(formattedPhoneNumber);
        const MobileNumberValue = new Buffer(MobileNumber.replace(/[-() ]/g, '')).toString("base64");
        // sessionStorage.setItem("MobileNo",MobileNumberValue);

    };


    //Once enter the otp OTPContinue will clicked on the continue button
    const OTPContinue = async () => {
        var encodedemail = new Buffer(EmailId).toString("base64");
        const encodedMobile = new Buffer(MobileNumber.replace(/[-() ]/g, '')).toString("base64");


        sessionStorage.setItem("EmailId", encodedemail);
        sessionStorage.setItem("MobileNo", encodedMobile);
        if (Authkey === '' || Authkey === undefined || Authkey === null) {
            sessionStorage.setItem("MobileNoLink", encodedMobile);
            sessionStorage.setItem("EmailIdLink", encodedemail);
        }



        setIsLookingUpOpen(true);
        if (Authkey !== '') {
            //console.log("InsideMObile");
            const OTPResponse: any = await SendOTP(MobileNumber.replace(/[-() ]/g, ''), Authkey);
            //console.log("Outsidefunction", OTPResponse)
            if (OTPResponse !== undefined && OTPResponse !== null) {
                if (OTPResponse && OTPResponse?.messageId && OTPResponse?.message == 'SUCCESS') {
                    setIsLookingUpOpen(false);
                    setActionShow('show');
                    setIsOTPOpen(true);
                    setMessageId(OTPResponse.messageId);
                    ////console.log(("Inside the function", OTPResponse.smsCode)
                }
                else if (OTPResponse && OTPResponse?.uniqueId && OTPResponse?.messageId == 'PLEASETRYAGAIN') {
                    setIsLookingUpOpen(false);
                    setMobileNoValidationText('Please Try Again');
                    setDisableContinueButton(true);
                }
                else {
                    setIsLookingUpOpen(false);
                    setMobileNoValidationText('Please Enter Valid MobileNumber');
                    setDisableContinueButton(true);
                }
            }
            else {
                setIsLookingUpOpen(false);
                setMobileNoValidationText('Please Enter Valid MobileNumber');
                setDisableContinueButton(true);
            }
        }
        else {

            //console.log(("insidestore");
            setIsLookingUpOpen(true);
            let EncodedStorecode = sessionStorage.getItem("StoreCode") || '';
            let encodestore = new Buffer(EncodedStorecode, 'base64');
            let decodestore = encodestore.toString('ascii');
            decodestore = decodestore + '-Store';
            //console.log((decodestore);

            let updateAccessLink = await UpdateAccesslink(decodestore, true);
            //console.log((updateAccessLink);
            if (updateAccessLink.token !== undefined) {
                setAuthkey(updateAccessLink.token);
                setvalidateOtpAuthKey(updateAccessLink.token)
                const OTPResponse: any = await SendOTP(MobileNumber.replace(/[-() ]/g, ''), updateAccessLink.token);
                //console.log(("Outsidefunction", OTPResponse)
                if (OTPResponse !== undefined && OTPResponse !== null) {
                    if (OTPResponse && OTPResponse?.messageId && OTPResponse?.message == 'SUCCESS') {
                        setIsLookingUpOpen(false);
                        setActionShow('show');
                        setIsOTPOpen(true);
                        setMessageId(OTPResponse.messageId);
                        ////console.log(("Inside the function", OTPResponse.smsCode)
                    }
                    else if (OTPResponse && OTPResponse?.uniqueId && OTPResponse?.messageId == 'PLEASETRYAGAIN') {
                        setIsLookingUpOpen(false);
                        setMobileNoValidationText('Please Try Again');
                        setDisableContinueButton(true);
                    }
                    else {
                        setIsLookingUpOpen(false);
                        setMobileNoValidationText('Please Enter Valid MobileNumber');
                        setDisableContinueButton(true);
                    }
                }
                else {
                    setIsLookingUpOpen(false);
                    setMobileNoValidationText('Please Enter Valid MobileNumber');
                    setDisableContinueButton(true);
                }
            }

        }
    }
    const enableContinueButton = (Value: string) => {


        switch (Value) {
            case 'SSN':
                if (IsEmailVerified && IsMobileNoVerified && IsFirstNameVerified && IsLastNameVerified) {
                    setDisableContinueButton(false);

                } else {
                    setDisableContinueButton(true);
                }
                break;

            case 'MobileNumber':
                if (IsEmailVerified && IsLastFourSSNVerified && IsFirstNameVerified && IsLastNameVerified) {
                    setDisableContinueButton(false);

                } else {
                    setDisableContinueButton(true);
                }
                break;
            case 'Email':
                if (IsLastFourSSNVerified && IsMobileNoVerified && IsFirstNameVerified && IsLastNameVerified) {
                    setDisableContinueButton(false);

                } else {
                    setDisableContinueButton(true);
                }
                break;
            case 'FirstName':
                if (IsEmailVerified && IsLastFourSSNVerified && IsMobileNoVerified && IsLastNameVerified) {
                    setDisableContinueButton(false);

                } else {
                    setDisableContinueButton(true);
                }
                break;
            case 'LastName':
                if (IsEmailVerified && IsLastFourSSNVerified && IsMobileNoVerified && IsFirstNameVerified) {
                    setDisableContinueButton(false);

                } else {
                    setDisableContinueButton(true);
                }
                break;
        }
    }

    //This function used to validate the email address
    const EmailValueVerify = (value: any) => {
        if (value.trim().length <= 50) {
            var emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            //var EmailRegex = new RegExp('^([0-9a-zA-Z]([-\\.\\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})$');
            if (emailPattern.test(value.trim())) {
                setEmailIdValue(value.trim());

                setEmailVerify("verify-icon");
                setIsEmailVerified(true);
                enableContinueButton('Email');
                var encodedemail = new Buffer(value).toString("base64");

            } else {
                setEmailVerify("");
                setEmailIdValue(value);
                setIsEmailVerified(false);
                enableContinueButton('Email');
                setDisableContinueButton(true);
            }
        }

    }

    //This function will used to validate the all inputs
    const ValidateInputs = (event: any) => {
        if (event.target.id === 'Email') {
            EmailValueVerify(event.target.value);
        }
        if (event.target.id === 'SSN') {
            const lastFourssn = event.target.value.replace(/[^\d]/g, "");

            if (lastFourssn.length <= 4) {
                setLastFourSSNValue(lastFourssn);
                if (lastFourssn.length === 4) {
                    setIsLastFourSSNVerified(true);
                    setLastFourVerify("verify-icon");
                    enableContinueButton('SSN');

                } else {
                    setLastFourVerify("");
                    setIsLastFourSSNVerified(false);
                    setDisableContinueButton(true);

                }

            }


        }
        if (event.target.id === 'FirstName') {

            const value = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');

            if (value.length > 0 && event.target.value.trim() !== '') {
                if (event.target.value.trim().length <= 30) {
                    setFirstName(value);
                    setIsFirstNameVerified(true);
                    setFirstNameVerify('verify-icon');
                    enableContinueButton('FirstName');
                    const FirstNameSession = new Buffer(value).toString("base64");
                    sessionStorage.setItem('FirstName', FirstNameSession)

                }
            } else {
                setFirstName(value);
                setIsFirstNameVerified(false);
                setFirstNameVerify('');
                setDisableContinueButton(true);

            }

        }
        if (event.target.id === 'LastName') {

            const value = event.target.value.replace(/[^A-Za-z' .,-]/ig, '');

            if (value.length > 0 && event.target.value.trim() !== '') {
                if (event.target.value.trim().length <= 30) {
                    setLastName(value);
                    setIsLastNameVerified(true);
                    setLastNameVerify('verify-icon');
                    enableContinueButton('LastName');
                    const LastNameSession = new Buffer(value).toString("base64");
                    sessionStorage.setItem('LastName', LastNameSession)

                }
            } else {
                setIsLastNameVerified(false);
                setLastNameVerify('');
                setLastName(value);
                setDisableContinueButton(true);

            }

        }

    }


    //This function used to enable and disable the continue button



    //After enter OTP verification Process

    const ConfirmButtonClick = async () => {
        setIsLookingUpOpen(true)

        const payload = {
            enteredOtp: OTPValue,
            messageId: messageId
        }
        const validateOtpResponse = await ValidateOTP(payload, validateOtpAuthKey)
        setIsLookingUpOpen(false)
        if (validateOtpResponse?.status == 200 && validateOtpResponse?.isValid == true && validateOtpResponse?.resendOtp == false && validateOtpResponse?.cacheDeleted == true) {
            setIsOTPOpen(false);
            setIsLookingUpOpen(true);
            setOTPValidationText('');
            // clean the input for any non-digit values.
            const UnformatedMobileNumber = MobileNumber.replace(/[^\d]/g, "");
            let EncodeMobileNumber = new Buffer(UnformatedMobileNumber).toString("base64");
            let EncodeLastfourSSN = new Buffer(LastFourSSN).toString("base64");
            let EncodedEmail = new Buffer(EmailId).toString("base64");
            sessionStorage.setItem("LFN", EncodeLastfourSSN);
            window.location.href = '/PayfonePrefill?AuthKey=' + Authkey;

        }
        else if (validateOtpResponse?.status == 400 && validateOtpResponse?.isValid == false && validateOtpResponse?.resendOtp == false && validateOtpResponse?.cacheDeleted == false) {
            setOTPValidationText('Please Enter The Valid OTP')
        }
        else {
            setOTPValidationText('Please Resend the OTP')
        }

    }
    //ResendOTP Button Click

    const ReSendOTPButtonClick = async () => {
        setOTPValidationText('');
        const OTPResponse: any = await SendOTP(MobileNumber.replace(/[-() ]/g, ''), Authkey);

        //console.log(("Outsidefunction", OTPResponse)
        if (OTPResponse !== undefined && OTPResponse !== null) {
            setMessageId(OTPResponse.messageId);
            //console.log(("Inside the function", OTPResponse.smsCode)
        }

    }

    const BasicPrevious = () => {
        if (Authkey !== '' && Authkey !== undefined && Authkey !== null) {
            window.location.href = './?AuthKey=' + Authkey;
        }
        else {

            let sessionStoreCode = sessionStorage.getItem('StoreCode') || '';
            window.location.href = './?StoreCode=' + sessionStoreCode;
        }

    }


    return (
        <>
            <DOFHeader />
            <div>
                <div className="row justify-content-center me-0 ">
                    <div className="col-md-7 col-mdl-6  mt-5 pe-0">
                        <h5 className="sub-heading-txt mb-5 text-center  px-3">Let’s look up your info!
                            Let us do the work for you! We may be
                            able to find and prefill your contact info.
                        </h5>
                        <div className="w-100 float-start ">
                            <div className="col-md-6 float-start px-3 mb-3 ">
                                <label className="form-label mandatory" htmlFor="FirstName">First Name</label>
                                <input type="text" onChange={ValidateInputs} value={FirstName} className={"form-control " + FirstNameVerify} id="FirstName" maxLength={30} />
                            </div>
                            <div className="col-md-6 float-start px-3 mb-3 ">
                                <label className="form-label mandatory" htmlFor="LastName">Last Name</label>
                                <input type="text" onChange={ValidateInputs} value={LastName} className={"form-control " + LastNameVerify} id="LastName" maxLength={30} />
                            </div>
                        </div>
                        <div className="w-100 float-left mb-3 px-3">
                            <label className="form-label mandatory" htmlFor="mobile#">Mobile #</label>
                            <input type="text" className={"form-control " + MobileNoVerify} value={MobileNumber} onChange={(e) => handleInput(e)} id="mobilenumber" />
                            <span className="OtpValidation">{MobileNoValidationText}</span>
                        </div>
                        <div className="w-100 float-left mb-3 px-3">
                            <label className="form-label mandatory" htmlFor="Email">Email</label>
                            <input type="text" value={EmailId} maxLength={50} className={"form-control " + EmailVerify} onChange={ValidateInputs} id="Email" />
                            {!IsEmailVerified && <span className="OtpValidation">Please enter valid email</span>}
                        </div>
                        <div className="w-100 float-left mb-5 px-3">
                            <label className="form-label mandatory" htmlFor="SSN">Last 4 SSN</label>

                            <input type="text" className={"form-control " + LastFourVerify} value={LastFourSSN} onChange={ValidateInputs} id="SSN" />
                        </div>
                        <div className="mx-2 text-center mt-5 d-md">
                            <button className="prev-button px-4 bg-rem" onClick={BasicPrevious}><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
                            <button className="btn btn-primary-custom my-3" disabled={DisableContinueButton} onClick={OTPContinue} data-bs-toggle="modal" id="OTPContinueButton1" data-bs-target="#OTP">CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                        </div>
                        <div className="mx-2 text-center mt-5 d-sm">
                            <button className="btn btn-primary-custom my-3 " disabled={DisableContinueButton} onClick={OTPContinue} data-bs-toggle="modal" id="OTPContinueButton2" data-bs-target="#OTP">CONTINUE <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                            <button className="prev-button px-4 bg-rem" onClick={BasicPrevious} ><img className="me-2" src={PreviousArrow} alt="prev icon" />Previous</button>
                        </div>
                    </div>
                </div>
                {/*body content ends*/}
                {/*Modal for OTP starst here*/}
                <Modal isOpen={IsOTPOpen} className="test"   >
                    <div tabIndex={-1} className={"modal fade " + ActionShow} id="OTP" data-bs-backdrop="static" data-bs-keyboard="false" style={{ display: 'block' }} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content">
                                <div className="modal-body p-4">
                                    <label className="sub-heading-txt text-center mb-4">
                                        Please enter the One Time Password
                                        sent to your mobile #
                                    </label>
                                    <div className="w-100 float-start">
                                        {/* <input type="text" ref="input_1"  autoFocus = {OtpFirstButton}  id="otpfirst" className="form-control txtbox-height w-23 float-start" />
                  <input type="text"  ref="input_2"  autoFocus = {OtpSecondButton} id="otpsecond" className="form-control txtbox-height w-23 float-start" />
                  <input type="text"  autoFocus = {OtpThirdButton} id="otpfourth"  className="form-control txtbox-height w-23 float-start" />
                  <input type="text"  autoFocus = {OtpFourthButton} id="otpfifth"  className="form-control txtbox-height w-23 float-start" />
                  <input type="text"  autoFocus = {OtpFifthButton} id="otpsixth" className="form-control txtbox-height w-23 float-start" />
                   */}              <input type="text" value={OTPValue} className={"form-control otp-center"} maxLength={6} onChange={SetOTP} id="otp" />


                                    </div>

                                </div>
                                <span className="OtpValidation">{OTPValidationText}</span>
                                <div className="modal-footer border-0">
                                    <button type="button" onClick={ReSendOTPButtonClick} className="btn outline-btn w-100 mb-2">RESEND OTP</button>
                                    <button className="btn btn-primary-custom w-100" data-bs-toggle="modal" onClick={ConfirmButtonClick} data-bs-dismiss="modal" data-bs-target="#loader">CONFIRM <img className="ms-2" src={NextArrow} alt="arrow right" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/*Modal for OTP ends here*/}
                {/*Modal for Loader starst here*/}
                <Modal isOpen={IsLookingUpOpen} className='test'>
                    <div className="modal fade show" id="loader" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body py-4">
                                    <p className="popup-txt-style mb-0"><img className="me-2" src={Loader} alt="loader icon" />Looking you up</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/*Modal for Loader ends here*/}
            </div>

        </>

    );
}
export default BasicInfo;
